/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Flags } from '../models/flags';
import { UserEntitlements } from '../models/user-entitlements';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getFlags
   */
  static readonly GetFlagsPath = '/api/sellers/profile/flags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFlags()` instead.
   *
   * This method doesn't expect any response body
   */
  getFlags$Response(params: {
    applicationName: string;
    timestamp: string;
    correlationId: string;
    trackingId: string;

  }
): Observable<StrictHttpResponse<Flags>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.GetFlagsPath, 'get');
    if (params) {

      rb.header('ApplicationName', params.applicationName);
      rb.header('Timestamp', params.timestamp);
      rb.header('CorrelationId', params.correlationId);
      rb.header('TrackingId', params.trackingId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Flags>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFlags$Response()` instead.
   *
   * This method doesn't expect any response body
   */
  getFlags(params: {
    applicationName: string;
    timestamp: string;
    correlationId: string;
    trackingId: string;

  }
): Observable<Flags> {

    return this.getFlags$Response(params).pipe(
      map((r: StrictHttpResponse<Flags>) => r.body as Flags)
    );
  }

  /**
   * Path part for operation createFlag
   */
  static readonly CreateFlagPath = '/api/sellers/profile/flags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFlag()` instead.
   *
   * This method sends `application/json` and handles response body of type `application/json`
   */
  createFlag$Response(params: {
    applicationName: string;
    timestamp: string;
    correlationId: string;
    trackingId: string;

    body: Flags
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.CreateFlagPath, 'post');
    if (params) {

      rb.header('ApplicationName', params.applicationName);
      rb.header('Timestamp', params.timestamp);
      rb.header('CorrelationId', params.correlationId);
      rb.header('TrackingId', params.trackingId);

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createFlag$Response()` instead.
   *
   * This method sends `application/json` and handles response body of type `application/json`
   */
  createFlag(params: {
    applicationName: string;
    timestamp: string;
    correlationId: string;
    trackingId: string;

    body: Flags
  }
): Observable<void> {

    return this.createFlag$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getSellerProfile
   */
  static readonly GetSellerProfilePath = '/api/sellers/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSellerProfile()` instead.
   *
   * This method doesn't expect any response body
   */
  getSellerProfile$Response(params: {
    applicationName: string;
    timestamp: string;
    correlationId: string;
    trackingId: string;

  }
): Observable<StrictHttpResponse<UserEntitlements>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.GetSellerProfilePath, 'get');
    if (params) {

      rb.header('ApplicationName', params.applicationName);
      rb.header('Timestamp', params.timestamp);
      rb.header('CorrelationId', params.correlationId);
      rb.header('TrackingId', params.trackingId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserEntitlements>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSellerProfile$Response()` instead.
   *
   * This method doesn't expect any response body
   */
  getSellerProfile(params: {
    applicationName: string;
    timestamp: string;
    correlationId: string;
    trackingId: string;

  }
): Observable<UserEntitlements> {

    return this.getSellerProfile$Response(params).pipe(
      map((r: StrictHttpResponse<UserEntitlements>) => r.body as UserEntitlements)
    );
  }

}

/**
 * Custom interfaces
 */
export interface GetFlagsPathParams {
    applicationName: string;
    timestamp: string;
    correlationId: string;
    trackingId: string;
}
export interface CreateFlagPathParams {
    applicationName: string;
    timestamp: string;
    correlationId: string;
    trackingId: string;
}
export interface GetSellerProfilePathParams {
    applicationName: string;
    timestamp: string;
    correlationId: string;
    trackingId: string;
}
