import { ActionMap, reduce } from '@core/store/root.state';
import { Action } from '@ngrx/store';
import * as DocumentMarketplaceActions from './marketplace-document.actions';
import { MarketplaceDocumentState, MARKETPLACE_DOCUMENT_INITIAL_STATE } from './marketplace-document.state';

const REDUCER_ACTION_MAP: ActionMap = {
  [ DocumentMarketplaceActions.FETCH_DOCUMENTS_MARKETPLACE_REQUEST ]: fetchDocumentsMarketplaceRequest,
  [ DocumentMarketplaceActions.FETCH_DOCUMENTS_MARKETPLACE_SUCCESS ]: fetchDocumentsMarketplaceSuccess,
  [ DocumentMarketplaceActions.FETCH_DOCUMENTS_MARKETPLACE_FAILURE ]: fetchDocumentsMarketplaceFailure,
  [ DocumentMarketplaceActions.CLEAR_DOCUMENTS_MARKETPLACE ]: clearDocumentsMarketplace,
  [ DocumentMarketplaceActions.CANCEL_FETCH_DOCUMENTS_MARKETPLACE_REQUEST ]: clearDocumentsMarketplace,
};

export function marketplaceDocumentReducer(state: MarketplaceDocumentState = MARKETPLACE_DOCUMENT_INITIAL_STATE, action: Action): MarketplaceDocumentState {
  return reduce(REDUCER_ACTION_MAP, action, state);
}

function fetchDocumentsMarketplaceRequest(state: MarketplaceDocumentState): MarketplaceDocumentState {
  return {
    ...state,
    documents: {
      ...state.documents,
      isPending: true,
      error: null,
    },
  };
}

function fetchDocumentsMarketplaceSuccess(state: MarketplaceDocumentState, action: DocumentMarketplaceActions.FetchDocumentsMarketplaceSuccessAction): MarketplaceDocumentState {
  return {
    ...state,
    documents: {
      isPending: false,
      error: null,
      data: action.documents,
    },
  };
}

function fetchDocumentsMarketplaceFailure(state: MarketplaceDocumentState, action: DocumentMarketplaceActions.FetchDocumentsMarketplaceFailureAction): MarketplaceDocumentState {
  return {
    ...state,
    documents: {
      isPending: false,
      error: action.error,
      data: null,
    },
  };
}

function clearDocumentsMarketplace(): MarketplaceDocumentState {
  return { ...MARKETPLACE_DOCUMENT_INITIAL_STATE };
}
