import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { RootState } from '@core/store/root.state';
import { Store } from '@ngrx/store';
import { DropdownOption } from '@shared/components/dropdown/dropdown-option.class';
import { Subscription } from 'rxjs';
import { LanguageCode } from '../config/global.config';
import { I18nService } from '../i18n/i18n.service';
import * as SessionSelectors from '@core/store/session/session.selectors';
import { CurrentStateService } from '@core/store/current-state.service';

@Component({
  selector: 'sss-language-switcher-modal',
  templateUrl: './language-switcher-modal.component.html',
  styleUrls: [ './language-switcher-modal.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'sss-language-switcher-modal' },
})
export class LanguageSwitcherModalComponent implements OnInit, OnDestroy {
  @Output()
  public close: EventEmitter<null> = new EventEmitter();

  public selectedLanguageCode: LanguageCode = null;
  public languageOptions: Array<DropdownOption> = [];

  private subscription: Subscription;

  constructor(
    private readonly store: Store<RootState>,
    private readonly i18nService: I18nService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly currentStateService: CurrentStateService,
  ) {}

  public ngOnInit(): void {
    this.languageOptions = this.getDropdownOptionsForLanguages();

    this.subscription = this.store.select(SessionSelectors.uiLanguageCode)
      .subscribe((lang: LanguageCode) => {
        this.selectedLanguageCode = lang;
        this.changeDetectorRef.markForCheck();
      });
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public changeLanguage(): void {
    this.i18nService.changeLanguageTo(this.selectedLanguageCode);
    this.closeModal();
  }

  public closeModal(): void {
    this.close.emit();
  }

  public onLanguageSelectionChange(language: LanguageCode): void {
    this.selectedLanguageCode = language;
  }

  private getDropdownOptionsForLanguages(): Array<DropdownOption> {
    const uniqueUiLanguages = new Set(this.currentStateService.get(SessionSelectors.uiLanguages));
    return Array.from(uniqueUiLanguages)
      .map((languageCode) => new DropdownOption(languageCode, this.i18nService.getInstantTranslation('common.languageSwitcher.uiLanguages.' + languageCode)));
  }
}
