import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AppRoute } from '@app/app-routes.enum';
import { BackofficeRoute } from '@app/backoffice/backoffice.routes';
import { DocumentsRoute } from '@app/document/document.config';
import { ProfileRoute } from '@app/profile/profile.config';
import { ShippingCostsRoute } from '@app/shipping-costs/shipping-costs.config';
import { WarrantyPackagesRoute } from '@app/warranty-packages/warranty-packages.config';
import { CommerceDomain, SellerCenterDomain, SellerCenterService } from '@core/config/global.config';
import { DomainAccessService } from '@core/services/domain-access/domain-access.service';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { CurrentStateService } from '@core/store/current-state.service';
import { RootState } from '@core/store/root.state';
import * as SessionSelectors from '@core/store/session/session.selectors';
import { UserEntitlements } from '@core/types/types';
import { UserPermissions, UserRole } from '@core/types/user-role';
import { Store } from '@ngrx/store';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sss-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: [ './header-main.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'sss-header-main' },
})
export class HeaderMainComponent implements OnInit, OnDestroy {
  @Input() public isLoggedIn = false;
  @Input() public userPermissions: UserPermissions;

  public inShopsCommerceDomain = false;
  public hasDECountryPermissions = false;
  public userRole: UserRole;

  public readonly SellerCenterService = SellerCenterService;
  public readonly ROUTE = AppRoute;
  public readonly BACKOFFICE_ROUTE = BackofficeRoute;
  public readonly SELLER_CENTER_DOMAIN = SellerCenterDomain;
  public readonly DOCUMENT_ROUTE = DocumentsRoute;
  public readonly SHIPPING_COSTS_ROUTE = ShippingCostsRoute;
  public readonly WARRANTY_PACKAGES_ROUTE = WarrantyPackagesRoute;
  public readonly PROFILE_ROUTE = ProfileRoute;

  private profile$: Observable<UserEntitlements>;
  private readonly destroy$ = new Subject();

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store<RootState>,
    private readonly domainAccessService: DomainAccessService,
    private readonly windowRefService: WindowRefService,
    private readonly currentStateService: CurrentStateService,
  ) {}

  public canAccessDomain(domain: SellerCenterDomain | SellerCenterService): boolean {
    return this.domainAccessService.canAccessDomain(domain);
  }

  public ngOnInit(): void {
    this.inShopsCommerceDomain = this.currentStateService.get(SessionSelectors.commerceDomain) === CommerceDomain.SHOPS;
    this.hasDECountryPermissions = this.currentStateService.get(AuthSelectors.hasDECountryPermissions);
    this.userRole = this.currentStateService.get(AuthSelectors.userRole);

    this.profile$ = this.store.select(AuthSelectors.profile)
      .pipe(
        filter(Boolean),
        takeUntil(this.destroy$)
      );

    this.profile$.subscribe(() => this.changeDetectorRef.detectChanges());

    this.store
      .select(AuthSelectors.selectedCompany)
      .pipe(
        filter(Boolean),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.changeDetectorRef.detectChanges());
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
