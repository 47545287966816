import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DropdownOption } from '@app/shared/components/dropdown/dropdown-option.class';
import { DropdownService } from '@app/shared/services/dropdown.service';
import { PermissionsService } from '@app/shared/services/permissions.service';
import { CompanyOutletSelectorFilter } from '@core/company-outlet-selector-2/store/company-outlet-selector-2.state';
import { CommerceDomain, SellerCenterDomain } from '@core/config/global.config';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { CurrentStateService } from '@core/store/current-state.service';
import { SSSPCountryCode } from '@core/types/types';
import { SortService } from '@shared/services/sort/sort.service';

@Component({
  selector: 'sss-dropdown-company',
  templateUrl: './dropdown-company.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'sss-dropdown-company' },
})
export class DropdownCompanyComponent implements OnInit, OnChanges {
  @Input()
  public dark = false;

  @Input()
  public filter: CompanyOutletSelectorFilter;

  @Input()
  public disabled = false;

  @Input()
  public isCountryLevel = false;

  @Output()
  public changedCompany: EventEmitter<string> = new EventEmitter();

  public companyOptions: DropdownOption[] = [];
  public companyid: string;

  constructor(
    private readonly sortService: SortService,
    private readonly currentStateService: CurrentStateService,
    private readonly dropdownService: DropdownService,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly permissionsService: PermissionsService,
  ) {}


  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter) {
      this.companyid = this.filter.company;
    }
  }

  public ngOnInit(): void {
    this.makeCompanyOptions();

    const preSelectedCompanyId = this.route.snapshot.queryParams.companyId;

    if (preSelectedCompanyId) {
      this.emitCompany(preSelectedCompanyId);
      this.companyid = preSelectedCompanyId;
    }

    this.location.go(this.location.path().replace(/\?.*/g, ''));
  }

  public emitCompany(id: string): void {
    this.changedCompany.emit(id);
  }

  private makeCompanyOptions(): void {
    this.companyOptions = this.makeDropdownOptions();

    if (this.companyOptions.length === 1) {
      this.autoSelect();
    }
  }

  private autoSelect(): void {
    this.companyid = this.companyOptions[0].id;
    this.changedCompany.emit(this.companyid);
  }

  private makeDropdownOptions(): DropdownOption[] {
    let companies = this.currentStateService.get(AuthSelectors.allCompanies);
    const countriesWithPermissions = this.currentStateService.get(AuthSelectors.countriesWithPermissions);

    const { sellerCenterDomain, commerceDomain } = this.filter;

    const isSellerOverview = this.isCountryLevel &&
      sellerCenterDomain === SellerCenterDomain.PROFILE &&
      commerceDomain === CommerceDomain.MARKETPLACE;

    if (isSellerOverview) {
      companies = companies.filter((company) => this.dropdownService.hasMarketplaceServices(company, commerceDomain));
    }

    const elements = companies
      .filter((company) => this.dropdownService.hasDomain(company, sellerCenterDomain, commerceDomain))
      .filter((company) => {
        const isCompanyFromCountryEditor = this.isCountryLevel && countriesWithPermissions.includes(company.countryCode as SSSPCountryCode);
        const hasPermissionsAndDomain = !this.isCountryLevel &&  this.permissionsService.hasCompanyOrOutletPermissions(company);

        return isCompanyFromCountryEditor || hasPermissionsAndDomain;
      })
      .map((company) => new DropdownOption(company.companyId, `${ company.name }, ${ company.companyId }`))
      .sort((a, b) => this.sortService.sortStringAlphabeticallyIgnoreCase(a.value, b.value));

    return elements || [];
  }
}
