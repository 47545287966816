import { Async } from '@core/store/async.interfaces';
import { VehicleGroup } from '@generated/sssg-v2/models/vehicle-group';
import { State } from '@core/store/root.state';
import { WarrantyPackage } from '@generated/sssg-v2/models/warranty-package';

export interface WarrantyPackagesState extends State {
  products: Async<Array<WarrantyPackage>>;
  vehicleGroups: Async<Array<VehicleGroup>>;
  selectedProductCode: string;
}

export const WARRANTY_PACKAGES_INITIAL_STATE: WarrantyPackagesState = {
  products: {
    error: null,
    isPending: false,
    data: null,
  },
  vehicleGroups: {
    error: null,
    isPending: false,
    data: null,
  },
  selectedProductCode: null,
};
