import { CountryCode as _CountryCode, InterestStatus as _InterestStatus, UpdateOrCreateProductTypeInterests } from '@generated/sssp-v2/models';
import { Address } from '@generated/sssp/models/address';


export type OutletParticipationUpdated = Record<string, OutletParticipationData>;

export interface OutletParticipationData extends UpdateOrCreateProductTypeInterests{
  name?: string;
  address?: Address;
  vfnr?: string;
  country: CountryCode;
}

export const InterestStatus = {
  [ _InterestStatus.INTERESTED ]: _InterestStatus.INTERESTED,
  [ _InterestStatus.NOT_INTERESTED ]: _InterestStatus.NOT_INTERESTED,
  NO_VALUE: 'NO_VALUE',
  ONBOARDED: 'ONBOARDED',
  UNAVAILABLE: 'UNAVAILABLE',
};

export type SetupInterestStatus = keyof typeof InterestStatus;

export import CountryCode = _CountryCode;

export enum SetupRoute {
  ROOT = '/setup'
}

