import { Identity } from '@core/services/identity/identity.typings';
import { Company, Jwt } from '@core/types/types';
import { UserEntitlements } from '@generated/sssp-v2/models';
import { SellerOutlet } from '@generated/sssp/models/seller-outlet';
import { Action } from '@ngrx/store';

export const RESET_AUTH_STATE = '[Auth] RESET_AUTH_STATE';
export const SET_TOKEN = '[Auth] SET_TOKEN';
export const SET_IDENTITY = '[Auth] SET_IDENTITY';
export const SET_PROFILE = '[Auth] SET_PROFILE';
export const SET_SELECTED_COMPANY = '[Auth] SET_SELECTED_COMPANY';
export const SET_LEGAL_ENTITY_OUTLET = '[Auth] SET_LEGAL_ENTITY_OUTLET';

export class ResetAuthStateAction implements Action {
  public readonly type = RESET_AUTH_STATE;
}

export class SetTokenAction implements Action {
  public readonly type = SET_TOKEN;
  constructor(
    public readonly token: Jwt,
  ) {}
}

export class SetIdentityAction implements Action {
  public readonly type = SET_IDENTITY;
  constructor(
    public readonly identity: Identity,
  ) {}
}

export class SetSelectedCompanyAction implements Action {
  public readonly type = SET_SELECTED_COMPANY;
  constructor(
    public readonly company: Company,
  ) {}
}
export class SetLegalEntityOutletAction implements Action {
  public readonly type = SET_LEGAL_ENTITY_OUTLET;

  constructor(
    public readonly outlet: SellerOutlet,
  ) {}
}

export class SetProfileAction implements Action {
  public readonly type = SET_PROFILE;
  constructor(
    public readonly profile: UserEntitlements,
  ) {}
}
