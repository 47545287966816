<label
  *ngIf="!inline"
  (click)="toggleSelectList()"
  [for]="selectId">
  {{ label }}
</label>

<div
  class="h-position-relative">
  <div
    class="sss-combo-box__container h-flex-row"
    [ngClass]="{
      'sss-combo-box__container--dark': this.darkMode
    }">

    <label
      *ngIf="inline"
      class="sss-combo-box__label">
      {{ label }}
    </label>

    <input
      #input
      (click)="onInputClick($event)"
      (keydown.esc)="restoreSelectionOrDefault($event)"
      [disabled]="disabled"
      class="sss-combo-box__search"
      type="text"
      [value]="buttonText" />

    <svg
      *ngIf="options && options.length > 0 && !disabled"
      (click)="onInputClick($event)"
      class="sss-combo-box__arrow"
      [ngClass]="{
          'sss-combo-box__arrow--dark': this.darkMode,
          'sss-combo-box__arrow--up': this.expanded
      }"
      aria-hidden="true"
      viewBox="0 0 33.5 19.992">
      <polygon
        points="33.5,0 33.5,5.223 16.63,19.992 0,5.343 0,0.12 16.63,13.028">
      </polygon>
    </svg>
  </div>
</div>

<div
  class="h-position-relative">
  <select
    [id]="selectId"
    [name]="selectId"
    (change)="onNativeSelectionChange($event?.target?.value)"
    class="sss-combo-box__native"
    required>
    <option
      selected="selected"
      [value]="0">
      {{ defaultText }}
    </option>
    <option
      *ngFor="let option of filteredOptions"
      [value]="option.id">
      {{ option.value }}
    </option>
  </select>
</div>

<ul
  *ngIf="expanded"
  class="sss-combo-box__list"
  [style.display]="displayDropdown ? 'block' : 'none'"
  [ngClass]="{
    'sss-combo-box__list--dark': this.darkMode
  }">
  <li
    *ngFor="let option of filteredOptions"
    (click)="selectOption(option, $event)"
    class="sss-combo-box__list-item"
    [ngClass]="{
        'sss-combo-box__list-item--dark': this.darkMode
    }"
    [class.sss-combo-box__list-item--active]="option.id === selection">
    {{ option.value }}
  </li>
</ul>
