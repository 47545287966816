import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_APP_NAME } from '@core/config/global.config';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { CurrentStateService } from '@core/store/current-state.service';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';

@Injectable()
export class RequestHeaderExtenderInterceptor implements HttpInterceptor {
  constructor(
    private currentStateService: CurrentStateService,
    private windowRefService: WindowRefService,
  ) {}

  public intercept(req: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
    let extendedRequest: HttpRequest<any>;
    if (this.isClaimsRequest(req.url)) {
      extendedRequest = this.extendClaimsRequest(req);
    } else {
      extendedRequest = this.extendRequest(req);
    }
    return handler.handle(extendedRequest);
  }

  private extendRequest(req: HttpRequest<any>): HttpRequest<any> {
    return this.isRequestForSecuredUrl(req.url)
      ? req.clone({
        setHeaders: {
          Timestamp: this.currentIsoDate,
          ApplicationName: API_APP_NAME,
          applicationName: API_APP_NAME, // NOTE: some backends (Quotes) need to have that header as lower case instead of upper case
          Authorization: this.currentStateService.get<string>(AuthSelectors.token),
          CorrelationId: this.generateUuId(),
          TrackingId: this.generateUuId(),
        },
      })
      : req;
  }

  private extendClaimsRequest(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        ApplicationName: API_APP_NAME,
        applicationName: API_APP_NAME, // NOTE: some backends (Quotes) need to have that header as lower case instead of upper case
        Authorization: this.currentStateService.get<string>(AuthSelectors.token),
      },
    });
  }

  private findURL(obj: any, url: string): boolean {
    const values = Object.values(obj);
    let found = false;

    for (const i of values) {
      found = i instanceof Object ? this.findURL(i, url) : url.includes(i as string);

      if (found) {
        break;
      }
    }

    return found;
  }

  private isRequestForSecuredUrl(url: string): boolean {
    return this.findURL(this.windowRefService.environment.api.secured, url);
  }

  private isClaimsRequest(url: string): boolean {
    return this.windowRefService.environment.api.open?.claims === url;
  }

  private get currentIsoDate(): string {
    return new Date().toISOString();
  }

  private generateUuId(): string {
    return uuid();
  }
}
