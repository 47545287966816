<div
  class="sss-hint_type-icon">
  <sss-icon
    *ngIf="error"
    name="alert-circle">
  </sss-icon>

  <sss-icon
    *ngIf="confirmation"
    name="checkmark">
  </sss-icon>

  <sss-icon
    *ngIf="!error && !confirmation"
    name="question-mark-circle">
  </sss-icon>
</div>

<div
  class="sss-hint__text">
  <ng-content></ng-content>
</div>

<div
  *ngIf="dismissable"
  (click)="onDismiss()"
  class="sss-hint__close-icon">

  <sss-icon
    name="close">
  </sss-icon>
</div>
