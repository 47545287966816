import { Injectable, Renderer2 } from '@angular/core';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';

export const INACTIVE_BACKGROUND_COLOR_CLASS = 'sss-inactive-background-color';
export const BEIGE_BACKGROUND_COLOR_CLASS = 'sss-beige-background-color';
export const BLACK_BAR_CLASS = 'sss-black-bar';

@Injectable()
export class BackgroundColorService {
  constructor(
    private windowRefService: WindowRefService,
  ) {}

  public enableDefaultMode(renderer2: Renderer2): void {
    renderer2.removeClass(this.windowRefService.nativeDocument.body, INACTIVE_BACKGROUND_COLOR_CLASS);
    renderer2.removeClass(this.windowRefService.nativeDocument.body, BEIGE_BACKGROUND_COLOR_CLASS);
    renderer2.removeClass(this.windowRefService.nativeDocument.body, BLACK_BAR_CLASS);
  }

  public enableDarkMode(renderer2: Renderer2): void {
    renderer2.addClass(this.windowRefService.nativeDocument.body, INACTIVE_BACKGROUND_COLOR_CLASS);
  }

  public enableBeigeMode(renderer2: Renderer2): void {
    renderer2.addClass(this.windowRefService.nativeDocument.body, BEIGE_BACKGROUND_COLOR_CLASS);
  }

  public enableBlackBarMode(renderer2: Renderer2): void {
    renderer2.addClass(this.windowRefService.nativeDocument.body, BLACK_BAR_CLASS);
  }

  public enableBlackBarOverBeigeMode(renderer2: Renderer2): void {
    renderer2.addClass(this.windowRefService.nativeDocument.body, BLACK_BAR_CLASS);
    renderer2.addClass(this.windowRefService.nativeDocument.body, BEIGE_BACKGROUND_COLOR_CLASS);
  }
}
