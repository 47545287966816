import { SellerCenterShopName } from '@core/config/global.config';
import { State } from '@core/store/root.state';
import { Outlet } from '@core/types/types';

export interface ShopOutletSelectorState extends State {
  isVisible: boolean;
  selectedShop: SellerCenterShopName;
  selectedShopHasOutlets: boolean;
  selectedOutlet: Outlet;
  availableShopsFilter: SellerCenterShopName[];
}

export const SHOP_OUTLET_SELECTOR_INITIAL_STATE: ShopOutletSelectorState = {
  isVisible: false,
  selectedShop: null,
  selectedShopHasOutlets: null,
  selectedOutlet: null,
  availableShopsFilter: null,
};
