import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '@app/profile/profile.service';
import { SellerCenterMarket } from '@core/config/global.config';
import { SessionService } from '@core/services/session/session.service';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { CurrentStateService } from '@core/store/current-state.service';
import { RootState } from '@core/store/root.state';
import { CompanyId } from '@core/types/profile.types';
import { Company } from '@core/types/types';
import { CountryCode } from '@generated/sssp-v2/models';
import { Store } from '@ngrx/store';
import { ComboBoxOption } from '@shared/components/combo-box/combo-box-option.class';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';
import { SetLegalEntityOutletAction, SetSelectedCompanyAction } from '../store/auth/auth.actions';

export enum CompanySelectionMode {
  INITIAL = 'INITIAL',
  UPDATE = 'UPDATE',
}

@Component({
  selector: 'sss-company-selector',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: [ './company-selector.component.scss' ],
  templateUrl: './company-selector.component.html',
  host: { class: 'sss-company-selector' },
})
export class CompanySelectorComponent implements OnInit {
  @Input()
  public mode: CompanySelectionMode = CompanySelectionMode.INITIAL;

  @Output()
  public closed: EventEmitter<void> = new EventEmitter();

  public readonly CompanySelectionMode: typeof CompanySelectionMode = CompanySelectionMode;

  public supportedMarket = true;
  public dropdownOptions: ComboBoxOption[] = [];
  public company: Company = null;

  private companies: Company[];

  constructor(
    private readonly store: Store<RootState>,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly windowRefService: WindowRefService,
    private readonly currentStateService: CurrentStateService,
    private readonly profileService: ProfileService,
    private readonly sessionService: SessionService,
  ) {}

  public ngOnInit(): void {
    this.company = this.currentStateService.get(AuthSelectors.selectedCompany);
    /**
     * FYI
     * Must force shops for DE due onboarding tool
     */
    this.companies = this.currentStateService.get(AuthSelectors.allCompanies)
      .filter(c => c.shops.length || c.countryCode === CountryCode.DE as string );

    this.dropdownOptions = this.makeCompanyOptions();

    if (this.companies.length === 1) {
      this.skipSelector();
    }
  }

  public logout(): void {
    this.sessionService.logout();
  }

  public selectCompany(gcid: CompanyId): void {
    this.company = this.companies.find((company) => company.companyId === gcid);
    this.supportedMarket = this.company && this.isMarketAvailable(this.company.countryCode as SellerCenterMarket);
  }

  public done(): void {
    this.profileService
      .getSellerProfileInformation(this.company.countryCode, this.company.companyId)
      .subscribe((seller) => {
        const next = this.route.snapshot.queryParams.next;
        this.company.supportedLanguages = seller.profile.supportedLanguages;
        this.store.dispatch(new SetLegalEntityOutletAction(seller.legalEntity.outlet));
        this.store.dispatch(new SetSelectedCompanyAction(this.company));

        this.close();

        if (next) {
          this.router.navigate([ next ]);
        }

        // if (this.mode === CompanySelectionMode.INITIAL) {
        //   this.router.navigate([ AppRoute.DASHBOARD ]);
        // }
      });
  }

  public reloadPage(): void {
    this.windowRefService.nativeWindow.location.reload();
  }

  public close(): void {
    this.closed.emit();
  }

  private makeCompanyOptions(): ComboBoxOption[] {
    return this.companies
      .map((company: Company) => new ComboBoxOption(company.companyId, `${ company.name }, ${ company.companyId }`))
      .sort((a, b) => a.value.localeCompare(b.value));
  }

  private skipSelector(): void {
    this.company = this.companies[0];
    this.done();
  }

  private isMarketAvailable(market: SellerCenterMarket): boolean {
    return Boolean(SellerCenterMarket[ market ]);
  }
}
