import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ToasterNotification } from './toaster-notification.model';
import { ToasterNotificationType } from './toaster-notitfication-type';

const DISMISS_TIMEOUT_IN_MS = 6000;
const DISMISS_TIMEOUT_IN_MS_ON_RESTART = 2000;

class NotificationMap {
  private map: Map<string, ToasterNotification> = new Map();
  private subject: BehaviorSubject<Array<ToasterNotification>> = new BehaviorSubject([]);

  public set(key: string, value: ToasterNotification): void {
    this.map.set(key, value);
    this.next();
  }

  public delete(key: string): void {
    this.map.delete(key);
    this.next();
  }

  public asObservable(): Observable<Array<ToasterNotification>> {
    return this.subject.asObservable();
  }

  private next(): void {
    this.subject.next(Array.from(this.map.values()).reverse());
  }
}

class SubscriptionMap {
  private map: Map<string, Subscription> = new Map();

  public set(key: string, value: Subscription): void {
    this.map.set(key, value);
  }

  public removeSubscription(key: string): void {
    const subscription = this.map.get(key);
    if (subscription) {
      subscription.unsubscribe();
    }
    this.map.delete(key);
  }
}

@Injectable()
export class ToasterService {
  private notifications: NotificationMap = new NotificationMap();
  private timerSubscriptions: SubscriptionMap = new SubscriptionMap();

  public showError(message: string, autoDismiss = true, autoDismissTimeoutInMs: number = DISMISS_TIMEOUT_IN_MS): void {
    const notification = new ToasterNotification(message, ToasterNotificationType.ERROR, autoDismiss, autoDismissTimeoutInMs);
    this.push(notification);
  }

  public showInformation(message: string, autoDismiss = true, autoDismissTimeoutInMs: number = DISMISS_TIMEOUT_IN_MS): void {
    const notification = new ToasterNotification(message, ToasterNotificationType.INFORMATION, autoDismiss, autoDismissTimeoutInMs);
    this.push(notification);
  }

  public showConfirmation(message: string, autoDismiss = true, autoDismissTimeoutInMs: number = DISMISS_TIMEOUT_IN_MS, actionLabel?: string, action?: any): ToasterNotification {
    const notification = new ToasterNotification(message, ToasterNotificationType.CONFIRMATION, autoDismiss, autoDismissTimeoutInMs, actionLabel, action);
    this.push(notification);
    return notification;
  }

  public dismiss(notification: ToasterNotification): void {
    this.notifications.delete(notification.id);
  }

  public notificationsAsObservable(): Observable<Array<ToasterNotification>> {
    return this.notifications.asObservable();
  }

  public interruptDisappearTimeout(notification: ToasterNotification): void {
    this.timerSubscriptions.removeSubscription(notification.id);
  }

  public restartDisappearTimeout(notification: ToasterNotification): void {
    this.startTimerToDeleteNotification(notification, DISMISS_TIMEOUT_IN_MS_ON_RESTART);
  }

  private push(notificaton: ToasterNotification): void {
    this.notifications.set(notificaton.id, notificaton);

    if (notificaton.autoDismiss) {
      this.registerForAutoDismiss(notificaton);
    }
  }

  private registerForAutoDismiss(identifiableNotifaction: ToasterNotification): void {
    this.startTimerToDeleteNotification(identifiableNotifaction, identifiableNotifaction.autoDismissTimeoutInMs);
  }

  private startTimerToDeleteNotification(identifiableNotifaction: ToasterNotification, delayInMs: number): void {
    const subscription = of(identifiableNotifaction)
      .pipe(delay(delayInMs))
      .subscribe((notification: ToasterNotification) => {
        this.notifications.delete(notification.id);
        this.timerSubscriptions.removeSubscription(notification.id);
      });

    this.timerSubscriptions.set(identifiableNotifaction.id, subscription);
  }
}
