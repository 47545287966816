import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import * as ShopOutletSelectorSelectors from '@core/shop-outlet-selector/store/shop-outlet-selector.selectors';
import { RootState } from '@core/store/root.state';
import { Store } from '@ngrx/store';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';
import { Observable } from 'rxjs';
import { UserPermissions, UserRole } from '../types/user-role';
import * as AuthSelectors from '../store/auth/auth.selectors';
import * as SessionSelectors from '@core/store/session/session.selectors';
import * as CompanyOutletSelector2Selectors from '@core/company-outlet-selector-2/store/company-outlet-selector-2.selectors';
import { tap } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'sss-header',
  styleUrls: [ './header.component.scss' ],
  templateUrl: './header.component.html',
  host: { class: 'sss-header' },
})
export class HeaderComponent implements OnInit {
  @Output()
  public clickedCompanySelector: EventEmitter<void> = new EventEmitter();

  public isProd = false;

  public isReady$: Observable<boolean>;
  public isShopOutletSelectorVisible$: Observable<boolean>;
  public isCompanyOutletSelector2Visible$: Observable<boolean>;
  public userRole$: Observable<UserRole>;
  public userPermissions$: Observable<UserPermissions>;

  constructor(
    private readonly windowRefService: WindowRefService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store<RootState>,
  ) {}

  public ngOnInit(): void {
    this.isProd = this.windowRefService.environment.production;

    this.isReady$ = this.store.select(SessionSelectors.isReady);
    this.userRole$ = this.store.select(AuthSelectors.userRole);
    this.userPermissions$ = this.store.select(AuthSelectors.userPermissions);

    this.isShopOutletSelectorVisible$ = this.store
      .select(ShopOutletSelectorSelectors.isVisible)
      .pipe(tap(() => this.changeDetectorRef.detectChanges()));

    this.isCompanyOutletSelector2Visible$ = this.store
      .select(CompanyOutletSelector2Selectors.active)
      .pipe(tap(() => this.changeDetectorRef.detectChanges()));
  }

  public emitClickCompanySelector(): void {
    this.clickedCompanySelector.emit();
  }
}
