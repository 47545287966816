import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CurrentStateService } from '@core/store/current-state.service';
import { AppRoute } from '../../app-routes.enum';
import * as AuthSelectors from '../store/auth/auth.selectors';

@Injectable()
export class SellerGuard implements CanActivate {
  constructor(
    private router: Router,
    private currentStateService: CurrentStateService,
  ) {}

  public canActivate(): boolean {
    const { OUTLET_EDITOR, COMPANY_EDITOR } = this.currentStateService.get(AuthSelectors.userPermissions);

    if (!OUTLET_EDITOR && !COMPANY_EDITOR) {
      this.router.navigate([ AppRoute.DASHBOARD ]);
    }

    return OUTLET_EDITOR || COMPANY_EDITOR;
  }
}
