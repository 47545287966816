import { Injectable } from '@angular/core';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';
import { ToggleableFeature } from './toggleable-feature';

// TODO: create a pipe to resolve feature availability more easily in templates

const PROD_FEATURE_MAP = {
  // [ ToggleableFeature.SOME_FEATURE_IDENTIFIER ]: false,
};

@Injectable()
export class FeatureToggleService {
  constructor(
    private readonly windowRefService: WindowRefService,
  ) {}

  public isFeatureEnabledForProd(feature: ToggleableFeature): boolean {
    if (!this.windowRefService.environment.production) {
      return true;
    }

    return Boolean(PROD_FEATURE_MAP[ feature ]);
  }
}
