/* tslint:disable */

/**
 * Possible order statuses.
 */
export enum OrderStatus {
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  REFUNDED = 'REFUNDED',
  COMPLETED = 'COMPLETED',
  CANCELLING = 'CANCELLING',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR'
}
