<sss-dropdown
  (selectionChange)="emitCompany($event)"
  [selection]="companyid"
  [defaultText]="('common.dropdown.chooseOptionText' | sssTranslate | async)"
  [options]="companyOptions"
  [darkMode]="dark"
  [disabled]="companyOptions.length < 2 || disabled"
  [label]="'common.selector.company' | sssTranslate | async"
  autoExpand>
</sss-dropdown>
