import { Async } from '@core/store/async.interfaces';
import { BaseProduct } from '@generated/sssg/models/base-product';
import { SellerProductPriceContainer } from '@generated/sssg/models/seller-product-price-container';
import { VehicleGroup } from '@generated/sssg/models/vehicle-group';
import { State } from '@core/store/root.state';

export interface PricingGPmeState extends State {
  baseProducts: Async<Array<BaseProduct>>;
  products: Async<SellerProductPriceContainer>;
  vehicleGroups: Async<Array<VehicleGroup>>;
}

export const PRICING_GPME_INITIAL_STATE: PricingGPmeState = {
  baseProducts: {
    error: null,
    isPending: false,
    data: null,
  },
  products: {
    error: null,
    isPending: false,
    data: null,
  },
  vehicleGroups: {
    error: null,
    isPending: false,
    data: null,
  },
};
