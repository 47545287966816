import { ActionMap, reduce } from '@core/store/root.state';
import { BaseProduct } from '@generated/sssg/models/base-product';
import { Product } from '@generated/sssg/models/product';
import { ProductVariant } from '@generated/sssg/models/product-variant';
import { SellerProductPrice } from '@generated/sssg/models/seller-product-price';
import { Action } from '@ngrx/store';
import * as PricingActions from './pricing-gpme.actions';
import { PricingGPmeState, PRICING_GPME_INITIAL_STATE } from './pricing-gpme.state';

const REDUCER_ACTION_MAP: ActionMap = {
  [ PricingActions.FETCH_PRODUCTS_REQUEST ]: fetchProductsRequest,
  [ PricingActions.FETCH_PRODUCTS_SUCCESS ]: fetchProductsSuccess,
  [ PricingActions.FETCH_PRODUCTS_FAILURE ]: fetchProductsFailure,
  [ PricingActions.UPDATE_PRODUCT_PRICE_REQUEST ]: updateProductsRequest,
  [ PricingActions.UPDATE_PRODUCT_PRICE_SUCCESS ]: updateProductsSuccess,
  [ PricingActions.UPDATE_PRODUCT_PRICE_FAILURE ]: updateProductsFailure,
  [ PricingActions.DELETE_PRODUCT_PRICE_REQUEST ]: deleteProductPriceRequest,
  [ PricingActions.DELETE_PRODUCT_PRICE_SUCCESS ]: deleteProductPriceSuccess,
  [ PricingActions.DELETE_PRODUCT_PRICE_FAILURE ]: deleteProductPriceFailure,
  [ PricingActions.FETCH_BASE_PRODUCTS_REQUEST ]: fetchBaseProductsRequest,
  [ PricingActions.FETCH_BASE_PRODUCTS_SUCCESS ]: fetchBaseProductsSuccess,
  [ PricingActions.FETCH_BASE_PRODUCTS_FAILURE ]: fetchBaseProductsFailure,
  [ PricingActions.FETCH_VEHICLE_GROUPS_REQUEST ]: fetchVehicleGroupsRequest,
  [ PricingActions.FETCH_VEHICLE_GROUPS_SUCCESS ]: fetchVehicleGroupsSuccess,
  [ PricingActions.FETCH_VEHICLE_GROUPS_FAILURE ]: fetchVehicleGroupsFailure,
};

export function pricingGPMeReducer(state: PricingGPmeState = PRICING_GPME_INITIAL_STATE, action: Action): PricingGPmeState {
  return reduce(REDUCER_ACTION_MAP, action, state);
}

function resetState(state: PricingGPmeState): PricingGPmeState {
  return {
    ...state,
    products: {
      ...state.products,
      error: null,
      // note: we don't want to set the global loading state of products, because not all products are reloading but just one
      isPending: false,
    },
  };
}

function fetchProductsRequest(state: PricingGPmeState): PricingGPmeState {
  return {
    ...state,
    products: {
      error: null,
      isPending: true,
      data: null,
    },
  };
}

function fetchProductsSuccess(state: PricingGPmeState, action: PricingActions.FetchProductsSuccessAction): PricingGPmeState {
  return {
    ...state,
    products: {
      error: null,
      isPending: false,
      data: action.products,
    },
  };
}

function fetchProductsFailure(state: PricingGPmeState, action: PricingActions.FetchProductsFailureAction): PricingGPmeState {
  return {
    ...state,
    products: {
      error: action.error,
      isPending: false,
      data: null,
    },
  };
}

function updateProductsRequest(state: PricingGPmeState): PricingGPmeState {
  return resetState(state);
}

function updateProductsSuccess(state: PricingGPmeState, action: PricingActions.UpdateProductPriceSuccessAction): PricingGPmeState {
  return {
    ...state,
    products: {
      error: null,
      isPending: false,
      data: state.products.data.map((baseProduct: BaseProduct) => ({
        ...baseProduct,
        variants: baseProduct.variants.map((productVariant: ProductVariant) => ({
          ...productVariant,
          products: productVariant.products.map((product: Product) => {
            let updatedSellerPrice = null;

            action.products
              .forEach((updatedProduct: SellerProductPrice) => {
                if (updatedProduct.productId === product.productId) {
                  updatedSellerPrice = updatedProduct.sellerPrice;
                }
              });

            if (updatedSellerPrice) {
              return {
                ...product,
                sellerPrice: updatedSellerPrice,
              };
            } else {
              return product;
            }
          }),
        })),
      })),
    },
  };
}

function updateProductsFailure(state: PricingGPmeState): PricingGPmeState {
  return state; // note: single product update error won't cause the whole view to display error state but will trigger toaster
}

function fetchBaseProductsRequest(state: PricingGPmeState): PricingGPmeState {
  return {
    ...state,
    baseProducts: {
      error: null,
      isPending: true,
      data: null,
    },
  };
}

function fetchBaseProductsSuccess(state: PricingGPmeState, action: PricingActions.FetchBaseProductsSuccessAction): PricingGPmeState {
  return {
    ...state,
    baseProducts: {
      error: null,
      isPending: false,
      data: action.products,
    },
  };
}

function fetchBaseProductsFailure(state: PricingGPmeState, action: PricingActions.FetchBaseProductsFailureAction): PricingGPmeState {
  return {
    ...state,
    baseProducts: {
      error: action.error,
      isPending: false,
      data: null,
    },
  };
}

function fetchVehicleGroupsRequest(state: PricingGPmeState): PricingGPmeState {
  return {
    ...state,
    vehicleGroups: {
      error: null,
      isPending: true,
      data: null,
    },
  };
}

function fetchVehicleGroupsSuccess(state: PricingGPmeState, action: PricingActions.FetchVehicleGroupsSuccessAction): PricingGPmeState {
  return {
    ...state,
    vehicleGroups: {
      error: null,
      isPending: false,
      data: action.vehicleGroups,
    },
  };
}

function fetchVehicleGroupsFailure(state: PricingGPmeState, action: PricingActions.FetchVehicleGroupsFailureAction): PricingGPmeState {
  return {
    ...state,
    vehicleGroups: {
      error: action.error,
      isPending: false,
      data: null,
    },
  };
}

function deleteProductPriceRequest(state: PricingGPmeState): PricingGPmeState {
  return resetState(state);
}

function deleteProductPriceSuccess(state: PricingGPmeState, action: PricingActions.DeleteProductPriceSuccessAction): PricingGPmeState {
  return {
    ...state,
    products: {
      error: null,
      isPending: false,
      data: state.products.data.map((baseProduct) => ({
        ...baseProduct,
        variants: baseProduct.variants.map((productVariant) => ({
          ...productVariant,
          products: productVariant.products.map((product) => {
            if (action.productId === product.productId) {
              return {
                ...product,
                sellerPrice: null,
              };
            } else {
              return product;
            }
          }),
        })),
      })),
    },
  };
}

function deleteProductPriceFailure(state: PricingGPmeState): PricingGPmeState {
  return state; // note: single product update error won't cause the whole view to display error state but will trigger toaster
}
