<aside class="sss-sidebar">
  <wb-tooltip
    class="sss-sidebar__link"
    position="end"
  >
    <wb-button
      [routerLink]="Route.FEEDBACK"
      aria-label="Feedback"
      icon-only
      size="s"
      variant="tertiary"
    >
      <wb-icon
        slot="icon"
        name="comment"
        aria-hidden="true"
      >
      </wb-icon>
    </wb-button>
    <div slot="content">{{ 'common.sidebar.feedback' | sssTranslate | async }}</div>
  </wb-tooltip>

  <wb-tooltip
    class="sss-sidebar__link"
    position="end"
  >
    <wb-button
      [routerLink]="Route.HELP"
      aria-label="Support"
      icon-only
      size="s"
      variant="tertiary"
    >
      <wb-icon
        slot="icon"
        name="question-mark-outline"
        aria-hidden="true"
      >
      </wb-icon>
    </wb-button>
    <div slot="content">{{ 'common.sidebar.support' | sssTranslate | async }}</div>
  </wb-tooltip>
</aside>
