<wb-select #select>
  <select
    (change)="selectOption($event.target.value)"
    [disabled]="disabled"
    class="sss-dropdown__select"
    [ngClass]="{'compact': compact}"
  >
    <option
      *ngIf="defaultText"
      [selected]="!selection"
      value=""
      disabled
    >
      {{ defaultText }}
    </option>
    <option
      *ngFor="let option of options"
      [selected]="selection === option.id"
      [value]="option.id"
    >
      {{ option.value | sssTranslate | async }}
    </option>
  </select>
</wb-select>
