import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { I18nService } from '@core/i18n/i18n.service';
import { Subscription } from 'rxjs';
import { APP_NAME } from '@core/config/global.config';

@Injectable()
export class DocumentTitleService {
  private subscription: Subscription;

  constructor(
    private i18nService: I18nService,
    private titleService: Title,
  ) {}

  public setTitle(titleKey: string, params?: Record<string, unknown>): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.i18nService
      .getTranslation(titleKey, params)
      .subscribe((newTitle) => {
        this.titleService.setTitle(`${ newTitle } - ${ APP_NAME }`);
      });
  }
}
