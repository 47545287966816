import { Component, ChangeDetectionStrategy, ViewEncapsulation, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sss-warning-bar',
  templateUrl: './warning-bar.component.html',
  styleUrls: [ './warning-bar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'sss-warning-bar' },
})

export class WarningBarComponent {
  @Output() public dismissed: EventEmitter<void> = new EventEmitter();

  public dismiss(): void {
    this.dismissed.emit();
  }
}
