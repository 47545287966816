<ng-container *ngIf="translationsLoaded">
  <sss-warning-bar
    *ngIf="showBrowserWarning"
    (dismissed)="dismissWarning()">
  </sss-warning-bar>

  <div class="sss-app">
    <div class="sss-app__navbar">
      <sss-header
        (clickedCompanySelector)="openCompanySelector()">
      </sss-header>
    </div>

    <div class="sss-app__asidebar">
      <sss-sidebar
        *ngIf="(isLoggedIn$ | async)">
      </sss-sidebar>
    </div>

    <div
      class="sss-app__content"
      [style.backgroundImage]="'url(' + getBackgroundImage() + ')'"
      [class.dashboard]="isDashboard"
    >
      <div class="sss-app__content__container">
        <router-outlet></router-outlet>
      </div>

      <!-- The reason why it is here and not in dashboard is that it is possible to change companies without loggin out and in again (once implemented) -->
      <sss-company-selector
        *ngIf="(companySelector$ | async) || showCompanySelector"
        (closed)="closeCompanySelector()"
        [mode]="companySelectionMode">
      </sss-company-selector>

      <!-- <sss-commerce-domain-selector
        *ngIf="(commerceDomainSelector$ | async)">
      </sss-commerce-domain-selector> -->
    </div>

  </div>

  <div
    class="h-hide-for-mq-gt-2 sss-app__mobile-lockout-overlay">
    <wb-heading
      size="xs"
      class="sss-app__mobile-lockout-overlay__inner">
      Seller.Center is currently not available for mobile devices. Please open Seller.Center on your desktop computer.
    </wb-heading>
  </div>

  <sss-loader *ngIf="(loading$ | async)"></sss-loader>
  <sss-toaster></sss-toaster>
</ng-container>
