import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

const FALLBACK_TIME_FORMAT = 'hh:mm aaa';

@Pipe({
  name: 'sssTime',
})
export class TimePipe implements PipeTransform {
  public transform(value: string | number, timeFormat: string = FALLBACK_TIME_FORMAT): string {
    return value ? format(new Date(value), timeFormat) : '';
  }
}
