import { Async } from '@core/store/async.interfaces';
import { State } from '@core/store/root.state';
import { DocumentOverview } from '../document.typings';
import { FileForm } from '../document-marketplace/document-marketplace.typings';

export interface DocumentState extends State {
  overview: Async<DocumentOverview>;
  fileForm: FileForm;
}

export const DOCUMENT_INITIAL_STATE: DocumentState = {
  overview: {
    data: null,
    isPending: false,
    error: null,
  },
  fileForm: {
    name: null,
    version: null,
    file: {
      name: null,
      error: null,
      base64: null,
      blob: null,
    },
  },
};
