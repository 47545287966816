<sss-modal
  [showCloseButton]="false"
  compact
  [headline]="'common.companySelector.headline' | sssTranslate | async">

    <modal-content>
      <p>{{ 'common.companySelector.paragraph' | sssTranslate | async }}</p>

      <sss-combo-box
        (selectionChange)="selectCompany($event)"
        [defaultText]="'common.companySelector.dropdown.defaultText' | sssTranslate | async"
        [options]="dropdownOptions"
        [selection]="company?.companyId"
        [label]="'common.companySelector.dropdown.label' | sssTranslate | async"
        class="h-margin-top sss-company-selector__content"
        autoExpand
        inline>
      </sss-combo-box>

      <sss-hint
        *ngIf="!supportedMarket"
        error>
        {{ 'common.errors.unsupportedMarket' | sssTranslate:selectedProfile | async }}
      </sss-hint>
    </modal-content>

    <modal-footer
      class="h-flex-row h-flex-spread">

      <wb-button
        (click)="(mode && mode === CompanySelectionMode.INITIAL) ? logout() : close()"
        size="s"
        variant="secondary">
        {{ 'common.companySelector.buttons.cancel' | sssTranslate | async }}
      </wb-button>

      <wb-button
        (click)="done()"
        [disabled]="!company || !supportedMarket"
        size="s"
        variant="primary">
        {{ 'common.companySelector.buttons.continue' | sssTranslate | async }}
      </wb-button>

    </modal-footer>
</sss-modal>
