import { ErrorHandler, Injectable } from '@angular/core';
import { ToasterService } from '@shared/components/toaster/toaster.service';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';
import { Logger, LoggerService } from '../logger/logger.service';

@Injectable()
export class ErrorService implements ErrorHandler {
  private logger: Logger = null;

  constructor(
    private readonly loggerService: LoggerService,
    private readonly windowRefService: WindowRefService,
    private readonly toasterService: ToasterService,
  ) {
    this.logger = this.loggerService.getLogger('ErrorService');
  }

  public handleError(error: Error): void {
    if (!error) {
      return;
    }

    if (this.windowRefService.environment.logErrors) {
      this.reportError();
    }

    this.logger.error(error);
  }

  private showNotification(): void {
    const ERROR_REPORTED_KEY = 'common.errors.occurredAndReported';

    this.toasterService.showError(ERROR_REPORTED_KEY);
  }

  private reportError(): void {
    this.showNotification();
  }
}
