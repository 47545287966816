import { CommerceDomain, SellerCenterMarket } from '@core/config/global.config';
import { ShopParticipation } from '@core/store/auth/shop-participation.interface';
import { Company } from '@core/types/types';
import { UserPermission, UserPermissions, UserRole } from '@core/types/user-role';
import { UserEntitlements } from '@generated/sssp-v2/models';
import { Action } from '@ngrx/store';
import { ActionMap, reduce } from '../root.state';
import * as AuthActions from './auth.actions';
import { AuthState, AUTH_INITIAL_STATE } from './auth.state';

const REDUCER_ACTION_MAP: ActionMap = {
  [ AuthActions.RESET_AUTH_STATE ]: resetAuthState,
  [ AuthActions.SET_TOKEN ]: setToken,
  [ AuthActions.SET_IDENTITY ]: setIdentity,
  [ AuthActions.SET_PROFILE ]: setProfile,
  [ AuthActions.SET_SELECTED_COMPANY ]: setSelectedCompany,
  [ AuthActions.SET_LEGAL_ENTITY_OUTLET ]: setLegalEntityOutlet,
};

// TODO: Why is that the fallback!? Why not 'de'? Fallback should come from Identiy Endpoint / AuthState
// const FALLBACK_SUPPORTED_LANGUAGE = 'en';
const FALLBACK_COUNTRY_ISO_CODE = 'GB';

export function authReducer(state: AuthState = AUTH_INITIAL_STATE, action: Action): AuthState {
  return reduce(REDUCER_ACTION_MAP, action, state);
}

function resetAuthState(): AuthState {
  return AUTH_INITIAL_STATE;
}

function setToken(state: AuthState, action: AuthActions.SetTokenAction): AuthState {
  return {
    ...state,
    token: action.token,
  };
}

function setIdentity(state: AuthState, action: AuthActions.SetIdentityAction): AuthState {
  return {
    ...state,
    identity: action.identity,
  };
}

function setProfile(state: AuthState, action: AuthActions.SetProfileAction): AuthState {
  return {
    ...state,
    profile: action.profile,
    userRole: getUserRole(action.profile),
    userPermissions: getUserPermissions(action.profile),
    commerceDomains: getCommerceDomains(action.profile),
  };
}

function getUserPermissions(profile: UserEntitlements): UserPermissions {
  const availableCountries = profile.countries;

  const countryWithPermissions = Object.keys(availableCountries).filter((countryCode) => Boolean(availableCountries[countryCode].permissions));

  const countryCompanies = Object.values(availableCountries).flatMap((country) => Object.values(country.companies));

  const hasCompanyPermissions = countryCompanies.some((company) => Boolean(company.permissions));

  const hasOutletPermissions = countryCompanies
    .filter((company) => Boolean(company.outlets))
    .flatMap((company) => Object.values(company.outlets))
    .some((outlet) => Boolean(outlet.permissions));

  return {
    [UserPermission.COUNTRY_EDITOR]: Boolean(countryWithPermissions.length),
    [UserPermission.COMPANY_EDITOR]: hasCompanyPermissions,
    [UserPermission.OUTLET_EDITOR]: hasOutletPermissions,
  };
}

function getCommerceDomains(profile: UserEntitlements): CommerceDomain[] {
  let commerceDomains = [ CommerceDomain.MARKETPLACE, CommerceDomain.SHOPS ];
  const userPermissions = getUserPermissions(profile);

  const hasCountryEditorDE = Boolean(profile.countries.DE) && Boolean(profile.countries.DE.permissions);

  if (userPermissions.COUNTRY_EDITOR && hasCountryEditorDE) {
    return commerceDomains;
  }

  if (userPermissions.COUNTRY_EDITOR && userPermissions.COMPANY_EDITOR) {
    return validateCommerceDomains(profile, commerceDomains);
  }

  if (userPermissions.COUNTRY_EDITOR && !userPermissions.COMPANY_EDITOR) {
    return [ CommerceDomain.MARKETPLACE ];
  }

  if (!userPermissions.COUNTRY_EDITOR) {
    if (!userPermissions.OUTLET_EDITOR) {
      commerceDomains = commerceDomains.filter((domain) => domain !== CommerceDomain.MARKETPLACE);
    }
    if (!userPermissions.COMPANY_EDITOR) {
      commerceDomains = commerceDomains.filter((domain) => domain !== CommerceDomain.SHOPS);
    }

    return validateCommerceDomains(profile, commerceDomains);
  }

  return [];
}

function validateCommerceDomains(profile: UserEntitlements, commerceDomains: CommerceDomain[]): CommerceDomain[] {
  return commerceDomains
    .filter((commerceDomain) => {
      const hasAvailableProductTypes = Object.values(profile.countries)
        .some((country) => country.availableProductTypes && Boolean(country.availableProductTypes.length));

      const hasCountryPermissions = Object.values(profile.countries)
        .some((country) => Boolean(country.permissions));

      switch (commerceDomain) {
        case CommerceDomain.SHOPS:
          return (profile.onboardedLegacyShops && profile.onboardedLegacyShops.length);

        case CommerceDomain.MARKETPLACE:
          return (profile.onboardedProductTypes && profile.onboardedProductTypes.length) ||
        (profile.interestedProductTypes && profile.interestedProductTypes.length) ||
        hasAvailableProductTypes ||
        hasCountryPermissions;

        default:
          return false;
      }
    });
}

function getUserRole(profile: UserEntitlements): UserRole {
  const hasCountryPermissions = SellerCenterMarket.DE in profile.countries && Boolean(profile.countries[SellerCenterMarket.DE].permissions);

  return hasCountryPermissions ? UserRole.COUNTRY_MANAGER : UserRole.SELLER;
}

function setSelectedCompany(state: AuthState, action: AuthActions.SetSelectedCompanyAction): AuthState {
  return {
    ...state,
    selectedCompany: action.company,
    // TODO !refact shopParticipations
    shopParticipations: action.company.shops.map(shop => new ShopParticipation(shop, true)),
    companyCountryCode: getCompanyCountryCode(action.company),
  };
}

function getCompanyCountryCode(profile: Company): string {
  if (!profile) {
    return `${ FALLBACK_COUNTRY_ISO_CODE }`;
  }

  const countryIsoCode = profile.countryCode || FALLBACK_COUNTRY_ISO_CODE;
  return `${ countryIsoCode }`;
}

function setLegalEntityOutlet(state: AuthState, action: AuthActions.SetLegalEntityOutletAction): AuthState {
  return {
    ...state,
    legalEntity: action.outlet,
  };
}
