import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { ToasterNotification } from './toaster-notification.model';
import { ToasterNotificationType } from './toaster-notitfication-type';
import { ToasterService } from './toaster.service';

@Component({
  selector: 'sss-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: [ './toaster.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'sss-toaster' },
})
export class ToasterComponent implements OnInit {
  public notificationType = ToasterNotificationType;

  public notificationIcons = {
    [this.notificationType.ERROR]: 'alert-circle',
    [this.notificationType.INFORMATION]: 'alert',
    [this.notificationType.CONFIRMATION]: 'checkmark-circle',
  };

  public notifications$: Observable<Array<ToasterNotification>> = null;

  constructor(
    private toasterService: ToasterService,
  ) { }

  public ngOnInit(): void {
    this.notifications$ = this.toasterService.notificationsAsObservable();
  }

  public onDismiss(notification: ToasterNotification): void {
    this.toasterService.dismiss(notification);
  }

  public trackByFn(index: number, item: ToasterNotification): any {
    return item ? item.id : index;
  }

  public interruptDisappearTimeout(notification: ToasterNotification): void {
    if (notification.autoDismiss) {
      this.toasterService.interruptDisappearTimeout(notification);
    }
  }

  public restartDisappearTimeout(notification: ToasterNotification): void {
    if (notification.autoDismiss) {
      this.toasterService.restartDisappearTimeout(notification);
    }
  }
}
