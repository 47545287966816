import { ActionMap, reduce } from '@core/store/root.state';
import { Action } from '@ngrx/store';
import { CompanyOutletSelector2ActionTypes, SelectCompanyAction, SelectMarketAction, SelectOutletAction } from './company-outlet-selector-2.actions';
import { CompanyOutletSelector2State, COMPANY_OUTLET_SELECTOR_2_INITIAL_STATE } from './company-outlet-selector-2.state';

export function companyOutletSelector2Reducer(
  state: CompanyOutletSelector2State = COMPANY_OUTLET_SELECTOR_2_INITIAL_STATE,
  action: Action,
): CompanyOutletSelector2State {
  return reduce(REDUCER_ACTION_MAP, action, state);
}

function reset(): CompanyOutletSelector2State {
  return { ...COMPANY_OUTLET_SELECTOR_2_INITIAL_STATE };
}

function activateSelector(state: CompanyOutletSelector2State): CompanyOutletSelector2State {
  return {
    ...state,
    active: true,
  };
}
function enableSelector(state: CompanyOutletSelector2State): CompanyOutletSelector2State {
  return {
    ...state,
    disabled: false,
  };
}

function disableSelector(state: CompanyOutletSelector2State): CompanyOutletSelector2State {
  return {
    ...state,
    disabled: true,
  };
}

function selectCompany(state: CompanyOutletSelector2State, action: SelectCompanyAction): CompanyOutletSelector2State {
  return {
    ...state,
    company: action.company,
  };
}

function selectOutlet(state: CompanyOutletSelector2State, action: SelectOutletAction): CompanyOutletSelector2State {
  return {
    ...state,
    outlet: action.outlet,
  };
}

function selectMarket(state: CompanyOutletSelector2State, action: SelectMarketAction): CompanyOutletSelector2State {
  return {
    ...state,
    market: action.market,
  };
}


const REDUCER_ACTION_MAP: ActionMap = {
  [ CompanyOutletSelector2ActionTypes.RESET ]: reset,
  [ CompanyOutletSelector2ActionTypes.ACTIVATE_SELECTOR ]: activateSelector,
  [ CompanyOutletSelector2ActionTypes.SELECT_COMPANY ]: selectCompany,
  [ CompanyOutletSelector2ActionTypes.SELECT_OUTLET ]: selectOutlet,
  [ CompanyOutletSelector2ActionTypes.ENABLE_SELECTOR]: enableSelector,
  [ CompanyOutletSelector2ActionTypes.DISABLE_SELECTOR]: disableSelector,
  [ CompanyOutletSelector2ActionTypes.SELECT_MARKET]: selectMarket,
};
