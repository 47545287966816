import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '../icon/icon.module';
import { ModalComponent } from './modal.component';

@NgModule({
  declarations: [ ModalComponent ],
  exports: [ ModalComponent ],
  imports: [
    CommonModule,
    IconModule,
  ],
})
export class ModalModule {}
