<wb-grid>
  <wb-grid-row>
    <wb-grid-col mq3="12" mq4="6">
      <sss-dropdown
        #shopDropdown
        (selectionChange)="selectShop($event)"
        [selection]="selectedShop"
        [defaultText]="'common.shopOutletSelector.defaultText' | sssTranslate | async"
        [options]="shopOptions"
        [disabled]="shopOptions?.length === 0"
        [label]=" 'common.shopOutletSelector.shop' | sssTranslate | async"
        class="sss-shop-outlet-selector__shop"
        ></sss-dropdown>
    </wb-grid-col>

    <wb-grid-col mq3="12" mq4="6">
      <sss-dropdown
        #outletDropdown
        (selectionChange)="selectOutlet($event)"
        [selection]="selectedOutlet"
        [defaultText]="'common.shopOutletSelector.defaultText' | sssTranslate | async"
        [options]="outletOptions"
        [disabled]="outletOptions?.length === 0"
        [label]="'common.shopOutletSelector.outlet' | sssTranslate | async"
        class="sss-shop-outlet-selector__outlet"
        ></sss-dropdown>
    </wb-grid-col>
  </wb-grid-row>
</wb-grid>
