import { ActionMap, reduce } from '@core/store/root.state';
import { Action } from '@ngrx/store';
import { OrderActionTypes, SelectOrderAction } from './orders.actions';
import { OrderState, ORDER_INITIAL_STATE } from './orders.state';

export function orderReducer(
  state: OrderState = ORDER_INITIAL_STATE,
  action: Action,
): OrderState {
  return reduce(REDUCER_ACTION_MAP, action, state);
}

function selectOrder(state: OrderState, action: SelectOrderAction): OrderState {
  return {
    ...state,
    selectedOrder: action.order,
  };
}

function reset(): OrderState {
  return { ...ORDER_INITIAL_STATE };
}

const REDUCER_ACTION_MAP: ActionMap = {
  [OrderActionTypes.RESET]: reset,
  [OrderActionTypes.SELECT_ORDER]: selectOrder,
};
