import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DropdownModule } from '@shared/components/dropdown/dropdown.module';
import { DropdownOutletComponent } from './dropdown-outlet.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';

@NgModule({
  declarations: [
    DropdownOutletComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
    DropdownModule,
  ],
  exports: [
    DropdownOutletComponent,
  ],
})
export class DropdownOutletModule { }
