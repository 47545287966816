<wb-notification-host>
  <wb-notification
    *ngFor="let notification of (notifications$ | async); trackBy: trackByFn"
    theme="light"
    [variant]="notification?.notificationType"
    [lifetime]="notification.autoDismiss ? notification.autoDismissTimeoutInMs : 0"
    [icon]="notificationIcons[notification?.notificationType]"
    (wbdismiss)="onDismiss(notification)"
    (mouseenter)="interruptDisappearTimeout(notification)"
    (mouseleave)="restartDisappearTimeout(notification)">

    {{ notification?.message | sssTranslate | async }}

    <div slot="footer" *ngIf="notification?.actionLabel && notification?.action">
      <wb-button
        (click)="notification?.action()"
        variant="tertiary"
      >
        {{ notification?.actionLabel | sssTranslate | async }}
      </wb-button>
    </div>

  </wb-notification>
</wb-notification-host>
