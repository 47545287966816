import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Seller } from '../models/seller';
import { Profile } from '../models/profile';
import { CompanyInformationContainer } from '../models/company-information-container';
import { SellerProfileInformation } from '../models/seller-profile-information';
import { SellerStore } from '../models/seller-store';
import { SellerMasterData } from '../models/seller-master-data';


/**
 * Seller Profile protected endpoints, which authenticate via validation service
 */

@Injectable()
export class ProtectedService {
  private domain: string;

  constructor(
    private httpClient: HttpClient,
  ) {}

  public setDomain(domain: string): void {
    this.domain = domain;
  }


  /**
   * @param params The `ProtectedService.GetProtectedSellerByGcAndGsIdParams` containing the following parameters:
   *
   * - `gsId`: Valid seller GS id from a seller; in the case of a dealer the GSSN GS id (outletId)
   *
   * - `gcId`: Valid seller GC id from a seller; in the case of a dealer the GSSN GC id (companyId)
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `Authorization`: Authorization
   *
   * - `ApplicationName`: Valid client application identifier
   *
   * - `TrackingId`: Optional id for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * - `CorrelationId`: Optional id for correlating multiple requests for a business case
   *
   * @return OK
   */

  public getProtectedSellerByGcAndGsId(params: GetProtectedSellerByGcAndGsIdParams): Observable<Seller> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;



    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());

    const request = new HttpRequest('GET', this.domain + `/protected/${params.country}/sellers/${params.gcId}/outlets/${params.gsId}`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<Seller>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.GetProtectedSellerProfileByGcIdParams` containing the following parameters:
   *
   * - `gcId`: Valid seller GC id from a seller; in the case of a dealer the GSSN GC id (companyId)
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `Authorization`: Authorization
   *
   * - `ApplicationName`: Valid client application identifier
   *
   * - `TrackingId`: Optional id for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * - `CorrelationId`: Optional id for correlating multiple requests for a business case
   *
   * @return OK
   */

  public getProtectedSellerProfileByGcId(params: GetProtectedSellerProfileByGcIdParams): Observable<Profile> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;


    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());

    const request = new HttpRequest('GET', this.domain + `/protected/${params.country}/sellers/profile/${params.gcId}`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<Profile>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.PutSellerProfileParams` containing the following parameters:
   *
   * - `registrationStatus`:
   *
   * - `gcId`: Valid seller GC id from a seller; in the case of a dealer the GSSN GC id (companyId)
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `Authorization`: Authorization
   *
   * - `ApplicationName`: Valid client application identifier
   *
   * - `TrackingId`: Optional id for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * - `CorrelationId`: Optional id for correlating multiple requests for a business case
   */

  public putSellerProfile(params: PutSellerProfileParams): Observable<null> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;
    requestBody = params.registrationStatus;


    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());

    const request = new HttpRequest('PUT', this.domain + `/protected/${params.country}/sellers/profile/${params.gcId}`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<null>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.GetProtectedSellerProfilesParams` containing the following parameters:
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `companyIds`: List of companyIds
   *
   * - `Authorization`: Authorization
   *
   * - `ApplicationName`: Valid client application identifier
   *
   * - `TrackingId`: Optional id for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * - `CorrelationId`: Optional id for correlating multiple requests for a business case
   *
   * @return OK
   */

  public getProtectedSellerProfiles(params: GetProtectedSellerProfilesParams): Observable<CompanyInformationContainer> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;

    (params.companyIds || []).forEach((val, index) => {if (val != null) requestParams = requestParams.append('companyIds', val.toString())});
    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());

    const request = new HttpRequest('GET', this.domain + `/protected/${params.country}/sellers/profile/`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<CompanyInformationContainer>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.GetProtectedSellerProfileInformationParams` containing the following parameters:
   *
   * - `gcId`: Valid seller GC id from a seller; in the case of a dealer the GSSN GC id (companyId)
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `Authorization`: Authorization
   *
   * - `ApplicationName`: Valid client application identifier
   *
   * - `TrackingId`: Optional id for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * - `CorrelationId`: Optional id for correlating multiple requests for a business case
   *
   * @return OK
   */

  public getProtectedSellerProfileInformation(params: GetProtectedSellerProfileInformationParams): Observable<SellerProfileInformation> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;


    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());

    const request = new HttpRequest('GET', this.domain + `/protected/${params.country}/sellers/profile/${params.gcId}/information`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<SellerProfileInformation>) => response.body)
      );
  }


  /**
   * Returns store entry of the master data of the requested outletId (GS-ID).
   * @param params The `ProtectedService.GetProtectedMasterDataStoresByGsIdParams` containing the following parameters:
   *
   * - `gsId`: Valid seller GS id from a seller; in the case of a dealer the GSSN GS id (outletId)
   *
   * - `gcId`: Valid seller GC id from a seller; in the case of a dealer the GSSN GC id (companyId)
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `Authorization`: Authorization
   *
   * - `ApplicationName`: Valid client application identifier
   *
   * - `TrackingId`: Optional id for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * - `CorrelationId`: Optional id for correlating multiple requests for a business case
   *
   * @return OK
   */

  public getProtectedMasterDataStoresByGsId(params: GetProtectedMasterDataStoresByGsIdParams): Observable<SellerStore> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;



    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());

    const request = new HttpRequest('GET', this.domain + `/protected/${params.country}/sellers/${params.gcId}/masterdata/${params.gsId}/stores`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<SellerStore>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.CreateProtectedMasterDataStoreByGsIdParams` containing the following parameters:
   *
   * - `store`:
   *
   * - `gsId`: Valid seller GS id from a seller; in the case of a dealer the GSSN GS id (outletId)
   *
   * - `gcId`: Valid seller GC id from a seller; in the case of a dealer the GSSN GC id (companyId)
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `Authorization`: Authorization
   *
   * - `ApplicationName`: Valid client application identifier
   *
   * - `TrackingId`: Optional id for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * - `CorrelationId`: Optional id for correlating multiple requests for a business case
   */

  public createProtectedMasterDataStoreByGsId(params: CreateProtectedMasterDataStoreByGsIdParams): Observable<null> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;
    requestBody = params.store;



    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());

    const request = new HttpRequest('POST', this.domain + `/protected/${params.country}/sellers/${params.gcId}/masterdata/${params.gsId}/stores`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<null>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.UpdateProtectedMasterDataStoreByGsIdParams` containing the following parameters:
   *
   * - `store`:
   *
   * - `gsId`: Valid seller GS id from a seller; in the case of a dealer the GSSN GS id (outletId)
   *
   * - `gcId`: Valid seller GC id from a seller; in the case of a dealer the GSSN GC id (companyId)
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `Authorization`: Authorization
   *
   * - `ApplicationName`: Valid client application identifier
   *
   * - `TrackingId`: Optional id for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * - `CorrelationId`: Optional id for correlating multiple requests for a business case
   *
   * @return OK
   */

  public updateProtectedMasterDataStoreByGsId(params: UpdateProtectedMasterDataStoreByGsIdParams): Observable<SellerStore> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;
    requestBody = params.store;



    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());

    const request = new HttpRequest('PATCH', this.domain + `/protected/${params.country}/sellers/${params.gcId}/masterdata/${params.gsId}/stores`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<SellerStore>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.GetProtectedMasterDataByGcIdParams` containing the following parameters:
   *
   * - `gcId`: Valid seller GC id from a seller; in the case of a dealer the GSSN GC id (companyId)
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `Authorization`: Authorization
   *
   * - `ApplicationName`: Valid client application identifier
   *
   * - `shops`: A list of valid online shop names as defined in GSSN. If omitted all shops will be considered.
   *
   * - `level`: Filter the result while returning only objects relevant for details or overview. By default  all objects are returned. OVERVIEW returns only store objets, DETAILS returns all objects.
   *
   * - `entities`: Filter the result while returning only objects, which are requested in the scope. By default  all objects are returned. If provided, only objects matching the scope are returned. COMPANY in the scope provides company data, OUTLETS will provide outlet data while STORES will provide the store information.
   *
   * - `TrackingId`: Optional id for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * - `CorrelationId`: Optional id for correlating multiple requests for a business case
   *
   * @return OK
   */

  public getProtectedMasterDataByGcId(params: GetProtectedMasterDataByGcIdParams): Observable<SellerMasterData> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;


    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    (params.shops || []).forEach((val, index) => {if (val != null) requestParams = requestParams.append('shops', val.toString())});
    if (params.level != null) requestParams = requestParams.set('level', params.level.toString());
    (params.entities || []).forEach((val, index) => {if (val != null) requestParams = requestParams.append('entities', val.toString())});
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());

    const request = new HttpRequest('GET', this.domain + `/protected/${params.country}/sellers/${params.gcId}/masterdata`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<SellerMasterData>) => response.body)
      );
  }
}

export interface GetProtectedSellerByGcAndGsIdParams {
  gsId: string;
  gcId: string;
  country: string;
  Authorization: string;
  ApplicationName: string;
  TrackingId?: string;
  Timestamp?: string;
  CorrelationId?: string;
}

export interface GetProtectedSellerProfileByGcIdParams {
  gcId: string;
  country: string;
  Authorization: string;
  ApplicationName: string;
  TrackingId?: string;
  Timestamp?: string;
  CorrelationId?: string;
}

export interface PutSellerProfileParams {
  registrationStatus: any;
  gcId: string;
  country: string;
  Authorization: string;
  ApplicationName: string;
  TrackingId?: string;
  Timestamp?: string;
  CorrelationId?: string;
}

export interface GetProtectedSellerProfilesParams {
  country: string;
  companyIds: Array<string>;
  Authorization: string;
  ApplicationName: string;
  TrackingId?: string;
  Timestamp?: string;
  CorrelationId?: string;
}

export interface GetProtectedSellerProfileInformationParams {
  gcId: string;
  country: string;
  Authorization: string;
  ApplicationName: string;
  TrackingId?: string;
  Timestamp?: string;
  CorrelationId?: string;
}

export interface GetProtectedMasterDataStoresByGsIdParams {
  gsId: string;
  gcId: string;
  country: string;
  Authorization: string;
  ApplicationName: string;
  TrackingId?: string;
  Timestamp?: string;
  CorrelationId?: string;
}

export interface CreateProtectedMasterDataStoreByGsIdParams {
  store: SellerStore;
  gsId: string;
  gcId: string;
  country: string;
  Authorization: string;
  ApplicationName: string;
  TrackingId?: string;
  Timestamp?: string;
  CorrelationId?: string;
}

export interface UpdateProtectedMasterDataStoreByGsIdParams {
  store: SellerStore;
  gsId: string;
  gcId: string;
  country: string;
  Authorization: string;
  ApplicationName: string;
  TrackingId?: string;
  Timestamp?: string;
  CorrelationId?: string;
}

export interface GetProtectedMasterDataByGcIdParams {
  gcId: string;
  country: string;
  Authorization: string;
  ApplicationName: string;
  shops?: Array<'ONLINESALES-ACCESSOIRES' | 'ONLINESALES-NEWCARS' | 'ONLINESALES-SERVICECONTRACTS' | 'ONLINESALES-WORKSHOPSERVICES' | 'ONLINESALES-USEDCARS' | 'ONLINESALES-DIGITAL-MARKETPLACE'>;
  level?: 'OVERVIEW' | 'DETAILS';
  entities?: Array<'COMPANY' | 'OUTLETS' | 'STORES'>;
  TrackingId?: string;
  Timestamp?: string;
  CorrelationId?: string;
}

