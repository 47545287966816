import { CountryShopMapping, SellerCenterMarket, SellerCenterShopName } from '@core/config/global.config';
import { DocumentCustomerType } from '@generated/sssd/models/document-customer-type';
import { DocumentType } from '@generated/sssd/models/document-type';
import { DocumentOverview, DocumentTableRow } from './document.typings';

export const DOCUMENT_OVERVIEW_CONFIG: CountryShopMapping<DocumentOverview> = {
  [ SellerCenterMarket.DE ]: {
    [ SellerCenterShopName.GPME ]: {
      [ DocumentCustomerType.PRIVATE ]: [
        new DocumentTableRow(DocumentType.TERMS_AND_CONDITIONS, false),
        new DocumentTableRow(DocumentType.CANCEL_NOTICE),
        new DocumentTableRow(DocumentType.CONSUMER_INFORMATION, false),
        new DocumentTableRow(DocumentType.DATA_PROTECTION, false),
      ],
    },
    [ SellerCenterShopName.ACCESSORIES ]: {
      [ DocumentCustomerType.PRIVATE ]: [
        new DocumentTableRow(DocumentType.CANCEL_NOTICE),
        new DocumentTableRow(DocumentType.ACCESSORIES_TERMS_AND_CONDITIONS, false),
        new DocumentTableRow(DocumentType.DATA_PROTECTION, false),
        new DocumentTableRow(DocumentType.CONSUMER_INFORMATION, false),
        new DocumentTableRow(DocumentType.SELLER_SALES_TAX_FORM),
      ],
    },
  },
  [ SellerCenterMarket.GB ]: {
    [ SellerCenterShopName.ACCESSORIES ]: {
      [ DocumentCustomerType.PRIVATE ]: [
        new DocumentTableRow(DocumentType.TERMS_AND_CONDITIONS),
        new DocumentTableRow(DocumentType.CANCEL_NOTICE),
      ],
    },
  },
  [ SellerCenterMarket.FR ]: {
    [ SellerCenterShopName.ACCESSORIES ]: {
      [ DocumentCustomerType.PRIVATE ]: [
        new DocumentTableRow(DocumentType.TERMS_AND_CONDITIONS),
        new DocumentTableRow(DocumentType.CANCEL_NOTICE),
      ],
    },
  },
  [ SellerCenterMarket.LU ]: {
    [ SellerCenterShopName.NCOS ]: {
      [ DocumentCustomerType.PRIVATE ]: [],
    },
    [ SellerCenterShopName.UCOS ]: {
      [ DocumentCustomerType.PRIVATE ]: [],
    },
    [ SellerCenterShopName.ACCESSORIES ]: {
      [ DocumentCustomerType.PRIVATE ]: [],
    },
  },
};

export enum DocumentTableOption {
  UPLOAD = 'UPLOAD',
  HISTORY = 'HISTORY',
}

interface DocumentTableConfigOption {
  documentRoute?: Array<string>;
}

type DocumentTableConfigMap = Record<DocumentTableOption, DocumentTableConfigOption>;

export enum DocumentsRoute {
  REPOSITORY = '/documents/repository',
  OUTLET_UPLOAD = '/documents/outlet-upload',
}

export const documentTableItemConfig: DocumentTableConfigMap = {
  [DocumentTableOption.UPLOAD] : {
    documentRoute: [ DocumentsRoute.OUTLET_UPLOAD ],
  },
  [DocumentTableOption.HISTORY] : {
    documentRoute: [ DocumentsRoute.REPOSITORY, DocumentsRoute.OUTLET_UPLOAD ],
  },
};
