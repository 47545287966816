import { Injectable } from '@angular/core';
import { CommerceDomain, COMMERCE_DOMAIN_PROP, DOMAIN_SERVICES_MAP, SellerCenterDomain, SellerCenterService } from '@core/config/global.config';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { CurrentStateService } from '@core/store/current-state.service';
import { Company, Outlet } from '@core/types/types';

@Injectable()
export class DropdownService {
  constructor(
    private readonly currentStateService: CurrentStateService,
  ) {}

  public hasDomain(entity: Outlet | Company, sellerCenterDomain: SellerCenterDomain, commerceDomain: CommerceDomain): boolean {
    const domainServices = DOMAIN_SERVICES_MAP[sellerCenterDomain] || [];
    const entityServices = this.findServicesByCommerceDomain(entity, commerceDomain);
    const country = entity.countryCode;

    switch (sellerCenterDomain) {
      case SellerCenterDomain.PROFILE:
        return this.hasAvailableProductTypes(country);

      case SellerCenterDomain.SETUP_PRODUCT_PARTICIPATION:
        return this.hasAvailableProductTypes(country);

      default:
        return entityServices.some(i => domainServices.includes(i));
    }
  }

  public hasMarketplaceServices(entity: Company, commerceDomain: CommerceDomain): boolean {
    return Boolean(this.findServicesByCommerceDomain(entity, commerceDomain).length);
  }

  private findServicesByCommerceDomain(entity: Outlet | Company, commerceDomain: CommerceDomain): SellerCenterService[] {
    const commerceDomainProp = COMMERCE_DOMAIN_PROP[commerceDomain];

    const allServices = {
      ...entity.services,
      ...entity.externalServices,
    };

    const services = Object.entries(allServices)
      .filter(([ , o ]) => o[commerceDomainProp])
      .map(([ service ]) => service);

    return services as SellerCenterService[];
  }

  private hasAvailableProductTypes(country: string): boolean {
    const profile = this.currentStateService.get(AuthSelectors.profile);

    return Boolean(profile.countries[country].availableProductTypes);
  }
}
