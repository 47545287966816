import { Action } from '@ngrx/store';
import { LanguageCode } from '@core/config/global.config';

export enum TranslationActionTypes {
  RESET_STATE = '[Translation] RESET_STATE',
  SET_TRANSLATIONS = '[Translation] SET_TRANSLATIONS',
  SET_LANGUAGE = '[Translation] SET_LANGUAGE',
}

export class ResetTranslationStateAction implements Action {
  public readonly type = TranslationActionTypes.RESET_STATE;
}

export class SetTranslationsAction implements Action {
  public readonly type = TranslationActionTypes.SET_TRANSLATIONS;

  constructor(
    public readonly translations: Record<string, string>,
  ) {}
}

export class SetLanguageAction implements Action {
  public readonly type = TranslationActionTypes.SET_LANGUAGE;

  constructor(
    public readonly language: LanguageCode,
  ) {}
}
