import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BadgeComponent } from './badge.component';

@NgModule({
  declarations: [
    BadgeComponent,
  ],
  exports: [
    BadgeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],

})
export class BadgeModule {}
