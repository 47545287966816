import { Action } from '@ngrx/store';
import { ActionMap, reduce } from '../root.state';
import { SetLanguageAction, SetTranslationsAction, TranslationActionTypes } from './translation.actions';
import { TranslationState, TRANSLATION_INITIAL_STATE } from './translation.state';

export function translationReducer(state: TranslationState = TRANSLATION_INITIAL_STATE, action: Action): TranslationState {
  return reduce(REDUCER_ACTION_MAP, action, state);
}

function resetTranslationState(): TranslationState {
  return {
    ...TRANSLATION_INITIAL_STATE,
  };
}

function setTranslations(state: TranslationState, action: SetTranslationsAction): TranslationState {
  return {
    ...state,
    translations: action.translations,
  };
}

function setLanguage(state: TranslationState, action: SetLanguageAction): TranslationState {
  return {
    ...state,
    locale: action.language,
  };
}

const REDUCER_ACTION_MAP: ActionMap = {
  [ TranslationActionTypes.RESET_STATE ]: resetTranslationState,
  [ TranslationActionTypes.SET_TRANSLATIONS ]: setTranslations,
  [ TranslationActionTypes.SET_LANGUAGE ]: setLanguage,
};
