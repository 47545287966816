import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef,
  OnChanges,
} from '@angular/core';
import { isBool, Value } from '@trademe/ensure';
import { DropdownOption } from './dropdown-option.class';
import { Components } from '@workbench/core';

@Component({
  selector: 'sss-dropdown',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './dropdown.component.html',
  styleUrls: [ './dropdown.component.scss' ],
})
export class DropdownComponent implements OnChanges {
  @Input()
  public defaultText = '';

  @Input()
  public label = '';

  @Input()
  public options: DropdownOption[] = [];

  @Input()
  @Value(isBool)
  public darkMode = false;

  @Input()
  @Value(isBool)
  public disabled = false;

  @Input()
  public selection: string = null;

  @Input()
  @Value(isBool)
  public compact = false;

  @ViewChild('select', { static: true }) public select: ElementRef<Components.WbSelect>;

  @Output()
  public selectionChange: EventEmitter<string | null> = new EventEmitter();

  public selectOption(value: string): void {
    this.selectionChange.emit(value === 'null' ? null : value);
  }

  public ngOnChanges(): void {
    setTimeout(() => {
      // Avoid workbench error
      this.select.nativeElement.label = this.label;
    }, 10);
  }
}
