import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '../icon/icon.module';
import { HintComponent } from './hint.component';

@NgModule({
  declarations: [
    HintComponent,
  ],
  exports: [
    HintComponent,
  ],
  imports: [
    CommonModule,
    IconModule,
  ],
})
export class HintModule { }
