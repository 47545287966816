import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DropdownModule } from '@shared/components/dropdown/dropdown.module';
import { DropdownCompanyComponent } from './dropdown-company.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';

@NgModule({
  declarations: [
    DropdownCompanyComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
    DropdownModule,
  ],
  exports: [
    DropdownCompanyComponent,
  ],
})
export class DropdownCompanyModule { }
