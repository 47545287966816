import { SHOPS_WITH_OUTLETS } from '@core/config/global.config';
import { ActionMap, reduce } from '@core/store/root.state';
import { Action } from '@ngrx/store';
import * as ShopOutletSelectorActions from './shop-outlet-selector.actions';
import { ShopOutletSelectorState, SHOP_OUTLET_SELECTOR_INITIAL_STATE } from './shop-outlet-selector.state';

const REDUCER_ACTION_MAP: ActionMap = {
  [ ShopOutletSelectorActions.SHOW ]: show,
  [ ShopOutletSelectorActions.RESET ]: reset,
  [ ShopOutletSelectorActions.SELECT_SHOP ]: selectShop,
  [ ShopOutletSelectorActions.SELECT_OUTLET ]: selectOutlet,
};

export function shopOutletSelectorReducer(
  state: ShopOutletSelectorState = SHOP_OUTLET_SELECTOR_INITIAL_STATE,
  action: Action,
): ShopOutletSelectorState {
  return reduce(REDUCER_ACTION_MAP, action, state);
}

function show(
  state: ShopOutletSelectorState,
  action: ShopOutletSelectorActions.ShowAction,
): ShopOutletSelectorState {
  return {
    ...state,
    isVisible: true,
    availableShopsFilter: action.availableShopsFilter,
    selectedShop: (action.availableShopsFilter || []).includes(state.selectedShop) ? state.selectedShop : null,
  };
}

function reset(): ShopOutletSelectorState {
  return { ...SHOP_OUTLET_SELECTOR_INITIAL_STATE };
}

function selectShop(state: ShopOutletSelectorState, action: ShopOutletSelectorActions.SelectShopAction): ShopOutletSelectorState {
  return {
    ...state,
    selectedShop: action.shop,
    selectedShopHasOutlets: SHOPS_WITH_OUTLETS.includes(action.shop),
    selectedOutlet: null,
  };
}

function selectOutlet(state: ShopOutletSelectorState, action: ShopOutletSelectorActions.SelectOutletAction): ShopOutletSelectorState {
  return {
    ...state,
    selectedOutlet: action.outlet,
  };
}
