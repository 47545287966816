import { Injectable } from '@angular/core';
import { Environment } from './environment.model';

@Injectable()
export class WindowRefService {
  public readonly nativeWindow: Window;
  public readonly nativeDocument: Document;
  public readonly environment: Environment;
  public readonly decodeURIComponent: (uri: string) => string;
  public readonly encodeURIComponent: (uriComponent: string | number | boolean) => string;

  constructor() {
    this.nativeWindow = window;
    this.nativeDocument = document;
    this.environment = sss;
    this.decodeURIComponent = decodeURIComponent;
    this.encodeURIComponent = encodeURIComponent;
  }
}
