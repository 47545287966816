import { HttpErrorResponse } from '@angular/common/http';
import { Document as PdfDocument } from '@generated/sssd/models/document';
import { DocumentCountry } from '@generated/sssd/models/document-country';
import { DocumentScope } from '@generated/sssd/models/document-scope';
import { DocumentStatus } from '@generated/sssd/models/document-status';
import { Action } from '@ngrx/store';
import { DocumentOverview } from '@app/backoffice/documents/backoffice-documents.typings';
import { CompanyId, OutletId } from '@core/types/profile.types';
import { FileForm } from '../document-marketplace/document-marketplace.typings';

export const FETCH_DOCUMENTS_REQUEST = '[Document] FETCH_DOCUMENTS_REQUEST';
export const FETCH_DOCUMENTS_SUCCESS = '[Document] FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAILURE = '[Document] FETCH_DOCUMENTS_FAILURE';
export const DOCUMENT_UPLOADED = '[Document] DOCUMENT_UPLOADED';
export const CLEAR_OVERVIEW = '[Document] CLEAR_OVERVIEW';
export const SAVE_FILE_FORM = '[Document] SAVE_FILE_FORM';
export const RESET_FILE_FORM = '[Document] RESET_FILE_FORM';

export class FetchDocumentsRequestAction implements Action {
  readonly type = FETCH_DOCUMENTS_REQUEST;

  constructor(
    public readonly companyId: CompanyId,
    public readonly outletId: OutletId,
    public readonly documentStatus: DocumentStatus,
    public readonly documentCountry: DocumentCountry,
    public readonly documentLanguages: string[],
    public readonly documentScope: DocumentScope,
  ) {}
}

export class FetchDocumentsSuccessAction implements Action {
  readonly type = FETCH_DOCUMENTS_SUCCESS;

  constructor(
    public readonly overview: DocumentOverview,
  ) {}
}

export class FetchDocumentsFailureAction implements Action {
  readonly type = FETCH_DOCUMENTS_FAILURE;

  constructor(
    public readonly error: HttpErrorResponse,
  ) {}
}

export class DocumentUploadedAction implements Action {
  readonly type = DOCUMENT_UPLOADED;

  constructor(
    public readonly document: PdfDocument,
  ) {}
}

export class ClearDocumentOverviewAction implements Action {
  readonly type = CLEAR_OVERVIEW;
}

export class SaveFileForm implements Action {
  readonly type = SAVE_FILE_FORM;

  constructor(
    public readonly fileForm: FileForm,
  ) {}
}

export class ResetFileForm implements Action {
  readonly type = RESET_FILE_FORM;
}
