import { Injectable } from '@angular/core';
import { SortOrder } from './sort-order.enum';
import { Sorting } from './sorting.class';

type SortByFn<T> = (a: T, b: T) => number;

@Injectable()
export class SortService {
  public sortStringAlphabeticallyIgnoreCase(a: string, b: string): number {
    return a.trim().toUpperCase() < b.trim().toUpperCase() ? -1 : 1;
  }

  public getNewSortingWithToggle<T>(previousSorting: Sorting<T>, newSortingType: T): Sorting<T> {
    const newSortOrder = (newSortingType === previousSorting.type && previousSorting.order === SortOrder.DESC)
      ? SortOrder.ASC
      : SortOrder.DESC;

    return new Sorting(newSortingType, newSortOrder);
  }


  public sortByFunction<T>(items: Array<T>, sortOrder: SortOrder, sortFn: SortByFn<T>): Array<T> {
    return [ ...items ]
      .sort((a, b) => (sortOrder === SortOrder.ASC) ? sortFn(a, b) : sortFn(b, a));
  }

  public sortByValueAccessorFn<T>(items: Array<T>, sortOrder: SortOrder, valueAccessorFn: (v: T) => string): Array<T> {
    return [ ...items ]
      .sort((a, b) => {
        if (sortOrder === SortOrder.ASC) {
          return valueAccessorFn(a) < valueAccessorFn(b) ? -1 : 1;
        } else {
          return valueAccessorFn(b) < valueAccessorFn(a) ? -1 : 1;
        }
      });
  }
}
