import { Pipe, PipeTransform } from '@angular/core';
import { DecimalSeparators } from '@core/config/global.config';
import { CurrentStateService } from '@core/store/current-state.service';
import * as AuthSelectors from '@core/store/auth/auth.selectors';

const DEFAULT_DECIMAL_DIGITS = 1;

@Pipe({
  name: 'sssNumber',
})
export class NumberPipe implements PipeTransform {
  private readonly currentStateService: CurrentStateService;

  constructor(currentStateService: CurrentStateService) {
    this.currentStateService = currentStateService;
  }

  public transform(value: number | string, decimalDigits: number = DEFAULT_DECIMAL_DIGITS, customDecimalSeparator?: string): string {
    const country = this.currentStateService.get(AuthSelectors.selectedCompany)?.countryCode;
    const decimalSeparator = customDecimalSeparator || DecimalSeparators[country] || DecimalSeparators.Default;

    if (value === undefined || value === null || value.toString() === '') {
      return '';
    }

    // for inputs
    const incompleteValue = value.toString().match(/\d+\.$/);

    if (incompleteValue) {
      return value.toString().replace('.', decimalSeparator);
    }

    // for when user want to add negative numbers
    const isItMinus = value === '-';

    if (isItMinus) {
      return value;
    }

    const numericValue = Number(value);
    return (numericValue || numericValue === 0) && !isNaN(numericValue) ? numericValue.toFixed(decimalDigits).replace('.', decimalSeparator) : '';
  }
}
