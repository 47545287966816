import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoute } from '@app/app-routes.enum';
import { ProfileService } from '@app/profile/profile.service';
import { CommerceDomain } from '@core/config/global.config';
import { I18nService } from '@core/i18n/i18n.service';
import { IdentityService } from '@core/services/identity/identity.service';
import { Identity } from '@core/services/identity/identity.typings';
import { ResetAuthStateAction, SetIdentityAction, SetTokenAction } from '@core/store/auth/auth.actions';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { CurrentStateService } from '@core/store/current-state.service';
import { RootState } from '@core/store/root.state';
import * as SessionActions from '@core/store/session/session.actions';
import { Store } from '@ngrx/store';
import { ToasterService } from '@shared/components/toaster/toaster.service';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';
import { forkJoin, timer } from 'rxjs';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SessionService {
  private LOGIN_FAILED_I18N_KEY = 'common.login.failure';
  private SESSION_EXPIRED_I18N_KEY = 'common.login.sessionExpired';

  constructor(
    private readonly windowRefService: WindowRefService,
    private readonly identityService: IdentityService,
    private readonly router: Router,
    private readonly currentStateService: CurrentStateService,
    private readonly store: Store<RootState>,
    private readonly i18nService: I18nService,
    private readonly toasterService: ToasterService,
    private readonly profileService: ProfileService,
    private httpClient: HttpClient,
  ) {}

  public start(oneTimeToken: string, redirect: string): void {
    const headers = { Ott: oneTimeToken };

    this.httpClient
      .post(this.windowRefService.environment.api.open.token, {}, { headers: headers })
      .subscribe( (response: { token: string }) => {
        this.router.navigate([], { replaceUrl: true });
        this.store.dispatch(new SetTokenAction(`Bearer ${ response.token }`));
        this.getUserData(redirect);
      },
      () => this.logout(this.LOGIN_FAILED_I18N_KEY),
      );
  }

  public getUserData(redirect: string): void {
    const user$ = forkJoin([
      this.identityService.getIdentity().pipe(first()),
      this.profileService.fetchProfile(),
    ]);

    user$.subscribe(
      ([ identity  ]) => {
        this.store.dispatch(new SetIdentityAction(identity));

        this.startSessionTimeout(identity);
        if (redirect) {
          this.resolveRedirect(redirect);
        }
      },
      () => this.logout(this.LOGIN_FAILED_I18N_KEY),
    );
  }

  public isLogged(): boolean {
    return this.currentStateService.get<boolean>(AuthSelectors.hasSession);
  }

  public logout(messagekey?: string): void {
    const width = 700;
    const height = 500;
    const left = (screen.width/2) - (width/2);
    const top = (screen.height/2) - (height/2);

    const winAttr = `width=${width},height=${height},left=${left},top=${top}`;
    const logoutURL = this.windowRefService.environment.api.redirects.logout;
    const logoutwindow = this.windowRefService.nativeWindow.open(logoutURL, '_blank', winAttr);

    let message: string = null;

    if (messagekey) {
      message = this.i18nService.getInstantTranslation(messagekey);
      this.toasterService.showInformation(message);
    }

    if (logoutwindow) {
      logoutwindow.opener = null;

      setTimeout(() => {
        logoutwindow.focus();
        logoutwindow.close();
      }, 3000);
    }

    this.store.dispatch(new ResetAuthStateAction());
    this.store.dispatch(new SessionActions.ResetSessionStateAction());

    this.router.navigate([ AppRoute.DASHBOARD ], { replaceUrl: true });
  }

  public validateExpiration(): void {
    const ONE_MINUTE = 60 * 1000;

    const identity = this.currentStateService.get(AuthSelectors.identity);
    const remainingtime = identity ? identity.exp * 1000 - Date.now() : null;
    if (identity && remainingtime < ONE_MINUTE) {
      this.logout(this.SESSION_EXPIRED_I18N_KEY);
    }
  }

  private startSessionTimeout(identity: Identity): void {
    let expiration = identity.exp * 1000 - Date.now();

    if (expiration <= 0) {
      expiration = 0;
    }
    const timer$ = timer(expiration);

    timer$.subscribe(() => this.logout(this.SESSION_EXPIRED_I18N_KEY));
  }

  private resolveRedirect(url: string): void {
    const QUOTES_MARKETPLACE_REGEX = /quotes\/[A-Z]{2}\/\d+/g;
    // const QUOTES_SHOPS_REGEX = /quotes\/\d+/g;

    if (url.match(QUOTES_MARKETPLACE_REGEX)) {
      this.store.dispatch(new SessionActions.SetCommerceDomainAction(CommerceDomain.MARKETPLACE));
    }
    // TODO: Deprecating legacy SHOP
    // if (url.match(QUOTES_SHOPS_REGEX)) {
    //   this.store.dispatch(new SessionActions.SetCommerceDomainAction(CommerceDomain.SHOPS));
    // }

    this.router.navigate([ url ]);
  }
}
