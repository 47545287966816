import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { SellerCenterCurrencyIso, DEFAULT_CURRENCY_LOCALE } from '@core/config/global.config';
import { isValidNumber } from '../util/misc.util';

import en from '@angular/common/locales/en';
import enZA from '@angular/common/locales/en-ZA';
import enAU from '@angular/common/locales/en-AU';
import de from '@angular/common/locales/de';
import deCH from '@angular/common/locales/de-CH';
import ko from '@angular/common/locales/ko';
import ja from '@angular/common/locales/ja';

registerLocaleData(en);
registerLocaleData(enZA);
registerLocaleData(enAU);
registerLocaleData(de);
registerLocaleData(deCH);
registerLocaleData(ko);
registerLocaleData(ja);

@Pipe({
  name: 'sssCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  constructor(private readonly currencyPipe: CurrencyPipe) {}

  public transform(value: number, currencyIso: SellerCenterCurrencyIso): string {
    const locale = DEFAULT_CURRENCY_LOCALE[currencyIso];

    if (isValidNumber(value) === false) {
      return null;
    }

    if (!locale) {
      console.warn('Unknown locale for currency code', currencyIso); // eslint-disable-line
      return this.currencyPipe.transform(value).replace('$', '');
    }

    return this.currencyPipe.transform(value, currencyIso, 'symbol', undefined, locale);
  }
}
