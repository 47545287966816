export enum HttpStatusCode {
  PERMISSION_DENIED = 401,
  FORBIDDEN_ACCESS = 403,
  INTERNAL_SERVER_ERROR = 500
}

export enum ErrorKey {
  PERMISSION_DENIED = 'common.errors.permissionDenied',
  FORBIDDEN_ACCESS = 'common.errors.forbiddenAccess',
  GENERIC = 'common.errors.generic',
  FILE_SIZE_TOO_LARGE = 'common.errors.FILE_SIZE_TOO_LARGE',
  FILE_TYPE_UNSUPPORTED = 'common.errors.FILE_TYPE_UNSUPPORTED',
  UPLOAD_FAIL = 'common.errors.UPLOAD_FAIL',
  FILE_NAME_TOO_LONG = 'common.errors.FILE_NAME_TOO_LONG',
}

export const HttpErrorCodeKey =  {
  [ HttpStatusCode.PERMISSION_DENIED ]: ErrorKey.PERMISSION_DENIED,
  [ HttpStatusCode.FORBIDDEN_ACCESS ]: ErrorKey.FORBIDDEN_ACCESS,
  [ HttpStatusCode.INTERNAL_SERVER_ERROR ]: ErrorKey.GENERIC,
};
