import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { isBool, Value } from '@trademe/ensure';
import { Key } from 'ts-keycode-enum';
import { ANIMATIONS } from '../../animations/animations.config';
import { ScrollLockService } from '../../services/scroll-lock/scroll-lock.service';

const DEFAULT_MIN_HEIGHT = '412px';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'sss-modal',
  styleUrls: [ './modal.component.scss' ],
  templateUrl: './modal.component.html',
  animations: [ ANIMATIONS.FADE_IN__FADE_OUT ],
  host: { class: 'sss-modal' },
})
export class ModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('@fadeInFadeOut') fadeInFadeOut = true;

  @ViewChild('innerModal', { static: true }) public innerModal: ElementRef;
  @ViewChild('modalHeader') public header: ElementRef;

  @Input()
  @Value(isBool)
  @HostBinding('class.sss-modal--compact')
  public compact = false;

  @Input()
  @Value(isBool)
  @HostBinding('class.sss-modal--medium')
  public mediumSize = false;

  @Input()
  @Value(isBool)
  public showCloseButton = true;

  @Input()
  @Value(isBool)
  public closeOnBackdropClick = false;

  @Input()
  public headline?: string;

  @Input()
  public fixedHeight?: boolean;

  @Input()
  public minHeight?: string;

  @Output()
  public onClose: EventEmitter<null> = new EventEmitter();

  public showModalHeader = true;

  constructor(
    private renderer2: Renderer2,
    private scrollLockService: ScrollLockService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  @HostListener('click', [ '$event' ])
  public closeOnModalBackdropClick(event: Event): void {
    if (!this.closeOnBackdropClick) {
      return;
    }

    const clickTargetInModal = this.innerModal.nativeElement.contains(event.target);

    if (!clickTargetInModal) {
      this.closeModal(event);
    }
  }

  @HostListener('window:keyup', [ '$event' ])
  public onKeyUp(event: KeyboardEvent): void {
    if (event.keyCode === Key.Escape) {
      this.closeModal(event);
    }
  }

  public ngOnInit(): void {
    this.scrollLockService.lock(this.renderer2);

    if (this.minHeight === '') {
      this.minHeight = DEFAULT_MIN_HEIGHT;
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.showModalHeader = !!this.header && this.header.nativeElement.querySelector('modal-header');
      this.changeDetectorRef.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this.scrollLockService.unlock(this.renderer2);
  }

  public closeModal(event: Event = null): void {
    if (event) {
      event.preventDefault();
    }
    this.onClose.emit();
  }
}
