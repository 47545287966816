import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommerceDomainSelectorComponent } from './commerce-domain-selector.component';
import { CommerceDomainSelectorService } from './commerce-domain-selector.service';
import { PipesModule } from '@app/shared/pipes/pipes.module';

@NgModule({
  declarations: [ CommerceDomainSelectorComponent ],
  imports: [
    CommonModule,
    PipesModule,
  ],
  exports: [
    CommerceDomainSelectorComponent,
  ],
  providers: [ CommerceDomainSelectorService ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class CommerceDomainSelectorModule { }
