import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { APP_IMPORTS } from './app-imports';
import { APP_PROVIDERS } from './app-providers';
import { AppComponent } from './app.component';

@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [ AppComponent ],
  imports: [ APP_IMPORTS ],
  providers: [ APP_PROVIDERS ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class AppModule {}
