import { CommerceDomain } from '@core/config/global.config';
import { UserPermission, UserRole } from '@core/types/user-role';

export enum TopMenuItem {
  ENVIRONMENT_INFORMATION = 'environment_information',
  FEEDBACK = 'feedback',
  HELP = 'help',
  SHOP_MANAGEMENT = 'master_data',
  COMPANY_SELECTOR = 'company_selector',
  USER_NAME = 'user_name',
  LOGOUT = 'logout',
  LANGUAGE_SELECTOR = 'LANGUAGE_SELECTOR',
}

interface HeaderTopConfigItem {
  hideOnProd?: boolean;
  userRoles: Array<UserRole>;
  userPermissions: Array<UserPermission>;
  visibleJustWhenLoggedIn?: boolean;
  commerceDomain: CommerceDomain[];
}

interface HeaderTopConfigMap {
  [topMenuItem: string]: HeaderTopConfigItem;
}

export const headerTopConfig: HeaderTopConfigMap =  {
  [TopMenuItem.ENVIRONMENT_INFORMATION]: {
    hideOnProd: true,
    userRoles: [ UserRole.SELLER, UserRole.COUNTRY_MANAGER ],
    userPermissions: [ UserPermission.COMPANY_EDITOR, UserPermission.COUNTRY_EDITOR, UserPermission.OUTLET_EDITOR ],
    commerceDomain: [ CommerceDomain.SHOPS, CommerceDomain.MARKETPLACE ],
  },
  [TopMenuItem.FEEDBACK]: {
    visibleJustWhenLoggedIn: true,
    userRoles: [ UserRole.SELLER ],
    userPermissions: [ UserPermission.COMPANY_EDITOR, UserPermission.COUNTRY_EDITOR, UserPermission.OUTLET_EDITOR ],
    commerceDomain: [ CommerceDomain.SHOPS, CommerceDomain.MARKETPLACE ],
  },
  [TopMenuItem.HELP]: {
    visibleJustWhenLoggedIn: true,
    userRoles: [ UserRole.SELLER ],
    userPermissions: [ UserPermission.COMPANY_EDITOR, UserPermission.COUNTRY_EDITOR, UserPermission.OUTLET_EDITOR ],
    commerceDomain: [ CommerceDomain.SHOPS, CommerceDomain.MARKETPLACE ],
  },
  [TopMenuItem.SHOP_MANAGEMENT]: {
    visibleJustWhenLoggedIn: true,
    userRoles: [ UserRole.SELLER ],
    userPermissions: [ UserPermission.COMPANY_EDITOR, UserPermission.COUNTRY_EDITOR, UserPermission.OUTLET_EDITOR ],
    commerceDomain: [ CommerceDomain.SHOPS ],
  },
  [TopMenuItem.COMPANY_SELECTOR]: {
    visibleJustWhenLoggedIn: true,
    userRoles: [ UserRole.SELLER ],
    userPermissions: [ UserPermission.COMPANY_EDITOR, UserPermission.COUNTRY_EDITOR, UserPermission.OUTLET_EDITOR ],
    commerceDomain: [ CommerceDomain.SHOPS ],
  },
  [TopMenuItem.USER_NAME]: {
    visibleJustWhenLoggedIn: true,
    userRoles: [ UserRole.SELLER, UserRole.COUNTRY_MANAGER ],
    userPermissions: [ UserPermission.COMPANY_EDITOR, UserPermission.OUTLET_EDITOR ],
    commerceDomain: [ CommerceDomain.SHOPS, CommerceDomain.MARKETPLACE ],
  },
  [TopMenuItem.LOGOUT]: {
    visibleJustWhenLoggedIn: true,
    userRoles: [ UserRole.SELLER, UserRole.COUNTRY_MANAGER ],
    userPermissions: [ UserPermission.COMPANY_EDITOR, UserPermission.COUNTRY_EDITOR, UserPermission.OUTLET_EDITOR ],
    commerceDomain: [ CommerceDomain.SHOPS, CommerceDomain.MARKETPLACE ],
  },
  [TopMenuItem.LANGUAGE_SELECTOR]: {
    visibleJustWhenLoggedIn: false,
    userRoles: [ UserRole.SELLER, UserRole.COUNTRY_MANAGER ],
    userPermissions: [ UserPermission.COMPANY_EDITOR, UserPermission.COUNTRY_EDITOR, UserPermission.OUTLET_EDITOR ],
    commerceDomain: [ CommerceDomain.SHOPS, CommerceDomain.MARKETPLACE ],
  },
};
