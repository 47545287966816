import { Injectable, Renderer2 } from '@angular/core';
import { WindowRefService } from '../window-ref/window-ref.service';

// note: the below css class has to be publicly available
export const SCROLL_LOCK_CLASS = 'h-global-scroll-lock';

@Injectable()
export class ScrollLockService {
  private scrollTopBeforeLock: number;

  constructor(
    private windowRefService: WindowRefService,
  ) {}

  public lock(renderer2: Renderer2): void {
    this.scrollTopBeforeLock = this.windowRefService.nativeDocument.documentElement.scrollTop;
    renderer2.setStyle(this.windowRefService.nativeDocument.documentElement, 'top', `-${ this.scrollTopBeforeLock }px`);
    renderer2.addClass(this.windowRefService.nativeDocument.documentElement, SCROLL_LOCK_CLASS);
  }

  public unlock(renderer2: Renderer2): void {
    renderer2.removeClass(this.windowRefService.nativeDocument.documentElement, SCROLL_LOCK_CLASS);
    renderer2.removeStyle(this.windowRefService.nativeDocument.documentElement, 'top');
    this.windowRefService.nativeDocument.documentElement.scrollTop = this.scrollTopBeforeLock;
  }
}
