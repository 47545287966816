import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { DropdownModule } from '@shared/components/dropdown/dropdown.module';
import { ShopOutletSelectorService } from './shop-outlet-selector.service';
import { ShopOutletSelectorComponent } from './shop-outlet-selector.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';

@NgModule({
  declarations: [ ShopOutletSelectorComponent ],
  exports: [ ShopOutletSelectorComponent ],
  providers: [ ShopOutletSelectorService ],
  imports: [
    CommonModule,
    PipesModule,
    DropdownModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class ShopOutletSelectorModule {}
