import { State } from '../root.state';
import { LanguageCode } from '@core/config/global.config';

export interface TranslationState extends State {
  translations: Record<string, string>;
  locale: LanguageCode;
}

export const TRANSLATION_INITIAL_STATE: TranslationState = {
  translations: null,
  locale: LanguageCode.EN,
};
