import { Injectable } from '@angular/core';

import log from 'loglevel';
import prefix from 'loglevel-plugin-prefix';

import { WindowRefService } from '@shared/services/window-ref/window-ref.service';

export type Logger = log.Logger;

const CONSOLE_LOGO = `
   ____    ____         _____         __
  / __/__ / / /__ ____ / ___/__ ___  / /____ ____
 _\\ \\/ -_) / / -_) __// /__/ -_) _ \\/ __/ -_) __/
/___/\\__/_/_/\\__/_/ (_)___/\\__/_//_/\\__/\\__/_/

`;

// tslint:disable:no-console
@Injectable()
export class LoggerService {
  constructor(
    private windowRefService: WindowRefService,
  ) {}

  public initialize(): void {
    const level: log.LogLevelDesc = this.windowRefService.environment.logLevel || log.levels.DEBUG;
    log.setDefaultLevel(level);
    console.log(`${ CONSOLE_LOGO }> Logging '${ level }' and above\n`); // eslint-disable-line
  }

  public getLogger(name: string): log.Logger {
    const logger = log.getLogger(name);
    prefix.reg(log);

    // %t => time, %n => name, %l => level
    prefix.apply(logger, { template: '[%t] (%n) %l:' });

    return logger;
  }
}
