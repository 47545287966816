import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { skip, filter } from 'rxjs/operators';
import { SellerCenterShopName, SHOPS_WITH_OUTLETS } from '@core/config/global.config';
import { CurrentStateService } from '@core/store/current-state.service';
import { RootState } from '@core/store/root.state';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { DropdownOption } from '@shared/components/dropdown/dropdown-option.class';
import { ShopOutletSelectorService } from './shop-outlet-selector.service';
import { SelectOutletAction, SelectShopAction } from './store/shop-outlet-selector.actions';
import * as ShopOutletSelectorSelectors from './store/shop-outlet-selector.selectors';

@Component({
  selector: 'sss-shop-outlet-selector',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './shop-outlet-selector.component.html',
  styleUrls: [ './shop-outlet-selector.component.scss' ],
  host: { class: 'sss-shop-outlet-selector' },
})
export class ShopOutletSelectorComponent implements OnInit, OnDestroy {
  public selectedShop: SellerCenterShopName;
  public selectedOutlet: string;
  public shopOptions: DropdownOption[] = [];
  public outletOptions: DropdownOption[] = [];

  private changedCompany$: Subscription;

  constructor(
    private store: Store<RootState>,
    private changeDetectorRef: ChangeDetectorRef,
    private shopOutletSelectorService: ShopOutletSelectorService,
    private currentStateService: CurrentStateService,
  ) {}

  public ngOnInit(): void {
    this.makeShopOptions();

    this.changedCompany$ = this.store
      .select(AuthSelectors.selectedCompany)
      .pipe(skip(1), filter(Boolean))
      .subscribe(this.makeShopOptions.bind(this));
  }

  public ngOnDestroy(): void {
    if (this.changedCompany$) {
      this.changedCompany$.unsubscribe();
    }
  }

  public selectShop(shop: SellerCenterShopName): void {
    this.selectedShop = shop;
    this.selectedOutlet = null;
    this.outletOptions = [];

    this.store.dispatch(new SelectOutletAction(null));
    this.store.dispatch(new SelectShopAction(shop));

    if (this.doesShopHaveOutlets(shop)) {
      this.makeOutletOptions();
    } else {
      this.store.dispatch(new SelectOutletAction(this.currentStateService.get(AuthSelectors.legalEntity)));
    }

    this.changeDetectorRef.detectChanges();
  }

  public selectOutlet(outletId: string): void {
    const outlets = this.currentStateService.get(AuthSelectors.outlets);
    const outlet = outlets.find(i => i.outletId === outletId);

    this.selectedOutlet = outletId;

    this.store.dispatch(new SelectOutletAction(outlet));
  }

  private doesShopHaveOutlets(shop: SellerCenterShopName): boolean {
    return SHOPS_WITH_OUTLETS.includes(shop);
  }

  private makeShopOptions(): void {
    this.selectedOutlet = null;
    this.selectedShop = null;
    this.outletOptions = [];
    this.shopOptions = this.shopOutletSelectorService.getShopDropdownOptions();

    this.store.dispatch(new SelectShopAction(null));

    this.changeDetectorRef.detectChanges();
  }

  private makeOutletOptions(): void {
    const shopOutletSelectorState = this.currentStateService.get(ShopOutletSelectorSelectors.shopOutletSelectorState);

    const { selectedShop, selectedShopHasOutlets, isVisible } = shopOutletSelectorState;

    if (isVisible && selectedShop && selectedShopHasOutlets)  {
      this.outletOptions = this.shopOutletSelectorService.makeOutletDropdownOptions();
    }

    this.store.dispatch(new SelectOutletAction(null));

    this.changeDetectorRef.detectChanges();
  }
}
