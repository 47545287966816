import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSwitcherModalComponent } from './language-switcher-modal.component';
import { ModalModule } from '@shared/components/modal/modal.module';
import { ButtonModule } from '@shared/components/button/button.module';
import { ComboBoxModule } from '@shared/components/combo-box/combo-box.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    PipesModule,
    ButtonModule,
    ComboBoxModule,
  ],
  declarations: [
    LanguageSwitcherModalComponent,
  ],
  exports: [
    LanguageSwitcherModalComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class LanguageSwitcherModalModule { }
