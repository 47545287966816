import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CompanyOutletSelector2Component } from './company-outlet-selector-2.component';
import { DropdownCompanyModule } from '@core/dropdown-company/dropdown-company.module';
import { DropdownOutletModule } from '@core/dropdown-outlet/dropdown-outlet.module';
import { DropdownMarketModule } from '@core/dropdown-market/dropdown-market.module';
import { DropdownService } from '@app/shared/services/dropdown.service';

@NgModule({
  declarations: [
    CompanyOutletSelector2Component,
  ],
  imports: [
    CommonModule,
    DropdownOutletModule,
    DropdownCompanyModule,
    DropdownMarketModule,
  ],
  providers: [
    DropdownService,
  ],
  exports: [
    CompanyOutletSelector2Component,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class CompanyOutletSelector2Module { }
