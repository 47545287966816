import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Document } from '../models/document';
import { DocumentContainer } from '../models/document-container';


/**
 * Document protected endpoints, which authenticate via validation service
 */

@Injectable()
export class ProtectedDocumentService {
  private domain: string;

  constructor(
    private httpClient: HttpClient,
  ) {}

  public setDomain(domain: string): void {
    this.domain = domain;
  }


  /**
   * @param params The `ProtectedDocumentService.PostDocumentForCountryParams` containing the following parameters:
   *
   * - `sellerType`: A selector, if sellerId is outlet or company ID.
   *
   * - `outletId`: the gs - outletId.
   *
   * - `document`:
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `companyId`: the gc - companyId.
   *
   * - `Timestamp`: The timestamp in RFC 3339 format
   *
   * - `Authorization`: Authorization
   *
   * - `ApplicationName`: A valid client application identifier
   *
   * - `TrackingId`: An optional ID for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `CorrelationId`: An optional ID for correlating multiple requests for a business case
   *
   * @return Created
   */

  public postDocumentForCountry(params: PostDocumentForCountryParams): Observable<Document> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;
    if (params.sellerType != null) requestParams = requestParams.set('sellerType', params.sellerType.toString());

    requestBody = params.document;


    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());

    const request = new HttpRequest('POST', this.domain + `/protected/${params.country}/sellers/${params.companyId}/outlets/${params.outletId}/documents`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<Document>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedDocumentService.GetSellersDocumentsParams` containing the following parameters:
   *
   * - `outletId`: the gs - outletId.
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `companyId`: the gc - companyId.
   *
   * - `Timestamp`: The timestamp in RFC 3339 format
   *
   * - `Authorization`: Authorization
   *
   * - `ApplicationName`: A valid client application identifier
   *
   * - `pageSize`: Quantity of elements in one page
   *
   * - `page`: A page in case the client supports pagination. At the moment no client side control is provided for defining the item count per page.
   *
   * - `documentType`: Category of legal document.
   *
   * - `documentStatus`: Phase in lifecycle of the document.
   *
   * - `documentSortOrder`: Sorting type , by default is ASC
   *
   * - `documentSort`: A hint for sorting the results. If the sorting criteria is not supported a error will be returned with response code 422.
   *
   * - `documentScope`: A valid shop. It is not defined yet what behavior results in not sending the shop with the request. Anyhow there might be contexts outside of an ecommerce context where the shop is not needed.
   *
   * - `documentLanguage`: Filter for the specified language (ISO 639-1). Use the DocumentLanguage for validation in the backend, pattern doesn't matter.
   *
   * - `documentCountry`: Filter for the specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `customerType`: The intended audience of the document is defined by the customerType (PRIVATE, BUSINESS). The request for the customerType will only return documents, which were uploaded with this type. If omitted in the request, the service will return documents for all customers type.
   *
   * - `TrackingId`: An optional ID for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `CorrelationId`: An optional ID for correlating multiple requests for a business case
   *
   * @return OK
   */

  public getSellersDocuments(params: GetSellersDocumentsParams): Observable<DocumentContainer> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;



    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.pageSize != null) requestParams = requestParams.set('pageSize', params.pageSize.toString());
    if (params.page != null) requestParams = requestParams.set('page', params.page.toString());
    if (params.documentType != null) requestParams = requestParams.set('documentType', params.documentType.toString());
    if (params.documentStatus != null) requestParams = requestParams.set('documentStatus', params.documentStatus.toString());
    if (params.documentSortOrder != null) requestParams = requestParams.set('documentSortOrder', params.documentSortOrder.toString());
    if (params.documentSort != null) requestParams = requestParams.set('documentSort', params.documentSort.toString());
    if (params.documentScope != null) requestParams = requestParams.set('documentScope', params.documentScope.toString());
    if (params.documentLanguage != null) requestParams = requestParams.set('documentLanguage', params.documentLanguage.toString());
    if (params.documentCountry != null) requestParams = requestParams.set('documentCountry', params.documentCountry.toString());
    if (params.customerType != null) requestParams = requestParams.set('customerType', params.customerType.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());

    const request = new HttpRequest('GET', this.domain + `/protected/${params.country}/sellers/${params.companyId}/outlets/${params.outletId}/documents`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<DocumentContainer>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedDocumentService.GetSellersDocumentParams` containing the following parameters:
   *
   * - `uid`: A uuid from a document that already exists and is associated with the given seller.
   *
   * - `outletId`: the gs - outletId.
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `companyId`: the gc - companyId.
   *
   * - `Timestamp`: The timestamp in RFC 3339 format
   *
   * - `Authorization`: Authorization
   *
   * - `ApplicationName`: A valid client application identifier
   *
   * - `TrackingId`: An optional ID for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `CorrelationId`: An optional ID for correlating multiple requests for a business case
   *
   * @return OK
   */

  public getSellersDocument(params: GetSellersDocumentParams): Observable<Document> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;




    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());

    const request = new HttpRequest('GET', this.domain + `/protected/${params.country}/sellers/${params.companyId}/outlets/${params.outletId}/documents/${params.uid}`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<Document>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedDocumentService.DeleteSellersDocumentParams` containing the following parameters:
   *
   * - `uid`: A uuid from a document that already exists and is associated with the given seller.
   *
   * - `outletId`: the gs - outletId.
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `companyId`: the gc - companyId.
   *
   * - `Timestamp`: The timestamp in RFC 3339 format
   *
   * - `Authorization`: Authorization
   *
   * - `ApplicationName`: A valid client application identifier
   *
   * - `TrackingId`: An optional ID for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `CorrelationId`: An optional ID for correlating multiple requests for a business case
   */

  public deleteSellersDocument(params: DeleteSellersDocumentParams): Observable<null> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;




    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());

    const request = new HttpRequest('DELETE', this.domain + `/protected/${params.country}/sellers/${params.companyId}/outlets/${params.outletId}/documents/${params.uid}`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<null>) => response.body)
      );
  }
}

export interface PostDocumentForCountryParams {
  sellerType: 'GC' | 'GS';
  outletId: string;
  document: Document;
  country: string;
  companyId: string;
  Timestamp: string;
  Authorization: string;
  ApplicationName: string;
  TrackingId?: string;
  CorrelationId?: string;
}

export interface GetSellersDocumentsParams {
  outletId: string;
  country: string;
  companyId: string;
  Timestamp: string;
  Authorization: string;
  ApplicationName: string;
  pageSize?: number;
  page?: number;
  documentType?: 'TERMS_AND_CONDITIONS' | 'ACCESSORIES_TERMS_AND_CONDITIONS' | 'IMPRINT' | 'CANCEL_NOTICE' | 'PRIVACY_SECURITY' | 'JUNGE_STERNE_GUARANTEE' | 'SALES_TERMS' | 'USED_CAR_GUARANTEE' | 'DECLARATION_OF_CONSENT' | 'CONSUMER_INFORMATION' | 'REVOCABLE_FORM' | 'EXCHANGE_RIGHT' | 'USED_CAR_TERMS_AND_CONDITIONS' | 'NEW_CAR_GUARANTEE' | 'GUARANTEE_TERMS' | 'PRECONTRACTUAL_INFORMATION' | 'DISCLOSURE_NOTICE' | 'DEMONSTRATION_CAR_TERMS_AND_CONDITIONS' | 'PRIVACY_SECURITY_PAY-LEASING' | 'PRIVACY_SECURITY_PAY-FINANCING' | 'PRIVACY_SECURITY_MBF_PAY-LEASING' | 'PRIVACY_SECURITY_MBF_PAY-FINANCING' | 'RETURN_POLICY_PAY-LEASING' | 'TERMS_AND_CONDITIONS_MBF_PAY-LEASING' | 'TERMS_AND_CONDITIONS_MBF_PAY-FINANCING' | 'SERVICE_TERMS_PAY-FINANCING' | 'SERVICE_TERMS_PAY-LEASING' | 'SALES_TERMS_PAY-FINANCING' | 'TERMS_AND_CONDITIONS_VANS_PAY-LEASING' | 'PERSONAL_DATA_CONSENT' | 'AUTHORIZED_DEALERS_GENERAL_CONDITIONS' | 'PRE_CONTRACTUAL_CONDITIONS' | 'CUSTOMER_INFORMATION' | 'DATA_PROTECTION' | 'DATA_PROTECTION_INDEPENDENT_RETAILER' | 'RESERVATION_FEE_TERMS_AND_CONDITIONS' | 'SELLER_SALES_TAX_FORM' | 'SALE_TERMS_AND_CONDITIONS' | 'WARRANTY_MAINTENANCE_PLAN_TERMS_AND_CONDITIONS' | 'LEGAL_NOTICE' | 'DEPOSIT_TERMS_AND_CONDITIONS' | 'ORDER_FORM' | 'PROTECTION_PLAN' | 'RESERVATION_TERMS' | 'CANCEL_REVOCATION';
  documentStatus?: 'ACTIVE' | 'ARCHIVED';
  documentSortOrder?: 'ASC' | 'DESC';
  documentSort?: 'CREATED_AT' | 'LAST_MODIFIED_AT' | 'CREATED_BY' | 'LAST_MODIFIED_BY' | 'STATE' | 'TYPE' | 'SCOPE' | 'NAME';
  documentScope?: 'ACCESSORIES' | 'COLLECTION' | 'CONNECT' | 'NCOS' | 'UCOS' | 'GPME';
  documentLanguage?: 'DE' | 'EN' | 'IT' | 'FR' | 'SV' | 'NL' | 'PT';
  documentCountry?: string;
  customerType?: 'PRIVATE' | 'BUSINESS';
  TrackingId?: string;
  CorrelationId?: string;
}

export interface GetSellersDocumentParams {
  uid: string;
  outletId: string;
  country: string;
  companyId: string;
  Timestamp: string;
  Authorization: string;
  ApplicationName: string;
  TrackingId?: string;
  CorrelationId?: string;
}

export interface DeleteSellersDocumentParams {
  uid: string;
  outletId: string;
  country: string;
  companyId: string;
  Timestamp: string;
  Authorization: string;
  ApplicationName: string;
  TrackingId?: string;
  CorrelationId?: string;
}

