import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StickyContainerComponent, StickyDirective } from './sticky.component';

@NgModule({
  declarations: [ StickyDirective, StickyContainerComponent ],
  exports: [ StickyDirective, StickyContainerComponent ],
  imports: [
    CommonModule,
    FormsModule,
  ],
})
export class StickyModule {}
