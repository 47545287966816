<sss-header-top
  (clickedCompanySelector)="emitClickCompanySelector()"
  [isProd]="isProd"
  [isLoggedIn]="(isReady$ | async)"
  [userRole]="(userRole$ | async)">
</sss-header-top>

<sss-header-main
  *ngIf="(isReady$ | async)"
  [userPermissions]="(userPermissions$ | async)">
</sss-header-main>

<div class="sc-selector-container">
  <sss-shop-outlet-selector
    *ngIf="(isShopOutletSelectorVisible$ | async)">
  </sss-shop-outlet-selector>

  <sss-company-outlet-selector-2
    *ngIf="(isCompanyOutletSelector2Visible$ | async)">
  </sss-company-outlet-selector-2>
</div>
