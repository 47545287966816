import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';
import { Observable } from 'rxjs';

@Injectable()
export class IdentityService {
  private SELLER_CENTER_TENANT = 'DCPSSS';

  constructor(
    private windowRefService: WindowRefService,
    private httpClient: HttpClient,
  ) {}

  public getIdentity(): Observable<any> {
    const headers = { Tenant: this.SELLER_CENTER_TENANT };
    return this.httpClient
      .get(this.windowRefService.environment.api.open?.claims, { headers: headers });
  }
}
