import { Injectable } from '@angular/core';
import { validateRequestParams } from '@core/interceptors/params-validator';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { CurrentStateService } from '@core/store/current-state.service';
import { CountryCode } from '@core/types/types';
import { ArticlePriceList } from '@generated/sssa/models/article-price-list';
import { SellerDeliveryModes } from '@generated/sssa/models/seller-delivery-modes';
import { SellerPriceListContainer } from '@generated/sssa/models/seller-price-list-container';
import { SellerShippingClassUpdate } from '@generated/sssa/models/seller-shipping-class-update';
import { ShopSurcharge } from '@generated/sssa/models/shop-surcharge';
import { GetArticlesPricesParams, GetShippingCostParams, ProtectedService as PricingAccessoriesApiService, UpdateArticlesPricesParams, UpdateArticlesSurchargeParams, UpdateShippingCostsParams } from '@generated/sssa/services/protected.service';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';
import { Observable } from 'rxjs';
import { CompanyId, OutletId } from '@core/types/profile.types';

@Injectable()
export class PricingAccessoriesService {
  constructor(
    private readonly pricingAccessoriesApiService: PricingAccessoriesApiService,
    private readonly windowRefService: WindowRefService,
    private readonly currentStateService: CurrentStateService,
  ) {
    this.pricingAccessoriesApiService.setDomain(this.windowRefService.environment.api.secured.sssa);
  }

  public getArticles(country: CountryCode, outletId: OutletId, page: number, pageSize: number): Observable<SellerPriceListContainer> {
    const params = validateRequestParams<GetArticlesPricesParams>({ country, companyId: this.companyId, outletId, page, pageSize });
    return this.pricingAccessoriesApiService.getArticlesPrices(params);
  }

  public updateSurcharge(country: CountryCode, outletId: OutletId, surcharge: ShopSurcharge): Observable<ShopSurcharge> {
    const params = validateRequestParams<UpdateArticlesSurchargeParams>({ country, companyId: this.companyId, outletId, surcharge });
    return this.pricingAccessoriesApiService.updateArticlesSurcharge(params);
  }

  public updateArticles(country: CountryCode, outletId: OutletId, articles: ArticlePriceList): Observable<ArticlePriceList> {
    const params = validateRequestParams<UpdateArticlesPricesParams>({ country, companyId: this.companyId, outletId, articles });
    return this.pricingAccessoriesApiService.updateArticlesPrices(params);
  }

  public getShippingClasses(country: CountryCode, outletId: OutletId): Observable<SellerDeliveryModes> {
    const params = validateRequestParams<GetShippingCostParams>({ outletId, country, companyId: this.companyId });
    return this.pricingAccessoriesApiService.getShippingCost(params);
  }

  public updateShippingClass(country: CountryCode, outletId: OutletId, shippingClassesUpdates: SellerShippingClassUpdate): Observable<SellerShippingClassUpdate> {
    const params = validateRequestParams<UpdateShippingCostsParams>({ shippingClass: shippingClassesUpdates, outletId, country, companyId: this.companyId });
    return this.pricingAccessoriesApiService.updateShippingCosts(params);
  }

  private get companyId(): CompanyId {
    return this.currentStateService.get<CompanyId>(AuthSelectors.companyId);
  }
}
