import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ToasterComponent } from './toaster.component';
import { ToasterService } from './toaster.service';
import { ButtonModule } from '../button/button.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    PipesModule,
  ],
  providers: [ ToasterService ],
  declarations: [ ToasterComponent ],
  exports: [ ToasterComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class ToasterModule {}
