
export enum DocumentType {
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  ACCESSORIES_TERMS_AND_CONDITIONS = 'ACCESSORIES_TERMS_AND_CONDITIONS',
  IMPRINT = 'IMPRINT',
  CANCEL_NOTICE = 'CANCEL_NOTICE',
  PRIVACY_SECURITY = 'PRIVACY_SECURITY',
  JUNGE_STERNE_GUARANTEE = 'JUNGE_STERNE_GUARANTEE',
  SALES_TERMS = 'SALES_TERMS',
  USED_CAR_GUARANTEE = 'USED_CAR_GUARANTEE',
  DECLARATION_OF_CONSENT = 'DECLARATION_OF_CONSENT',
  CONSUMER_INFORMATION = 'CONSUMER_INFORMATION',
  REVOCABLE_FORM = 'REVOCABLE_FORM',
  EXCHANGE_RIGHT = 'EXCHANGE_RIGHT',
  USED_CAR_TERMS_AND_CONDITIONS = 'USED_CAR_TERMS_AND_CONDITIONS',
  NEW_CAR_GUARANTEE = 'NEW_CAR_GUARANTEE',
  GUARANTEE_TERMS = 'GUARANTEE_TERMS',
  PRECONTRACTUAL_INFORMATION = 'PRECONTRACTUAL_INFORMATION',
  DISCLOSURE_NOTICE = 'DISCLOSURE_NOTICE',
  DEMONSTRATION_CAR_TERMS_AND_CONDITIONS = 'DEMONSTRATION_CAR_TERMS_AND_CONDITIONS',
  PRIVACY_SECURITY_PAY_LEASING = 'PRIVACY_SECURITY_PAY-LEASING',
  PRIVACY_SECURITY_PAY_FINANCING = 'PRIVACY_SECURITY_PAY-FINANCING',
  PRIVACY_SECURITY_MBF_PAY_LEASING = 'PRIVACY_SECURITY_MBF_PAY-LEASING',
  PRIVACY_SECURITY_MBF_PAY_FINANCING = 'PRIVACY_SECURITY_MBF_PAY-FINANCING',
  RETURN_POLICY_PAY_LEASING = 'RETURN_POLICY_PAY-LEASING',
  TERMS_AND_CONDITIONS_MBF_PAY_LEASING = 'TERMS_AND_CONDITIONS_MBF_PAY-LEASING',
  TERMS_AND_CONDITIONS_MBF_PAY_FINANCING = 'TERMS_AND_CONDITIONS_MBF_PAY-FINANCING',
  SERVICE_TERMS_PAY_FINANCING = 'SERVICE_TERMS_PAY-FINANCING',
  SERVICE_TERMS_PAY_LEASING = 'SERVICE_TERMS_PAY-LEASING',
  SALES_TERMS_PAY_FINANCING = 'SALES_TERMS_PAY-FINANCING',
  TERMS_AND_CONDITIONS_VANS_PAY_LEASING = 'TERMS_AND_CONDITIONS_VANS_PAY-LEASING',
  PERSONAL_DATA_CONSENT = 'PERSONAL_DATA_CONSENT',
  AUTHORIZED_DEALERS_GENERAL_CONDITIONS = 'AUTHORIZED_DEALERS_GENERAL_CONDITIONS',
  PRE_CONTRACTUAL_CONDITIONS = 'PRE_CONTRACTUAL_CONDITIONS',
  CUSTOMER_INFORMATION = 'CUSTOMER_INFORMATION',
  DATA_PROTECTION = 'DATA_PROTECTION',
  DATA_PROTECTION_INDEPENDENT_RETAILER = 'DATA_PROTECTION_INDEPENDENT_RETAILER',
  RESERVATION_FEE_TERMS_AND_CONDITIONS = 'RESERVATION_FEE_TERMS_AND_CONDITIONS',
  SELLER_SALES_TAX_FORM = 'SELLER_SALES_TAX_FORM',
  SALE_TERMS_AND_CONDITIONS = 'SALE_TERMS_AND_CONDITIONS',
  WARRANTY_MAINTENANCE_PLAN_TERMS_AND_CONDITIONS = 'WARRANTY_MAINTENANCE_PLAN_TERMS_AND_CONDITIONS',
  LEGAL_NOTICE = 'LEGAL_NOTICE',
  DEPOSIT_TERMS_AND_CONDITIONS = 'DEPOSIT_TERMS_AND_CONDITIONS',
  ORDER_FORM = 'ORDER_FORM',
  PROTECTION_PLAN = 'PROTECTION_PLAN',
  RESERVATION_TERMS = 'RESERVATION_TERMS',
  CANCEL_REVOCATION = 'CANCEL_REVOCATION',
}
