
export enum UserRole {
  SELLER = 'seller',
  COUNTRY_MANAGER = 'country-manager',
}

export enum UserPermission {
  COUNTRY_EDITOR = 'COUNTRY_EDITOR',
  COMPANY_EDITOR = 'COMPANY_EDITOR',
  OUTLET_EDITOR = 'OUTLET_EDITOR',
}

export interface UserPermissions {
  [UserPermission.COUNTRY_EDITOR]: boolean;
  [UserPermission.COMPANY_EDITOR]: boolean;
  [UserPermission.OUTLET_EDITOR]: boolean;
}
