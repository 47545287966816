import { LanguageCode } from '@core/config/global.config';
import { Action } from '@ngrx/store';
import { ActionMap, reduce } from '../root.state';
import { SessionActionTypes, SetCommerceDomainAction, SetLanguageAction, SetSellerCenterDomainAction, SetUiLanguagesAction } from './session.actions';
import { SessionState, SESSION_INITIAL_STATE } from './session.state';

export function sessionReducer(state: SessionState = SESSION_INITIAL_STATE, action: Action): SessionState {
  return reduce(REDUCER_ACTION_MAP, action, state);
}

function resetSessionState(state: SessionState): SessionState {
  return {
    ...SESSION_INITIAL_STATE,
    selectedUiLanguage: state.selectedUiLanguage,
    uilanguages: state.uilanguages,
  };
}

function setCommerceDomain(state: SessionState, action: SetCommerceDomainAction): SessionState {
  return {
    ...state,
    commerceDomain: action.commerceDomain,
  };
}

function setSellerCenterDomain(state: SessionState, action: SetSellerCenterDomainAction): SessionState {
  return {
    ...state,
    sellerCenterDomain: action.sellerCenterDomain,
  };
}

function setLanguage(state: SessionState, action: SetLanguageAction): SessionState {
  return {
    ...state,
    selectedUiLanguage: action.language,
  };
}

function setUiLanguages(state: SessionState, action: SetUiLanguagesAction): SessionState {
  return {
    ...state,
    uilanguages: [ LanguageCode.EN, ...action.languages ],
  };
}

const REDUCER_ACTION_MAP: ActionMap = {
  [ SessionActionTypes.RESET_SESSION_STATE ]: resetSessionState,
  [ SessionActionTypes.SET_COMMERCE_DOMAIN ]: setCommerceDomain,
  [ SessionActionTypes.SET_SELLER_CENTER_DOMAIN ]: setSellerCenterDomain,
  [ SessionActionTypes.SET_LANGUAGE ]: setLanguage,
  [ SessionActionTypes.SET_UI_LANGUAGES ]: setUiLanguages,
};
