import { Order } from '@app/orders/orders.typings';
import { Action } from '@ngrx/store';

export enum OrderActionTypes {
  RESET = '[Order] RESET',
  SELECT_ORDER = '[Order] SELECT_ORDER',
}

export class ResetOrderAction implements Action {
  public readonly type = OrderActionTypes.RESET;
}

export class SelectOrderAction implements Action {
  public readonly type = OrderActionTypes.SELECT_ORDER;

  constructor(
    public readonly order: Order,
  ) { }
}
