import { SellerCenterShopName } from '@core/config/global.config';
import { Action } from '@ngrx/store';
import { Outlet } from '@core/types/types';

export const SHOW = '[ShopOutletSelector] SHOW';
export const RESET = '[ShopOutletSelector] RESET';
export const SELECT_SHOP = '[ShopOutletSelector] SELECT_SHOP';
export const SELECT_OUTLET = '[ShopOutletSelector] SELECT_OUTLET';

export class ShowAction implements Action {
  public readonly type = SHOW;

  constructor(
    public readonly availableShopsFilter: Array<SellerCenterShopName>,
  ) {}
}

export class ResetAction implements Action {
  public readonly type = RESET;
}

export class SelectShopAction implements Action {
  public readonly type = SELECT_SHOP;

  constructor(
    public readonly shop: SellerCenterShopName,
  ) {}
}

export class SelectOutletAction implements Action {
  public readonly type = SELECT_OUTLET;

  constructor(
    public readonly outlet: Outlet,
  ) {}
}
