import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '@core/store/root.state';
import * as SessionActions from '@core/store/session/session.actions';
import { CurrentStateService } from '@core/store/current-state.service';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { CommerceDomain } from '@core/config/global.config';

@Injectable()
export class CommerceDomainSelectorService {
  constructor(
    private readonly store: Store<RootState>,
    private readonly currentStateService: CurrentStateService,
  ) {}

  public enableCommerceDomainSelector(): boolean {
    const commerceDomains = this.currentStateService.get(AuthSelectors.commerceDomains);
    const hasCountryDE = this.currentStateService.get(AuthSelectors.hasCountryDE);

    /**
     * FYI
     * Must force shops for DE due onboarding tool
     */
    if (hasCountryDE) {
      if (!commerceDomains.length) {
        this.store.dispatch(new SessionActions.SetCommerceDomainAction(CommerceDomain.SHOPS));
        return false;
      }

      if (!commerceDomains.includes(CommerceDomain.SHOPS) && commerceDomains.includes(CommerceDomain.MARKETPLACE)) {
        return true;
      }
    }

    if (commerceDomains.length === 1) {
      this.store.dispatch(new SessionActions.SetCommerceDomainAction(commerceDomains[0]));
      return false;
    }

    return true;
  }
}
