<wb-grid>
  <wb-grid-row>
    <wb-grid-col mq1="12">
      <div class="sss-header-top__container">

        <!-- logo -->
        <div class="sss-brand">
          <a [routerLink]="Route.DASHBOARD">

            <!-- mb star -->
            <img
              src="https://assets.oneweb.mercedes-benz.com/global/1.15.0/images/svg/MB-star_n_web.svg"
              class="sss-brand__star"
              alt="Mercedes-Benz" />
          </a>
          <!-- app name -->
          <h1
            class="sss-app-title">
            Seller.<span class="sss-app-title--blue">{{ applicationNameSuffix }}</span>
          </h1>
        </div>

        <!-- links -->
        <div class="sss-header-top__nav h-hide-for-mq-lt-3">

          <!-- Environment -->
          <sss-badge
            *ngIf="isVisible(TopMenu.ENVIRONMENT_INFORMATION)">
            {{ environmentLabel }}
          </sss-badge>

          <!-- Shop Management (aka Seller Master Data) -->
          <a
            *ngIf="isVisible(TopMenu.SHOP_MANAGEMENT)"
            [routerLink]="Route.MASTER_DATA"
            routerLinkActive="sss-header-top__nav__item--active"
            class="sss-header-top__nav__item sss-header-top__nav__item--link"
            data-qa="shop-management-link">

            <wb-icon
              name="settings"
              aria-hidden="true"
              class="wb-button__icon"
            ></wb-icon>

            <span class=" h-hide-for-mq-lt-4">
              {{ 'common.header.navItem.masterData' | sssTranslate | async }}
            </span>
          </a>

          <!-- Company Selector -->
          <wb-button
            *ngIf="isVisible(TopMenu.COMPANY_SELECTOR) && this.hasMultipleCompanies"
            (click)="emitClickCompanySelector()"
            class="sss-header-top__nav__item sss-header-top__nav__item--link"
            variant="tertiary"
            data-qa="company-link"
          >
            <wb-icon
              slot="icon"
              name="company-registration"
              aria-hidden="true"
              class="wb-button__icon"
            ></wb-icon>
            <wb-text size="s" class="h-hide-for-mq-lt-4">
              {{ 'common.header.navItem.company' | sssTranslate | async }}
            </wb-text>
          </wb-button>

          <!-- User name -->
          <a
            *ngIf="isVisible(TopMenu.USER_NAME)"
            [routerLink]="PROFILE_ROUTE.MANAGE"
            routerLinkActive="sss-header-top__nav__item--active"
            class="sss-header-top__nav__item sss-header-top__nav__item--link"
            data-qa="user-profile-link">
            <wb-icon
              name="userimage"
              aria-hidden="true"
              class="wb-button__icon"
            >
            </wb-icon>
            <span>
              {{ userName }}
            </span>
          </a>

          <!-- Language Switcher -->
          <button
            *ngIf="isVisible(TopMenu.LANGUAGE_SELECTOR)"
            (click)="openLanguageModal()"
            type="button"
            class="sss-header-top__nav__item sss-header-top__nav__item--link"
            data-qa="language-selector-link">
            <wb-icon
              name="language"
              aria-hidden="true"
              class="wb-button__icon"
            >
            </wb-icon>
            <span class=" h-hide-for-mq-lt-4">
              {{ 'common.languageSwitcher.uiLanguages.' + selectedLanguageCode | sssTranslate | async }}
            </span>
          </button>

          <!-- Logout -->
          <button
            *ngIf="isVisible(TopMenu.LOGOUT)"
            (click)="logout()"
            type="button"
            class="sss-header-top__nav__item sss-header-top__nav__item--link"
            data-qa="logout-link">
            <wb-icon
              name="logout"
              aria-hidden="true"
              class="wb-button__icon"
            >
            </wb-icon>
            <span class=" h-hide-for-mq-lt-4">
              {{ 'common.header.navItem.logout' | sssTranslate | async }}
            </span>
          </button>
        </div>
      </div>
    </wb-grid-col>
  </wb-grid-row>
</wb-grid>

<sss-language-switcher-modal
  *ngIf="isLanguageModalOpen"
  (close)="closeLanguageModal()"
>
</sss-language-switcher-modal>
