<div
  #innerModal
  class="sss-modal__inner">
  <header #modalHeader class="sss-modal__header" *ngIf="showModalHeader; else closeButton">
    <ng-content select="modal-header">
    </ng-content>
    <ng-container *ngTemplateOutlet="closeButton">
    </ng-container>
  </header>
  <div
    class="sss-modal__content"
    [class.sss-modal__content--fixed-height]="fixedHeight"
    [style.min-height]="minHeight"
  >
    <wb-heading size="m"
      *ngIf="headline"
      class="h-margin-remove h-margin-bottom">
      {{ headline }}
    </wb-heading>
    <ng-content></ng-content>
  </div>


  <footer
  class="sss-modal__footer">
    <ng-content
      select="modal-footer">
    </ng-content>
  </footer>
</div>

<ng-template #closeButton>
  <div class="sss-modal__button-container">
    <wb-button
      variant="tertiary"
      size="s"
      *ngIf="showCloseButton"
      (click)="closeModal($event)"
      class="sss-modal__button">
      <wb-icon
        name="close"
        slot="icon"
        class="sss-modal__button__icon"
        flip-rtl></wb-icon>
    </wb-button>
  </div>
</ng-template>
