import { HttpErrorResponse } from '@angular/common/http';
import { CountryCode } from '@core/types/types';
import { ArticlePriceList } from '@generated/sssa/models/article-price-list';
import { SellerPriceListContainer } from '@generated/sssa/models/seller-price-list-container';
import { SellerShippingClassUpdate } from '@generated/sssa/models/seller-shipping-class-update';
import { ShippingClass } from '@generated/sssa/models/shipping-class';
import { ShopSurcharge } from '@generated/sssa/models/shop-surcharge';
import { Action } from '@ngrx/store';
import { OutletId } from '@core/types/profile.types';

export const GET_ARTICLES_REQUEST = '[Accessories] GET_ARTICLES_REQUEST';
export const GET_ARTICLES_SUCCESS = '[Accessories] GET_ARTICLES_SUCCESS';
export const GET_ARTICLES_FAILURE = '[Accessories] GET_ARTICLES_FAILURE';

export const UPDATE_ARTICLES_REQUEST = '[Accessories] UPDATE_ARTICLES_REQUEST';
export const UPDATE_ARTICLES_SUCCESS = '[Accessories] UPDATE_ARTICLES_SUCCESS';
export const UPDATE_ARTICLES_FAILURE = '[Accessories] UPDATE_ARTICLES_FAILURE';

export const UPDATE_SURCHARGE_REQUEST = '[Accessories] UPDATE_SURCHARGE_REQUEST';
export const UPDATE_SURCHARGE_SUCCESS = '[Accessories] UPDATE_SURCHARGE_SUCCESS';
export const UPDATE_SURCHARGE_FAILURE = '[Accessories] UPDATE_SURCHARGE_FAILURE';

export const GET_SHIPPING_CLASSES_REQUEST = '[Accessories] GET_SHIPPING_CLASSES_REQUEST';
export const GET_SHIPPING_CLASSES_SUCCESS = '[Accessories] GET_SHIPPING_CLASSES_SUCCESS';
export const GET_SHIPPING_CLASSES_FAILURE = '[Accessories] GET_SHIPPING_CLASSES_FAILURE';

export const UPDATE_SHIPPING_CLASS_REQUEST = '[Accessories] UPDATE_SHIPPING_CLASS_REQUEST';
export const UPDATE_SHIPPING_CLASS_SUCCESS = '[Accessories] UPDATE_SHIPPING_CLASS_SUCCESS';
export const UPDATE_SHIPPING_CLASS_FAILURE = '[Accessories] UPDATE_SHIPPING_CLASS_FAILURE';

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_PAGE_SIZE = 100;

export class GetArticlesRequestAction implements Action {
  public readonly type = GET_ARTICLES_REQUEST;

  constructor(
    public readonly country: CountryCode,
    public readonly outletId: OutletId,
    public readonly page: number = DEFAULT_PAGE_NUMBER,
    public readonly pageSize: number = DEFAULT_PAGE_SIZE,
  ) {}
}

export class GetArticlesSuccessAction implements Action {
  public readonly type = GET_ARTICLES_SUCCESS;

  constructor(
    public readonly sellerPriceListContainer: SellerPriceListContainer,
  ) {}
}

export class GetArticlesFailureAction implements Action {
  public readonly type = GET_ARTICLES_FAILURE;

  constructor(
    public readonly error: HttpErrorResponse,
  ) {}
}

export class UpdateArticlesRequestAction implements Action {
  public readonly type = UPDATE_ARTICLES_REQUEST;

  constructor(
    public readonly country: CountryCode,
    public readonly outletId: OutletId,
    public readonly articles: ArticlePriceList,
  ) {}
}

export class UpdateArticlesSuccessAction implements Action {
  public readonly type = UPDATE_ARTICLES_SUCCESS;

  constructor(
    public readonly articles: ArticlePriceList,
  ) {}
}

export class UpdateArticlesFailureAction implements Action {
  public readonly type = UPDATE_ARTICLES_FAILURE;

  constructor(
    public readonly error: HttpErrorResponse,
  ) {}
}

export class UpdateSurchargeRequestAction implements Action {
  public readonly type = UPDATE_SURCHARGE_REQUEST;

  constructor(
    public readonly country: CountryCode,
    public readonly outletId: OutletId,
    public readonly surcharge: ShopSurcharge,
  ) {}
}

export class UpdateSurchargeSuccessAction implements Action {
  public readonly type = UPDATE_SURCHARGE_SUCCESS;

  constructor(
    public readonly surcharge: ShopSurcharge,
  ) {}
}

export class UpdateSurchargeFailureAction implements Action {
  public readonly type = UPDATE_SURCHARGE_FAILURE;

  constructor(
    public readonly error: HttpErrorResponse,
  ) {}
}

export class GetShippingClassesRequestAction implements Action {
  public readonly type = GET_SHIPPING_CLASSES_REQUEST;

  constructor(
    public readonly country: CountryCode,
    public readonly outletId: OutletId,
  ) {}
}

export class GetShippingClassesSuccessAction implements Action {
  public readonly type = GET_SHIPPING_CLASSES_SUCCESS;

  constructor(
    public readonly shippingClasses: Array<ShippingClass>,
  ) {}
}

export class GetShippingClassesFailureAction implements Action {
  public readonly type = GET_SHIPPING_CLASSES_FAILURE;

  constructor(
    public readonly error: HttpErrorResponse,
  ) {}
}

export class UpdateShippingClassRequestAction implements Action {
  public readonly type = UPDATE_SHIPPING_CLASS_REQUEST;

  constructor(
    public readonly country: CountryCode,
    public readonly outletId: OutletId,
    public readonly shippingClassUpdate: SellerShippingClassUpdate,
  ) {}
}

export class UpdateShippingClassSuccessAction implements Action {
  public readonly type = UPDATE_SHIPPING_CLASS_SUCCESS;

  constructor(
    public readonly shippingClassUpdate: SellerShippingClassUpdate,
  ) {}
}

export class UpdateShippingClassFailureAction implements Action {
  public readonly type = UPDATE_SHIPPING_CLASS_FAILURE;

  constructor(
    public readonly error: HttpErrorResponse,
  ) {}
}
