<wb-grid>
  <wb-grid-row>
    <wb-grid-col
      *ngIf="marketSelectionAvailable"
      mq1="4"
    >
      <sss-dropdown-market
        [disabled]="disabled"
        [filter]="filter"
        [isCountryLevel]="config?.countryLevel"
        (changedMarket)="selectMarket($event)">
      </sss-dropdown-market>
    </wb-grid-col>

    <wb-grid-col
      mq1="4"
    >
      <sss-dropdown-company
        [disabled]="disabled"
        [filter]="filter"
        [isCountryLevel]="config?.countryLevel"
        (changedCompany)="selectCompany($event)">
      </sss-dropdown-company>
    </wb-grid-col>

    <wb-grid-col
      *ngIf="config?.selectorOutlet"
      mq1="4">
      <sss-dropdown-outlet
        [disabled]="disabled"
        [filter]="filter"
        [allOption]="config?.allOutletOption"
        [autoSelection]="config?.autoSelection"
        [isCountryLevel]="config?.countryLevel"
        (changedOutlet)="selectOutlet($event)">
      </sss-dropdown-outlet>
    </wb-grid-col>
  </wb-grid-row>
</wb-grid>
