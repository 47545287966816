import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { HttpErrorInterceptor } from '@core/interceptors/http-error.interceptor';
import { NoIeCacheInterceptor } from '@core/interceptors/no-ie-cache-interceptor';
import { RequestHeaderExtenderInterceptor } from '@core/interceptors/request-header-extender.interceptor';
import { AppInitializerService } from '@core/services/app-initializer/app-initializer.service';
import { BackgroundColorService } from '@core/services/background-color/background-color.service';
import { ConsoleCommandService } from '@core/services/console-command/console-command.service';
import { DocumentTitleService } from '@core/services/document-title/document-title.service';
import { DomainAccessService } from '@core/services/domain-access/domain-access.service';
import { ErrorService } from '@core/services/error/error.service';
import { FeatureToggleService } from '@core/services/feature-toggle/feature-toggle.service';
import { IdentityService } from '@core/services/identity/identity.service';
import { LoggerService } from '@core/services/logger/logger.service';
import { SessionService } from '@core/services/session/session.service';
import { CurrentStateService } from '@core/store/current-state.service';
import { ProtectedService as PricingAccessoriesApiService } from '@generated/sssa/services/protected.service';
import { ProtectedDocumentService as DocumentApiService } from '@generated/sssd/services/protected-document.service';
import { ProtectedService as PricingGPmeApiService } from '@generated/sssg/services/protected.service';
import { OrdersService } from '@generated/ssso/services';
import { ProtectedService as ProfileApiService } from '@generated/sssp/services/protected.service';
import { BrowserUtilService } from '@shared/services/browser-util/browser-util.service';
import { MailtoService } from '@shared/services/mailto.service';
import { MediaQueryService } from '@shared/services/media-query/media-query.service';
import { ScrollLockService } from '@shared/services/scroll-lock/scroll-lock.service';
import { SortService } from '@shared/services/sort/sort.service';
import { SpecialEditionService } from '@shared/services/special-edition/special-edition.service';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';
import { DocumentService } from './core/documents/document.service';
import { PricingAccessoriesService } from './pricing/components/accessories/pricing-accessories.service';
import { PricingGPmeService } from './pricing/components/gpme/pricing-gpme.service';
import { ProfileService } from './profile/profile.service';
import { PermissionsService } from './shared/services/permissions.service';

export const APP_PROVIDERS = [
  SessionService,
  LoggerService,
  BackgroundColorService,
  WindowRefService,
  DocumentTitleService,
  ScrollLockService,
  CurrentStateService,
  IdentityService,
  AppInitializerService,
  BrowserUtilService,
  SortService,
  ConsoleCommandService,
  SpecialEditionService,
  DomainAccessService,
  MediaQueryService,
  FeatureToggleService,
  MailtoService,

  // TODO: provide feature services in feature modules. don't forget errorHandler and interceptors

  PricingAccessoriesService,
  PricingAccessoriesApiService,

  PricingGPmeService,
  PricingGPmeApiService,

  DocumentService,
  DocumentApiService,

  ProfileService,
  ProfileApiService,

  OrdersService,
  PermissionsService,
  {
    multi: true,
    provide: HTTP_INTERCEPTORS,
    useClass: NoIeCacheInterceptor,
  }, {
    multi: true,
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
  }, {
    multi: true,
    provide: HTTP_INTERCEPTORS,
    useClass: RequestHeaderExtenderInterceptor,
  }, {
    provide: ErrorHandler,
    useClass: ErrorService,
  },
];
