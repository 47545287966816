import { Injectable } from '@angular/core';
import { getInternalShopName, SellerCenterDomain, SellerCenterShopName, SHOPS_WITHOUT_OUTLETS, SHOPS_WITH_OUTLETS } from '@core/config/global.config';
import { validateRequestParams } from '@core/interceptors/params-validator';
import { Document as PdfDocument } from '@generated/sssd/models/document';
import { DocumentContainer } from '@generated/sssd/models/document-container';
import { DocumentCountry } from '@generated/sssd/models/document-country';
import { DocumentCustomerType } from '@generated/sssd/models/document-customer-type';
import { DocumentLanguage } from '@generated/sssd/models/document-language';
import { DocumentScope } from '@generated/sssd/models/document-scope';
import { DocumentStatus } from '@generated/sssd/models/document-status';
import { DocumentType } from '@generated/sssd/models/document-type';
import { Link } from '@generated/sssd/models/link';
import { LinkRel } from '@generated/sssd/models/link-rel';
import { GetSellersDocumentsParams, PostDocumentForCountryParams, ProtectedDocumentService as DocumentApiService, DeleteSellersDocumentParams } from '@generated/sssd/services/protected-document.service';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';
import { Observable } from 'rxjs';
import { DOCUMENT_OVERVIEW_CONFIG } from '@app/document/document.config';
import { DocumentOverview, DocumentTableRow } from '@app/document/document.typings';
import { CompanyId, OutletId } from '../types/profile.types';

export type SellerType = 'GC' | 'GS';

@Injectable()
export class DocumentService {
  constructor(
    private documentApiService: DocumentApiService,
    private windowRefService: WindowRefService,
  ) {
    this.documentApiService.setDomain(this.windowRefService.environment.api.secured.sssd);
  }

  public getDocuments(
    companyId: CompanyId,
    outletId: OutletId,
    documentStatus: DocumentStatus,
    documentCountry: DocumentCountry,
    documentLanguage: DocumentLanguage,
    documentScope: DocumentScope,
    documentType?: DocumentType,
    page?: number,
    pageSize?: number,
    customerType?: DocumentCustomerType,
  ): Observable<DocumentContainer> {
    const params = validateRequestParams<GetSellersDocumentsParams>({
      country: documentCountry,
      companyId,
      outletId,
      documentScope,
      documentStatus,
      documentCountry,
      documentLanguage: documentLanguage as any, // note: API is case insensitive
      documentSort: 'CREATED_AT',
      documentSortOrder: 'DESC',
      page,
      pageSize,
      documentType,
      customerType,
    });

    return this.documentApiService.getSellersDocuments(params);
  }

  public uploadDocument(document: PdfDocument, companyId: CompanyId, outletId: OutletId): Observable<PdfDocument> {
    const params = validateRequestParams<PostDocumentForCountryParams>({
      document,
      companyId,
      outletId,
      country: document.country,
      sellerType: this.getSellerTypeForDocument(document),
    });

    return this.documentApiService.postDocumentForCountry(params);
  }

  public getDownloadUrlForDocument(document: PdfDocument): string {
    if (!document) {
      throw new Error('invalid document given');
    }

    /* eslint-disable-next-line no-underscore-dangle */
    const downloadLink = document._links.find((link: Link) => link.rel === LinkRel.DOWNLOAD);
    return downloadLink.href;
  }

  // TODO: think about if we can instead return the overview in method `getDocuments`
  public getOverview(documentCountry: DocumentCountry, documentScope: DocumentScope, documentLanguages: string[], documentContainers: Array<DocumentContainer>): DocumentOverview {
    const configForMarketAndShop: DocumentOverview = { ...DOCUMENT_OVERVIEW_CONFIG[ documentCountry ][ documentScope ] };
    const allUploadedDocuments: Array<PdfDocument> = [];
    documentContainers
      .forEach((container: DocumentContainer) => {
        allUploadedDocuments.push(...container.documents);
      });

    return {
      [ DocumentCustomerType.PRIVATE ]: this.getFilledDocumentRows(DocumentCustomerType.PRIVATE, configForMarketAndShop, documentLanguages, allUploadedDocuments, documentScope),
      [ DocumentCustomerType.BUSINESS ]: this.getFilledDocumentRows(DocumentCustomerType.BUSINESS, configForMarketAndShop, documentLanguages, allUploadedDocuments, documentScope),
    };
  }

  public deleteDocument(uid: string, country: DocumentCountry, companyId: CompanyId, outletId: OutletId): Observable<void> {
    const params = validateRequestParams<DeleteSellersDocumentParams>({
      outletId,
      companyId,
      country,
      uid,
    });

    return this.documentApiService.deleteSellersDocument(params);
  }

  private getFilledDocumentRows(customerType: DocumentCustomerType, configForMarketAndShop: DocumentOverview, documentLanguages: string[], allUploadedDocuments: Array<PdfDocument>, documentScope: DocumentScope): Array<DocumentTableRow> {
    return (configForMarketAndShop[customerType] || [])
      .map((row: DocumentTableRow) => {
        const documentLanguageMap = {};

        documentLanguages
          .forEach((documentLanguage: string) => {
            const uploadedActiveDocumentForType = allUploadedDocuments
              .find((doc: PdfDocument) => Boolean(
                doc.customerType === customerType
                && doc.type === row.type
                && doc.scope === documentScope
                && doc.language === documentLanguage
                && doc.status === DocumentStatus.ACTIVE,
              ));

            documentLanguageMap[ documentLanguage ] = uploadedActiveDocumentForType || null;
          });

        return { ...row, documentLanguageMap };
      });
  }

  private getSellerTypeForDocument(document: PdfDocument): SellerType {
    const shopName: SellerCenterShopName = getInternalShopName(SellerCenterDomain.DOCUMENTS, document.scope);

    if (SHOPS_WITHOUT_OUTLETS.includes(shopName)) {
      return 'GC';
    } else if (SHOPS_WITH_OUTLETS.includes(shopName)) {
      return 'GS';
    } else {
      throw new Error(`Couldn't determine if shop '${ document.scope }' is based on GC or GS id`);
    }
  }
}
