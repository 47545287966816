import { HttpErrorResponse } from '@angular/common/http';
import { CompanyId, OutletId } from '@core/types/profile.types';
import { Action } from '@ngrx/store';
import { CompanyDocumentUploads, OutletDocumentUploads } from '../document-marketplace/document-marketplace.typings';

export const FETCH_DOCUMENTS_MARKETPLACE_REQUEST = '[Documents Marketplace] FETCH_DOCUMENTS_MARKETPLACE_REQUEST';
export const FETCH_DOCUMENTS_MARKETPLACE_SUCCESS = '[Documents Marketplace] FETCH_DOCUMENTS_MARKETPLACE_SUCCESS';
export const FETCH_DOCUMENTS_MARKETPLACE_FAILURE = '[Documents Marketplace] FETCH_DOCUMENTS_MARKETPLACE_FAILURE';
export const CLEAR_DOCUMENTS_MARKETPLACE = '[Documents Marketplace] CLEAR_DOCUMENTS_MARKETPLACE';
export const CANCEL_FETCH_DOCUMENTS_MARKETPLACE_REQUEST = '[Documents Marketplace] CANCEL_FETCH_DOCUMENTS_MARKETPLACE_REQUEST';

export class FetchDocumentsMarketplaceRequestAction implements Action {
  readonly type = FETCH_DOCUMENTS_MARKETPLACE_REQUEST;

  constructor(
    public readonly market: string,
    public readonly country: string,
    public readonly companyId: CompanyId,
    public readonly outletId: OutletId,
    public readonly mustFetchOutlets: boolean,
  ) {}
}

export class FetchDocumentsMarketplaceSuccessAction implements Action {
  readonly type = FETCH_DOCUMENTS_MARKETPLACE_SUCCESS;
  constructor(
    public documents: Array<OutletDocumentUploads | CompanyDocumentUploads>,
  ) {}
}

export class FetchDocumentsMarketplaceFailureAction implements Action {
  readonly type = FETCH_DOCUMENTS_MARKETPLACE_FAILURE;

  constructor(
    public readonly error?: HttpErrorResponse,
  ) {}
}

export class ClearDocumentsMarketplaceRequestAction implements Action {
  readonly type = CLEAR_DOCUMENTS_MARKETPLACE;
}

export class CancelFetchDocumentsMarketplaceRequest implements Action {
  readonly type = CANCEL_FETCH_DOCUMENTS_MARKETPLACE_REQUEST;
}
