import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Components, IconName } from '@workbench/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sss-icon',
  template: '<wb-icon #iconEl flip-rtl></wb-icon>',
  styleUrls: [ './icon.component.scss' ],
})
export class IconComponent implements OnInit {
  @ViewChild('iconEl', { static: true }) public iconEl: ElementRef<Components.WbIcon>;

  ngOnInit(): void {
    setTimeout(() => {
      // Avoid workbench error
      this.iconEl.nativeElement.name = this.name as IconName;
    }, 10);
  }

  @Input()
  public name: string = null;
}
