import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { isBool, Value } from '@trademe/ensure';

@Component({
  selector: 'sss-hint',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './hint.component.html',
  styleUrls: [ './hint.component.scss' ],
  host: { class: 'sss-hint' },
})
export class HintComponent {
  @Input()
  @Value(isBool)
  @HostBinding('class.sss-hint--confirmation')
  public confirmation: boolean;

  @Input()
  @Value(isBool)
  @HostBinding('class.sss-hint--error')
  public error: boolean;

  @Input()
  @Value(isBool)
  @HostBinding('class.sss-hint--dark-mode')
  public darkMode = false;

  @Input()
  @Value(isBool)
  public dismissable = false;

  @Output()
  public dismiss: EventEmitter<null> = new EventEmitter();

  public onDismiss(): void {
    this.dismiss.emit();
  }
}
