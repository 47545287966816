import { Injectable } from '@angular/core';
import { LanguageCode } from '@core/config/global.config';
import { CurrentStateService } from '@core/store/current-state.service';
import { RootState } from '@core/store/root.state';
import { Store } from '@ngrx/store';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as SessionActions from '@core/store/session/session.actions';
import * as SessionSelectors from '@core/store/session/session.selectors';
import { HttpClient } from '@angular/common/http';
import * as TranslationActions from '@core/store/translation/translation.actions';
import * as TranslationSelectors from '@core/store/translation/translation.selectors';

@Injectable()
export class I18nService {
  translations: Record<string, string>;
  baseUrl = this.windowRefService.environment.api.external.phrase.baseUrl;
  branch = this.windowRefService.environment.api.external.phrase.branch;
  projectId = this.windowRefService.environment.api.external.phrase.projectId;
  currentLanguage = '';

  constructor(
    private readonly store: Store<RootState>,
    private readonly windowRefService: WindowRefService,
    private readonly currentStateService: CurrentStateService,
    private http: HttpClient
  ) {}

  public initialize(): void {
    this.validateSelectedLanguage();
  }

  public async getTranslations(reload = false): Promise<any> {
    const cachedTranslations = localStorage.getItem('translations');
    if (cachedTranslations && !reload) {
      this.translations = JSON.parse(cachedTranslations);
      this.store.dispatch(new TranslationActions.SetTranslationsAction(this.translations));
      if (await this.isCacheValid()) {
        return;
      }
    }

    this.http.get(
      `${this.baseUrl}/projects/${this.projectId}/locales/${this.currentLanguage}/download?file_format=simple_json&branch=${this.branch}`
    ).pipe(
      map((translations: Record<string, string>) => {
        this.translations = translations;
        this.setCache(translations);
        this.store.dispatch(new TranslationActions.SetTranslationsAction(translations));
      })
    ).subscribe();
  }

  public async setCache(translations: Record<string, string>): Promise<any> {
    const localeData = await this.getLocaleData();
    localStorage.setItem('translations', JSON.stringify(translations));
    localStorage.setItem('translationsUpdatedAt', localeData.updated_at);
    localStorage.setItem('translationsLanguage', localeData.code);
  }

  public async getLocaleData(): Promise<any> {
    const data = await this.http.get(
      `${this.baseUrl}/projects/${this.projectId}/locales/${this.currentLanguage}?branch=${this.branch}`
    ).toPromise();
    return data;
  }

  public async isCacheValid(): Promise<any> {
    const data = await this.getLocaleData();
    const cacheUpdatedAt = localStorage.getItem('translationsUpdatedAt');
    const cachedLanguage = localStorage.getItem('translationsLanguage');
    return cacheUpdatedAt === data.updated_at && cachedLanguage === this.currentLanguage;
  }

  public getTranslation(key: string, params?: Record<string, any>): Observable<string> {
    return this.store.select(TranslationSelectors.getTranslations)
      .pipe(map((translations: Record<string, string>) => {
        if (params) {
          return this.replaceParams(translations[key], params);
        }

        return translations[key];
      }));
  }

  public replaceParams(label: string, params: Record<string, string>): string {
    const parameters = Object.keys(params);
    if (parameters.length) {
      parameters.forEach((param: string) => {
        const paramRegEx = `{{\\s?${param}\\s?}}`;
        label = label.replace(new RegExp(paramRegEx), params[param]);
      });
    }

    return label;
  }

  public getInstantTranslation(key: string, params?: Record<string, any>): string { // TODO REMOVE
    if (params) {
      return this.replaceParams(this.translations[key], params);
    }

    return this.translations?.[key];
  }

  public getTranslationForLang(key: string, lang: string, params?: Record<string, any>): Observable<string> {
    return this.http.get(
      `${this.baseUrl}/projects/${this.projectId}/locales/${lang}/download?file_format=simple_json&branch=${this.branch}`
    ).pipe(
      map((translations: Record<string, string>) => {
        if (params) {
          return this.replaceParams(translations[key], params);
        }

        return translations[key];
      })
    );
  }

  public changeLanguageTo(newLang: LanguageCode): void {
    const previousLang = this.currentLanguage;
    this.currentLanguage = newLang;

    this.windowRefService.nativeDocument.documentElement.setAttribute('lang', newLang);

    if (previousLang !== newLang) {
      this.getTranslations(!!previousLang);
    }

    // In order to enforce a store state change we need first to dispatch an action with null and then with the correct value.
    // Why? It might be the case that we already have a UI language saved in the store. This triggers the localization updates in the UI.
    // The localization file might not have been loaded completely already (mostly occurs in IE).
    // Then we have the localization keys instead of the localizations itself displayed (with synchronous translation requests).
    // Once the localization file got loaded, the lang change triggers again, with that enforced store change the localizations do get updated.
    this.store.dispatch(new SessionActions.SetLanguageAction(null)); // intentionally
    this.store.dispatch(new SessionActions.SetLanguageAction(newLang));
  }

  public validateSelectedLanguage(): void {
    this.changeLanguageTo(this.activeUiLanguageWithFallback);
  }

  private get activeUiLanguageWithFallback(): LanguageCode {
    const languageFromStore = this.currentStateService.get(SessionSelectors.uiLanguageCode);
    const languageFromBrowser = navigator.language?.replace(/-\w{2}/, '') as LanguageCode;
    const selectedLanguage = languageFromStore || languageFromBrowser;
    const isSelectedLanguageAvailable = this.currentStateService
      .get(SessionSelectors.uiLanguages)
      .includes(selectedLanguage);

    return isSelectedLanguageAvailable ? selectedLanguage : LanguageCode.EN;
  }
}
