import { Injectable } from '@angular/core';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';
import { LoggerService } from '../logger/logger.service';

@Injectable()
export class AppInitializerService {
  constructor(
    private readonly windowRefService: WindowRefService,
    private readonly loggerService: LoggerService,
  ) { }

  public initialize(): void {
    this.loggerService.initialize();
  }
}
