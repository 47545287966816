import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { SellerPriceListContainer } from '../models/seller-price-list-container';
import { ArticlePriceList } from '../models/article-price-list';
import { ShopSurcharge } from '../models/shop-surcharge';
import { SellerDeliveryModes } from '../models/seller-delivery-modes';
import { SellerShippingClassUpdate } from '../models/seller-shipping-class-update';


/**
 * Protected endpoints requiring validation while using the validation service.
 */

@Injectable()
export class ProtectedService {
  private domain: string;

  constructor(
    private httpClient: HttpClient,
  ) {}

  public setDomain(domain: string): void {
    this.domain = domain;
  }


  /**
   * @param params The `ProtectedService.GetArticlesPricesParams` containing the following parameters:
   *
   * - `outletId`: A valid Outlet ID from a seller, GS ID
   *
   * - `country`: The localization country of the shop.
   *
   * - `companyId`: A valid company ID from a seller, GC ID
   *
   * - `applicationName`: A valid client application identifier
   *
   * - `Authorization`: Authorization header; see security definitions
   *
   * - `trackingId`: An optional ID for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `priceType`: Price type indicates if the prices is calculated based on the surchage or customized by the seller or the original MSRP price. If omitted all prices type must be considered.
   *
   * - `priceScope`: Price scopes indicates the coverage of the price which can be net or gross.
   *
   * - `pageSize`: Quantity of elements in one page
   *
   * - `page`: A page in case the client supports pagination. At the moment no client side control is provided for defining the item count per page.
   *
   * - `lang`: The localization language of the requester.
   *
   * - `correlationId`: An optional ID for correlating multiple requests for a business case
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * @return OK
   */

  public getArticlesPrices(params: GetArticlesPricesParams): Observable<SellerPriceListContainer> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;



    if (params.applicationName != null) requestHeaders = requestHeaders.set('applicationName', params.applicationName.toString());
    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.trackingId != null) requestHeaders = requestHeaders.set('trackingId', params.trackingId.toString());
    if (params.priceType != null) requestParams = requestParams.set('priceType', params.priceType.toString());
    if (params.priceScope != null) requestParams = requestParams.set('priceScope', params.priceScope.toString());
    if (params.pageSize != null) requestParams = requestParams.set('pageSize', params.pageSize.toString());
    if (params.page != null) requestParams = requestParams.set('page', params.page.toString());
    if (params.lang != null) requestParams = requestParams.set('lang', params.lang.toString());
    if (params.correlationId != null) requestHeaders = requestHeaders.set('correlationId', params.correlationId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());

    const request = new HttpRequest('GET', this.domain + `/protected/${params.country}/sellers/${params.companyId}/outlets/${params.outletId}/articles/prices`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<SellerPriceListContainer>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.UpdateArticlesPricesParams` containing the following parameters:
   *
   * - `outletId`: A valid Outlet ID from a seller, GS ID
   *
   * - `country`: The localization country of the shop.
   *
   * - `companyId`: A valid company ID from a seller, GC ID
   *
   * - `articles`:
   *
   * - `applicationName`: A valid client application identifier
   *
   * - `Authorization`: Authorization header; see security definitions
   *
   * - `trackingId`: An optional ID for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `lang`: The localization language of the requester.
   *
   * - `correlationId`: An optional ID for correlating multiple requests for a business case
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * @return OK
   */

  public updateArticlesPrices(params: UpdateArticlesPricesParams): Observable<ArticlePriceList> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;



    requestBody = params.articles;
    if (params.applicationName != null) requestHeaders = requestHeaders.set('applicationName', params.applicationName.toString());
    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.trackingId != null) requestHeaders = requestHeaders.set('trackingId', params.trackingId.toString());
    if (params.lang != null) requestParams = requestParams.set('lang', params.lang.toString());
    if (params.correlationId != null) requestHeaders = requestHeaders.set('correlationId', params.correlationId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());

    const request = new HttpRequest('PATCH', this.domain + `/protected/${params.country}/sellers/${params.companyId}/outlets/${params.outletId}/articles/prices`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<ArticlePriceList>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.UpdateArticlesSurchargeParams` containing the following parameters:
   *
   * - `surcharge`: The seller's surcharge value for articles.
   *
   * - `outletId`: A valid Outlet ID from a seller, GS ID
   *
   * - `country`: The localization country of the shop.
   *
   * - `companyId`: A valid company ID from a seller, GC ID
   *
   * - `applicationName`: A valid client application identifier
   *
   * - `Authorization`: Authorization header; see security definitions
   *
   * - `trackingId`: An optional ID for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `lang`: The localization language of the requester.
   *
   * - `correlationId`: An optional ID for correlating multiple requests for a business case
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * @return OK
   */

  public updateArticlesSurcharge(params: UpdateArticlesSurchargeParams): Observable<ShopSurcharge> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;
    requestBody = params.surcharge;



    if (params.applicationName != null) requestHeaders = requestHeaders.set('applicationName', params.applicationName.toString());
    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.trackingId != null) requestHeaders = requestHeaders.set('trackingId', params.trackingId.toString());
    if (params.lang != null) requestParams = requestParams.set('lang', params.lang.toString());
    if (params.correlationId != null) requestHeaders = requestHeaders.set('correlationId', params.correlationId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());

    const request = new HttpRequest('PATCH', this.domain + `/protected/${params.country}/sellers/${params.companyId}/outlets/${params.outletId}/articles/surcharge`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<ShopSurcharge>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.FindArticlesParams` containing the following parameters:
   *
   * - `query`: A list of articles product codes prefix to search for.
   *
   * - `outletId`: A valid Outlet ID from a seller, GS ID
   *
   * - `country`: The localization country of the shop.
   *
   * - `companyId`: A valid company ID from a seller, GC ID
   *
   * - `applicationName`: A valid client application identifier
   *
   * - `Authorization`: Authorization header; see security definitions
   *
   * - `trackingId`: An optional ID for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `priceType`: Price type indicates if the prices is calculated based on the surchage or customized by the seller or the original MSRP price. If omitted all prices type must be considered.
   *
   * - `priceScope`: Price scopes indicates the coverage of the price which can be net or gross.
   *
   * - `pageSize`: Quantity of elements in one page
   *
   * - `page`: A page in case the client supports pagination. At the moment no client side control is provided for defining the item count per page.
   *
   * - `lang`: The localization language of the requester.
   *
   * - `correlationId`: An optional ID for correlating multiple requests for a business case
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * @return OK
   */

  public findArticles(params: FindArticlesParams): Observable<SellerPriceListContainer> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;
    (params.query || []).forEach((val, index) => {if (val != null) requestParams = requestParams.append('query', val.toString())});



    if (params.applicationName != null) requestHeaders = requestHeaders.set('applicationName', params.applicationName.toString());
    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.trackingId != null) requestHeaders = requestHeaders.set('trackingId', params.trackingId.toString());
    if (params.priceType != null) requestParams = requestParams.set('priceType', params.priceType.toString());
    if (params.priceScope != null) requestParams = requestParams.set('priceScope', params.priceScope.toString());
    if (params.pageSize != null) requestParams = requestParams.set('pageSize', params.pageSize.toString());
    if (params.page != null) requestParams = requestParams.set('page', params.page.toString());
    if (params.lang != null) requestParams = requestParams.set('lang', params.lang.toString());
    if (params.correlationId != null) requestHeaders = requestHeaders.set('correlationId', params.correlationId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());

    const request = new HttpRequest('GET', this.domain + `/protected/${params.country}/sellers/${params.companyId}/outlets/${params.outletId}/articles/search`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<SellerPriceListContainer>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.GetShippingCostParams` containing the following parameters:
   *
   * - `outletId`: A valid Outlet ID from a seller, GS ID
   *
   * - `country`: The localization country of the shop.
   *
   * - `companyId`: A valid company ID from a seller, GC ID
   *
   * - `applicationName`: A valid client application identifier
   *
   * - `Authorization`: Authorization header; see security definitions
   *
   * - `trackingId`: An optional ID for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `lang`: The localization language of the requester.
   *
   * - `correlationId`: An optional ID for correlating multiple requests for a business case
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * @return OK
   */

  public getShippingCost(params: GetShippingCostParams): Observable<SellerDeliveryModes> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;



    if (params.applicationName != null) requestHeaders = requestHeaders.set('applicationName', params.applicationName.toString());
    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.trackingId != null) requestHeaders = requestHeaders.set('trackingId', params.trackingId.toString());
    if (params.lang != null) requestParams = requestParams.set('lang', params.lang.toString());
    if (params.correlationId != null) requestHeaders = requestHeaders.set('correlationId', params.correlationId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());

    const request = new HttpRequest('GET', this.domain + `/protected/${params.country}/sellers/${params.companyId}/outlets/${params.outletId}/shippingcosts`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<SellerDeliveryModes>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.UpdateShippingCostsParams` containing the following parameters:
   *
   * - `shippingClass`: The seller's delivery modes to be updated
   *
   * - `outletId`: A valid Outlet ID from a seller, GS ID
   *
   * - `country`: The localization country of the shop.
   *
   * - `companyId`: A valid company ID from a seller, GC ID
   *
   * - `applicationName`: A valid client application identifier
   *
   * - `Authorization`: Authorization header; see security definitions
   *
   * - `trackingId`: An optional ID for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `lang`: The localization language of the requester.
   *
   * - `correlationId`: An optional ID for correlating multiple requests for a business case
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * @return OK
   */

  public updateShippingCosts(params: UpdateShippingCostsParams): Observable<SellerShippingClassUpdate> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;
    requestBody = params.shippingClass;



    if (params.applicationName != null) requestHeaders = requestHeaders.set('applicationName', params.applicationName.toString());
    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.trackingId != null) requestHeaders = requestHeaders.set('trackingId', params.trackingId.toString());
    if (params.lang != null) requestParams = requestParams.set('lang', params.lang.toString());
    if (params.correlationId != null) requestHeaders = requestHeaders.set('correlationId', params.correlationId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());

    const request = new HttpRequest('PATCH', this.domain + `/protected/${params.country}/sellers/${params.companyId}/outlets/${params.outletId}/shippingcosts`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<SellerShippingClassUpdate>) => response.body)
      );
  }
}

export interface GetArticlesPricesParams {
  outletId: string;
  country: string;
  companyId: string;
  applicationName: string;
  Authorization: string;
  trackingId?: string;
  priceType?: string;
  priceScope?: 'NET' | 'GROSS';
  pageSize?: number;
  page?: number;
  lang?: string;
  correlationId?: string;
  Timestamp?: string;
}

export interface UpdateArticlesPricesParams {
  outletId: string;
  country: string;
  companyId: string;
  articles: ArticlePriceList;
  applicationName: string;
  Authorization: string;
  trackingId?: string;
  lang?: string;
  correlationId?: string;
  Timestamp?: string;
}

export interface UpdateArticlesSurchargeParams {
  surcharge: ShopSurcharge;
  outletId: string;
  country: string;
  companyId: string;
  applicationName: string;
  Authorization: string;
  trackingId?: string;
  lang?: string;
  correlationId?: string;
  Timestamp?: string;
}

export interface FindArticlesParams {
  query: Array<string>;
  outletId: string;
  country: string;
  companyId: string;
  applicationName: string;
  Authorization: string;
  trackingId?: string;
  priceType?: string;
  priceScope?: 'NET' | 'GROSS';
  pageSize?: number;
  page?: number;
  lang?: string;
  correlationId?: string;
  Timestamp?: string;
}

export interface GetShippingCostParams {
  outletId: string;
  country: string;
  companyId: string;
  applicationName: string;
  Authorization: string;
  trackingId?: string;
  lang?: string;
  correlationId?: string;
  Timestamp?: string;
}

export interface UpdateShippingCostsParams {
  shippingClass: SellerShippingClassUpdate;
  outletId: string;
  country: string;
  companyId: string;
  applicationName: string;
  Authorization: string;
  trackingId?: string;
  lang?: string;
  correlationId?: string;
  Timestamp?: string;
}

