import { Pipe, PipeTransform } from '@angular/core';
import { format, setDate, setMonth, setYear } from 'date-fns';

const FALLBACK_DATE_FORMAT = 'dd.MM.yyyy';

@Pipe({
  name: 'sssDate',
})
export class DatePipe implements PipeTransform {
  public transform(value: string | number | Array<number>, dateFormat: string = FALLBACK_DATE_FORMAT): string {
    if (!value) {
      return '';
    }

    // TODO: this should be removed!?
    if (Array.isArray(value) && value.length === 3) {
      // note: date comes in as [2018, 5, 17]
      let date = new Date();
      date = setYear(date, value[ 0 ]);
      date = setMonth(date, (value[ 1 ] - 1)); // January is month 0, December is month 11
      date = setDate(date, value[ 2 ]);
      return format(date, dateFormat);
    } else {
      return format(new Date(value as string | number), dateFormat);
    }
  }
}
