import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sss-loader',
  styleUrls: [ './loader.component.scss' ],
  template: '', // we don't need markup here
  host: { class: 'sss-loader' },

})
export class LoaderComponent {
}
