import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { CurrentStateService } from '@core/store/current-state.service';
import { RootState } from '@core/store/root.state';
import * as SessionSelectors from '@core/store/session/session.selectors';
import { Market, Outlet } from '@core/types/types';
import { CountryCode } from '@generated/sssd-v3/models';
import { Store } from '@ngrx/store';
import { SELECTOR_CONFIG, SelectorConfig } from './company-outlet-selector.config';
import * as CompanyOutletSelector2Actions from './store/company-outlet-selector-2.actions';
import * as CompanyOutletSelector2Selectors from './store/company-outlet-selector-2.selectors';
import { CompanyOutletSelectorFilter } from './store/company-outlet-selector-2.state';
@Component({
  selector: 'sss-company-outlet-selector-2',
  templateUrl: './company-outlet-selector-2.component.html',
  styleUrls: [ './company-outlet-selector-2.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'sss-company-outlet-selector-2' },
})
export class CompanyOutletSelector2Component implements OnInit {
  public filter: CompanyOutletSelectorFilter;
  public config: SelectorConfig;
  public disabled = false;
  public countriesWithPermission: CountryCode[];

  constructor(
    private readonly store: Store<RootState>,
    private readonly currentStateService: CurrentStateService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    this.countriesWithPermission = this.currentStateService.get<CountryCode[]>(AuthSelectors.countriesWithPermissions);
    const commerceDomain = this.currentStateService.get(SessionSelectors.commerceDomain);
    const sellerCenterDomain = this.currentStateService.get(SessionSelectors.sellerCenterDomain);
    const url = this.router.url.replace(/\?.*/g, '');

    this.store
      .select(CompanyOutletSelector2Selectors.disabled)
      .subscribe((isDisabled) => {
        this.disabled = isDisabled;
        this.changeDetectorRef.markForCheck();
      });

    this.config = SELECTOR_CONFIG[url];

    this.filter = {
      commerceDomain,
      sellerCenterDomain,
    };
  }

  public selectMarket(id: string): void {
    this.store.dispatch(new CompanyOutletSelector2Actions.SelectOutletAction(null));
    this.store.dispatch(new CompanyOutletSelector2Actions.SelectCompanyAction(null));

    const market: Market = { marketId: id };

    this.filter = {
      ...this.filter,
      market: id,
      company: null,
      outlet: null,
    };

    this.store.dispatch(new CompanyOutletSelector2Actions.SelectMarketAction(market));

    this.changeDetectorRef.markForCheck();
  }

  public selectCompany(id: string): void {
    this.store.dispatch(new CompanyOutletSelector2Actions.SelectOutletAction(null));
    this.store.dispatch(new CompanyOutletSelector2Actions.SelectMarketAction(null));

    const companies = this.currentStateService.get(AuthSelectors.allCompanies);
    const company = companies.find(c => c.companyId === id);

    this.filter = {
      ...this.filter,
      market: null,
      company: null,
      outlet: null,
    };

    this.store.dispatch(new CompanyOutletSelector2Actions.SelectCompanyAction(company));

    this.filter = {
      ...this.filter,
      country: company.countryCode,
      company: company.companyId,
    };

    this.changeDetectorRef.markForCheck();
  }

  public get marketSelectionAvailable(): boolean {
    return this.config?.selectorMarket && this.countriesWithPermission.length > 0;
  }

  public selectOutlet(outlet: Outlet): void {
    this.store.dispatch(new CompanyOutletSelector2Actions.SelectOutletAction(outlet));
  }
}
