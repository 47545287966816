import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { VehicleGroup } from '../models/vehicle-group';
import { SellerProductPriceContainer } from '../models/seller-product-price-container';
import { Seller } from '../models/seller';
import { SellerProductPrice } from '../models/seller-product-price';
import { SellerProductPriceList } from '../models/seller-product-price-list';
import { BaseProduct } from '../models/base-product';


/**
 * Protected endpoints requiring validation while using the validation service.
 */

@Injectable()
export class ProtectedService {
  private domain: string;

  constructor(
    private httpClient: HttpClient,
  ) {}

  public setDomain(domain: string): void {
    this.domain = domain;
  }


  /**
   * @param params The `ProtectedService.GetVehicleGroupsParams` containing the following parameters:
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `Authorization`: Authorization header; see security definitons
   *
   * - `ApplicationName`: A valid client application identifier
   *
   * - `TrackingId`: An optional ID for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * - `CorrelationId`: An optional ID for correlating multiple requests for a business case
   *
   * - `Accept-Language`: The localization language of the requester.
   *
   * @return OK
   */

  public getVehicleGroups(params: GetVehicleGroupsParams): Observable<Array<VehicleGroup>> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;

    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());
    if (params.AcceptLanguage != null) requestHeaders = requestHeaders.set('Accept-Language', params.AcceptLanguage.toString());

    const request = new HttpRequest('GET', this.domain + `/protected/${params.country}/vehiclegroups`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<Array<VehicleGroup>>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.GetServiceContractProductsParams` containing the following parameters:
   *
   * - `outletId`: A valid Outlet ID from a seller, GS ID
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `companyId`: A valid company ID from a seller, GC ID
   *
   * - `Authorization`: Authorization header; see security definitons
   *
   * - `ApplicationName`: A valid client application identifier
   *
   * - `vehiclegroupId`: A valid vehicle group Id
   *
   * - `productCode`: A valid product code as defined for service contracts based products.
   *
   * - `TrackingId`: An optional ID for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * - `CorrelationId`: An optional ID for correlating multiple requests for a business case
   *
   * - `Accept-Language`: The localization language of the requester.
   *
   * @return OK
   */

  public getServiceContractProducts(params: GetServiceContractProductsParams): Observable<SellerProductPriceContainer> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;



    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.vehiclegroupId != null) requestParams = requestParams.set('vehiclegroupId', params.vehiclegroupId.toString());
    if (params.productCode != null) requestParams = requestParams.set('productCode', params.productCode.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());
    if (params.AcceptLanguage != null) requestHeaders = requestHeaders.set('Accept-Language', params.AcceptLanguage.toString());

    const request = new HttpRequest('GET', this.domain + `/protected/${params.country}/sellers/${params.companyId}/outlets/${params.outletId}/products`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<SellerProductPriceContainer>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.GetSellerParams` containing the following parameters:
   *
   * - `outletId`: A valid Outlet ID from a seller, GS ID
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `companyId`: A valid company ID from a seller, GC ID
   *
   * - `Authorization`: Authorization header; see security definitons
   *
   * - `ApplicationName`: A valid client application identifier
   *
   * - `TrackingId`: An optional ID for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * - `CorrelationId`: An optional ID for correlating multiple requests for a business case
   *
   * @return OK
   */

  public getSeller(params: GetSellerParams): Observable<Seller> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;



    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());

    const request = new HttpRequest('GET', this.domain + `/protected/${params.country}/sellers/${params.companyId}/outlets/${params.outletId}`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<Seller>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.PatchSellerParams` containing the following parameters:
   *
   * - `outletId`: A valid Outlet ID from a seller, GS ID
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `companyId`: A valid company ID from a seller, GC ID
   *
   * - `Authorization`: Authorization header; see security definitons
   *
   * - `ApplicationName`: A valid client application identifier
   *
   * - `seller`: The seller's information
   *
   * - `TrackingId`: An optional ID for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * - `CorrelationId`: An optional ID for correlating multiple requests for a business case
   *
   * @return OK
   */

  public patchSeller(params: PatchSellerParams): Observable<Seller> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;



    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    requestBody = params.seller;
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());

    const request = new HttpRequest('PATCH', this.domain + `/protected/${params.country}/sellers/${params.companyId}/outlets/${params.outletId}`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<Seller>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.UpdateServiceContractSellerProductsPriceParams` containing the following parameters:
   *
   * - `userName`: The user who performs the action.
   *
   * - `products`:
   *
   * - `outletId`: A valid Outlet ID from a seller, GS ID
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `companyId`: A valid company ID from a seller, GC ID
   *
   * - `Authorization`: Authorization header; see security definitons
   *
   * - `ApplicationName`: A valid client application identifier
   *
   * - `TrackingId`: An optional ID for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * - `CorrelationId`: An optional ID for correlating multiple requests for a business case
   *
   * @return OK
   */

  public updateServiceContractSellerProductsPrice(params: UpdateServiceContractSellerProductsPriceParams): Observable<Array<SellerProductPrice>> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;
    if (params.userName != null) requestHeaders = requestHeaders.set('userName', params.userName.toString());
    requestBody = params.products;



    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());

    const request = new HttpRequest('PATCH', this.domain + `/protected/${params.country}/sellers/${params.companyId}/outlets/${params.outletId}/products/prices`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<Array<SellerProductPrice>>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.DeleteServiceContractSellerProductPriceParams` containing the following parameters:
   *
   * - `userName`: The user who performs the action.
   *
   * - `product`:
   *
   * - `outletId`: A valid Outlet ID from a seller, GS ID
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `companyId`: A valid company ID from a seller, GC ID
   *
   * - `Authorization`: Authorization header; see security definitons
   *
   * - `ApplicationName`: A valid client application identifier
   *
   * - `TrackingId`: An optional ID for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * - `CorrelationId`: An optional ID for correlating multiple requests for a business case
   */

  public deleteServiceContractSellerProductPrice(params: DeleteServiceContractSellerProductPriceParams): Observable<null> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;
    if (params.userName != null) requestHeaders = requestHeaders.set('userName', params.userName.toString());
    requestBody = params.product;



    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());

    const request = new HttpRequest('DELETE', this.domain + `/protected/${params.country}/sellers/${params.companyId}/outlets/${params.outletId}/products/prices`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<null>) => response.body)
      );
  }


  /**
   * @param params The `ProtectedService.GetServiceContractBaseProductsParams` containing the following parameters:
   *
   * - `country`: A specified country (ISO 3166-1). The country of the Seller's shop.
   *
   * - `Authorization`: Authorization header; see security definitons
   *
   * - `ApplicationName`: A valid client application identifier
   *
   * - `productCode`: A valid product code as defined for service contracts based products.
   *
   * - `level`: The level of service contract product: BASE (returns only base products), variant (returns base products and product variants), product (returns all level including products with MSRP prices). Default value is level=BASE
   *
   * - `TrackingId`: An optional ID for end-to-end business monitoring for identifying request through multiple systems/applications
   *
   * - `Timestamp`: Timestamp in RFC 3339 format
   *
   * - `CorrelationId`: An optional ID for correlating multiple requests for a business case
   *
   * - `Accept-Language`: The localization language of the requester.
   *
   * @return OK
   */

  public getServiceContractBaseProducts(params: GetServiceContractBaseProductsParams): Observable<Array<BaseProduct>> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();
    let requestBody: any = null;

    if (params.Authorization != null) requestHeaders = requestHeaders.set('Authorization', params.Authorization.toString());
    if (params.ApplicationName != null) requestHeaders = requestHeaders.set('ApplicationName', params.ApplicationName.toString());
    if (params.productCode != null) requestParams = requestParams.set('productCode', params.productCode.toString());
    if (params.level != null) requestParams = requestParams.set('level', params.level.toString());
    if (params.TrackingId != null) requestHeaders = requestHeaders.set('TrackingId', params.TrackingId.toString());
    if (params.Timestamp != null) requestHeaders = requestHeaders.set('Timestamp', params.Timestamp.toString());
    if (params.CorrelationId != null) requestHeaders = requestHeaders.set('CorrelationId', params.CorrelationId.toString());
    if (params.AcceptLanguage != null) requestHeaders = requestHeaders.set('Accept-Language', params.AcceptLanguage.toString());

    const request = new HttpRequest('GET', this.domain + `/protected/${params.country}/baseproducts`, requestBody, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json',
    });

    return this.httpClient.request(request)
      .pipe(
        filter((response: any) => Boolean(response instanceof HttpResponse)),
        map((response: HttpResponse<Array<BaseProduct>>) => response.body)
      );
  }
}

export interface GetVehicleGroupsParams {
  country: string;
  Authorization: string;
  ApplicationName: string;
  TrackingId?: string;
  Timestamp?: string;
  CorrelationId?: string;
  AcceptLanguage?: string;
}

export interface GetServiceContractProductsParams {
  outletId: string;
  country: string;
  companyId: string;
  Authorization: string;
  ApplicationName: string;
  vehiclegroupId?: string;
  productCode?: string;
  TrackingId?: string;
  Timestamp?: string;
  CorrelationId?: string;
  AcceptLanguage?: string;
}

export interface GetSellerParams {
  outletId: string;
  country: string;
  companyId: string;
  Authorization: string;
  ApplicationName: string;
  TrackingId?: string;
  Timestamp?: string;
  CorrelationId?: string;
}

export interface PatchSellerParams {
  outletId: string;
  country: string;
  companyId: string;
  Authorization: string;
  ApplicationName: string;
  seller?: Seller;
  TrackingId?: string;
  Timestamp?: string;
  CorrelationId?: string;
}

export interface UpdateServiceContractSellerProductsPriceParams {
  userName: string;
  products: SellerProductPriceList;
  outletId: string;
  country: string;
  companyId: string;
  Authorization: string;
  ApplicationName: string;
  TrackingId?: string;
  Timestamp?: string;
  CorrelationId?: string;
}

export interface DeleteServiceContractSellerProductPriceParams {
  userName: string;
  product: {productId: string};
  outletId: string;
  country: string;
  companyId: string;
  Authorization: string;
  ApplicationName: string;
  TrackingId?: string;
  Timestamp?: string;
  CorrelationId?: string;
}

export interface GetServiceContractBaseProductsParams {
  country: string;
  Authorization: string;
  ApplicationName: string;
  productCode?: string;
  level?: 'BASE' | 'VARIANT' | 'PRODUCT';
  TrackingId?: string;
  Timestamp?: string;
  CorrelationId?: string;
  AcceptLanguage?: string;
}

