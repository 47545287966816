export function isNotNullUndefinedEmpty(value: string): boolean {
  return value !== null && value !== undefined && value !== '';
}

export function isNotNullUndefined(value: any): boolean {
  return value !== null && value !== undefined;
}

export function isNotUndefined(value: any): boolean {
  return typeof value !== 'undefined';
}

export function isValidNumber(value: number): boolean {
  return (typeof value === 'number' && isNaN(value) === false);
}
