import { Component } from '@angular/core';
import { AppRoute } from '@app/app-routes.enum';

@Component({
  selector: 'sss-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [ './sidebar.component.scss' ],
})
export class SidebarComponent {
  public readonly Route: typeof AppRoute = AppRoute;
}
