import { Async } from '@core/store/async.interfaces';
import { State } from '@core/store/root.state';
import { SellerPriceListContainer } from '@generated/sssa/models/seller-price-list-container';
import { ShippingClass } from '@generated/sssa/models/shipping-class';

export interface PricingAccessoriesState extends State {
  articles: Async<SellerPriceListContainer>;
  shippingClasses: Async<Array<ShippingClass>>;
}

export const PRICING_ACCESSORIES_INITIAL_STATE: PricingAccessoriesState = {
  articles: {
    error: null,
    isPending: false,
    data: null,
  },
  shippingClasses: {
    error: null,
    isPending: false,
    data: [],
  },
};
