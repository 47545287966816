import { SellerCenterShopName } from '@core/config/global.config';
import { RootState } from '@core/store/root.state';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { ShopOutletSelectorState } from './shop-outlet-selector.state';
import { Outlet } from '@core/types/types';

const selectFeature = (state: RootState): ShopOutletSelectorState => state.shopOutletSelector;

export const shopOutletSelectorState: MemoizedSelector<RootState, ShopOutletSelectorState> = createSelector(selectFeature, (state: ShopOutletSelectorState) => state);
export const isVisible: MemoizedSelector<RootState, boolean> = createSelector(selectFeature, (state: ShopOutletSelectorState) => state.isVisible);
export const selectedShopHasOutlets: MemoizedSelector<RootState, boolean> = createSelector(selectFeature, (state: ShopOutletSelectorState) => state.selectedShopHasOutlets);
export const selectedShop: MemoizedSelector<RootState, SellerCenterShopName> = createSelector(selectFeature, (state: ShopOutletSelectorState) => state.selectedShop);
export const selectedOutlet: MemoizedSelector<RootState, Outlet> = createSelector(selectFeature, (state: ShopOutletSelectorState) => state.selectedOutlet);
export const outletId: MemoizedSelector<RootState, string> = createSelector(selectFeature, (state: ShopOutletSelectorState) => state.selectedOutlet && state.selectedOutlet.outletId);
export const availableShopsFilter: MemoizedSelector<RootState, Array<SellerCenterShopName>> = createSelector(selectFeature, (state: ShopOutletSelectorState) => state.availableShopsFilter);
