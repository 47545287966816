/* tslint:disable */

/**
 * The responsible for setting the price
 */
export enum PriceType {
  BASE = 'BASE',
  SELLER = 'SELLER',
  SURCHARGE = 'SURCHARGE'
}
