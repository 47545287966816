import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Inject, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isBool, Value } from '@trademe/ensure';
import { fromEvent, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { Key } from 'ts-keycode-enum';
import { v4 as uuid } from 'uuid';
import { ComboBoxOption } from './combo-box-option.class';

export const NO_OPTIONS_BUTTON_TEXT = '-';

const CLASS_DARK_MODE = 'sss-combo-box--dark';
const EXPANDED_CONTAINER_CLASS_DARK_MODE = 'sss-combo-box--expanded';

const CLASS_LIGHT_MODE = 'sss-combo-box--light';
const EXPANDED_CONTAINER_CLASS_LIGHT_MODE = 'sss-combo-box--expanded';

// TODO https://jira.mercedes-benz.io/browse/DCPSSS-2992
// light mode has to be implemented. currently it's possible to use the component in light mode but that has not been tested.
// autoSelect and autoExpand inputs are available, but have not been tested and are currently not used.
// tests have to be added

@Component({
  selector: 'sss-combo-box',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './combo-box.component.html',
  styleUrls: [ './combo-box.component.scss' ],
  host: { class: 'sss-combo-box' },

})
export class ComboBoxComponent implements AfterViewInit, OnChanges, OnDestroy {
  @HostBinding('class.' + CLASS_LIGHT_MODE)
  public get isLightMode(): boolean {
    return !this.darkMode;
  }

  @HostBinding('class.' + CLASS_DARK_MODE)
  public get isDarkMode(): boolean {
    return this.darkMode;
  }

  @HostBinding('class.' + EXPANDED_CONTAINER_CLASS_LIGHT_MODE)
  public get isExpandedLightMode(): boolean {
    return !this.darkMode && this.expanded;
  }

  @HostBinding('class.' + EXPANDED_CONTAINER_CLASS_DARK_MODE)
  public get isExpandedDarkMode(): boolean {
    return this.darkMode && this.expanded;
  }

  @Input()
  public defaultText = '';

  @Input()
  public label = '';

  @Input()
  public inlineLabel = '';

  @Input()
  public options: Array<ComboBoxOption> = [];

  @Input()
  @Value(isBool)
  public inline = false;

  @Input()
  @Value(isBool)
  public darkMode = false;

  @Input()
  @Value(isBool)
  @HostBinding('class.sss-combo-box--disabled')
  public disabled = false;

  @Input()
  @Value(isBool)
  public autoExpand = false;

  @Input()
  @Value(isBool)
  public autoSelect = false;

  @Input()
  public selection: string = null;

  @Output()
  public selectionChange: EventEmitter<string> = new EventEmitter();

  @ViewChild('input', { static: true })
  public inputElRef: ElementRef;

  public expanded = false;
  public buttonText = '';
  public selectId: string = 'sss-combobox-' + uuid();
  public displayDropdown = false; // IE11 fix. This makes sure that no gray box is displayed
  public filteredOptions: Array<ComboBoxOption> = [];

  private subscription: Subscription;
  private filterValue = '';
  private latestSelectedOption: ComboBoxOption = null;

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  @HostListener('window:resize')
  public close(): void {
    this.expanded = false;
    this.changeDetectorRef.markForCheck();
  }

  public ngAfterViewInit(): void {
    this.subscription = fromEvent(this.inputElRef.nativeElement, 'keyup')
      .pipe(
        distinctUntilChanged(),
        filter((event: KeyboardEvent) => event.keyCode !== Key.Escape), // escape is treated somewhere else
      )
      .subscribe((event: KeyboardEvent) => {
        this.filterValue = (event.target as any).value;
        this.updateFilteredOptions();
        this.open();
      });

    // If the dropdown comes pre-selected
    const selectedOption = this.options && this.findSelection(this.options, this.selection);
    this.latestSelectedOption = selectedOption;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const { selection, options, defaultText } = changes;
    const currentSelection = selection ? selection.currentValue : this.selection;
    const currentOptions = options ? options.currentValue : this.options;
    const currentDefaultText = defaultText ? defaultText.currentValue : this.defaultText;
    this.updateState(currentSelection, currentOptions, currentDefaultText);

    if (options) {
      this.updateFilteredOptions();
    }
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public onInputClick(event: MouseEvent): void {
    this.toggleSelectList();
    this.hideDefaultText();
    this.showAndSelectFilterText(event);
  }

  public onNativeSelectionChange(optionId: string): void {
    const selectedOption = this.findSelection(this.options, optionId);

    if (selectedOption) {
      this.selectOption(selectedOption);
    }
  }

  public selectOption(option: ComboBoxOption, event?: Event): void {
    if (event) {
      event.stopPropagation();
    }

    this.latestSelectedOption = option;
    this.selection = option.id;
    this.selectionChange.emit(this.selection);
    this.setButtonText(option.value);
    this.close();
  }

  public toggleSelectList(): void {
    if (this.expanded) {
      this.close();
    } else {
      this.open();
    }
  }

  public hideDefaultText(): void {
    this.setButtonText('');
    this.changeDetectorRef.markForCheck();
  }

  public restoreSelectionOrDefault(event: Event): void {
    event.stopPropagation();

    const text = this.latestSelectedOption
      ? this.latestSelectedOption.value
      : this.defaultText;

    this.setButtonText(text);
    this.close();

    if (event && event.target) {
      (event.target as any).blur();
    }
  }

  public showAndSelectFilterText(event: MouseEvent): void {
    if (!this.filterValue) {
      return;
    }

    this.setButtonText(this.filterValue);

    // note: timeout needed here because of reasons
    setTimeout(() => {
      if (event && event.target) {
        (event.target as any).select();
      }
    });
  }

  public open(): void {
    if (this.expanded) {
      return;
    }

    this.expanded = true;

    // NOTE: the timeout fixes the following issue
    // link click -> route load -> dropdown auto opens -> dropdown immediately closes again
    setTimeout(() => this.closeOnOutsideClick());
  }

  /**
   * Returns the option with the required Id
   */
  private findSelection(options: Array<ComboBoxOption>, optionId: string): ComboBoxOption {
    return options.find((option: ComboBoxOption) => option.id === optionId);
  }

  private closeOnOutsideClick(): void {
    fromEvent(this.document, 'click')
      .pipe(take(2)) // If the user opens and closes the dropdown we still need to consider the second click
      .subscribe((event: Event) => {
        const hasClickedInsideDropdown = this.elementRef.nativeElement.contains(event.target);

        if (!hasClickedInsideDropdown) {
          this.restoreSelectionOrDefault(event);
        }
      });
  }

  private setButtonText(text: string): void {
    this.buttonText = text;
  }

  private updateState(selection: string, options: Array<ComboBoxOption>, defaultText: string): void {
    if (!selection && options) {
      if (this.autoSelect && options.length === 1) {
        this.selectOption(options[ 0 ]);
        return;
      }

      if (this.autoExpand && options.length > 0) {
        this.open();
      }
    }

    if (!options || !options.length) {
      this.setButtonText(NO_OPTIONS_BUTTON_TEXT);
      return;
    }

    if (!selection) {
      this.setButtonText(defaultText);
      return;
    }

    const matchingOption: ComboBoxOption = options.find((option: ComboBoxOption) => option.id === selection);
    this.setButtonText(matchingOption ? matchingOption.value : defaultText);
  }

  private filterOptions(options: Array<ComboBoxOption> = [], filterValue: string): Array<ComboBoxOption> {
    return !filterValue
      ? options
      : options
        .filter((comboBoxOption: ComboBoxOption) => comboBoxOption.value.toUpperCase().includes(this.filterValue.toUpperCase()));
  }

  private updateFilteredOptions(): void {
    this.filteredOptions = this.filterOptions(this.options, this.filterValue);
    this.displayDropdown = this.filteredOptions && this.filteredOptions.length > 0;
    this.changeDetectorRef.markForCheck();
  }
}
