import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DropdownModule } from '@shared/components/dropdown/dropdown.module';
import { DropdowMarketComponent } from './dropdown-market.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';

@NgModule({
  declarations: [
    DropdowMarketComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
    DropdownModule,
  ],
  exports: [
    DropdowMarketComponent,
  ],
})
export class DropdownMarketModule { }
