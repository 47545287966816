import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CurrentStateService } from '@core/store/current-state.service';
import { AppRoute } from '../../app-routes.enum';
import * as AuthSelectors from '../store/auth/auth.selectors';

@Injectable()
export class CompanyManagerGuard implements CanActivate {
  constructor(
    private router: Router,
    private currentStateService: CurrentStateService,
  ) {}

  public canActivate(): boolean {
    const { OUTLET_EDITOR } = this.currentStateService.get(AuthSelectors.userPermissions);

    if (!OUTLET_EDITOR) {
      this.redirectToDashboard();
    }

    return OUTLET_EDITOR;
  }

  private redirectToDashboard(): void {
    this.router.navigate([ AppRoute.DASHBOARD ]);
  }
}
