import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CompanyOutletSelector2Module } from '@core/company-outlet-selector-2/company-outlet-selector-2.module';
import { ShopOutletSelectorModule } from '@core/shop-outlet-selector/shop-outlet-selector.module';
import { BadgeModule } from '@shared/components/badge/badge.module';
import { StickyModule } from '@shared/components/sticky/sticky.module';
import { LanguageSwitcherModalModule } from '../language-switcher-modal/language-switcher-modal.module';
import { HeaderMainComponent } from './header-main/header-main.component';
import { HeaderTopComponent } from './header-top/header-top.component';
import { HeaderComponent } from './header.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';
@NgModule({
  declarations: [
    HeaderComponent,
    HeaderTopComponent,
    HeaderMainComponent,
  ],
  exports: [
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipesModule,
    BadgeModule,
    LanguageSwitcherModalModule,
    ShopOutletSelectorModule,
    StickyModule,
    CompanyOutletSelector2Module,
  ],
  providers: [ ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class HeaderModule {}
