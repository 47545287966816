import { HttpErrorResponse } from '@angular/common/http';
import { CountryCode } from '@core/types/types';
import { BaseProduct } from '@generated/sssg/models/base-product';
import { SellerProductPrice } from '@generated/sssg/models/seller-product-price';
import { SellerProductPriceList } from '@generated/sssg/models/seller-product-price-list';
import { VehicleGroup } from '@generated/sssg/models/vehicle-group';
import { Action } from '@ngrx/store';
import { CompanyId, OutletId } from '@core/types/profile.types';

export const FETCH_PRODUCTS_REQUEST = '[GPme] FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = '[GPme] FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = '[GPme] FETCH_PRODUCTS_FAILURE';

export const UPDATE_PRODUCT_PRICE_REQUEST = '[GPme] UPDATE_PRODUCT_PRICE_REQUEST';
export const UPDATE_PRODUCT_PRICE_SUCCESS = '[GPme] UPDATE_PRODUCT_PRICE_SUCCESS';
export const UPDATE_PRODUCT_PRICE_FAILURE = '[GPme] UPDATE_PRODUCT_PRICE_FAILURE';

export const DELETE_PRODUCT_PRICE_REQUEST = '[GPme] DELETE_PRODUCT_PRICE_REQUEST';
export const DELETE_PRODUCT_PRICE_SUCCESS = '[GPme] DELETE_PRODUCT_PRICE_SUCCESS';
export const DELETE_PRODUCT_PRICE_FAILURE = '[GPme] DELETE_PRODUCT_PRICE_FAILURE';

export const FETCH_BASE_PRODUCTS_REQUEST = '[GPme] FETCH_BASE_PRODUCTS_REQUEST';
export const FETCH_BASE_PRODUCTS_SUCCESS = '[GPme] FETCH_BASE_PRODUCTS_SUCCESS';
export const FETCH_BASE_PRODUCTS_FAILURE = '[GPme] FETCH_BASE_PRODUCTS_FAILURE';

export const FETCH_VEHICLE_GROUPS_REQUEST = '[GPme] FETCH_VEHICLE_GROUPS_REQUEST';
export const FETCH_VEHICLE_GROUPS_SUCCESS = '[GPme] FETCH_VEHICLE_GROUPS_SUCCESS';
export const FETCH_VEHICLE_GROUPS_FAILURE = '[GPme] FETCH_VEHICLE_GROUPS_FAILURE';

export class FetchProductsRequestAction implements Action {
  readonly type = FETCH_PRODUCTS_REQUEST;

  constructor(
    public readonly country: CountryCode,
    public readonly companyId: CompanyId,
    public readonly outletId: OutletId,
    public readonly productCodeFilter?: string,
    public readonly vehicleGroupIdFilter?: string,
  ) {}
}
export class FetchProductsSuccessAction implements Action {
  readonly type = FETCH_PRODUCTS_SUCCESS;

  constructor(public readonly products: Array<BaseProduct>) {}
}

export class FetchProductsFailureAction implements Action {
  readonly type = FETCH_PRODUCTS_FAILURE;

  constructor(public readonly error: HttpErrorResponse) {}
}

export class UpdateProductsRequestAction implements Action {
  readonly type = UPDATE_PRODUCT_PRICE_REQUEST;

  constructor(
    public readonly country: CountryCode,
    public readonly companyId: CompanyId,
    public readonly outletId: OutletId,
    public readonly products: SellerProductPriceList,
    public readonly userName: string,
  ) {}
}

export class UpdateProductPriceSuccessAction implements Action {
  readonly type = UPDATE_PRODUCT_PRICE_SUCCESS;

  constructor(public readonly products: Array<SellerProductPrice>) {}
}

export class UpdateProductPriceFailureAction implements Action {
  readonly type = UPDATE_PRODUCT_PRICE_FAILURE;

  constructor(public readonly error: HttpErrorResponse) {}
}

export class FetchBaseProductPriceRequestAction implements Action {
  readonly type = FETCH_BASE_PRODUCTS_REQUEST;

  constructor(public readonly country: CountryCode) {}
}

export class FetchBaseProductsSuccessAction implements Action {
  readonly type = FETCH_BASE_PRODUCTS_SUCCESS;

  constructor(public readonly products: Array<BaseProduct>) {}
}

export class FetchBaseProductsFailureAction implements Action {
  readonly type = FETCH_BASE_PRODUCTS_FAILURE;

  constructor(public readonly error: HttpErrorResponse) {}
}

export class FetchVehicleGroupsRequestAction implements Action {
  readonly type = FETCH_VEHICLE_GROUPS_REQUEST;

  constructor(public readonly country: CountryCode) {}
}

export class FetchVehicleGroupsSuccessAction implements Action {
  readonly type = FETCH_VEHICLE_GROUPS_SUCCESS;

  constructor(public readonly vehicleGroups: Array<VehicleGroup>) {}
}

export class FetchVehicleGroupsFailureAction implements Action {
  readonly type = FETCH_VEHICLE_GROUPS_FAILURE;

  constructor(public readonly error: HttpErrorResponse) {}
}

export class DeleteProductPriceRequestAction implements Action {
  readonly type = DELETE_PRODUCT_PRICE_REQUEST;

  constructor(
    public readonly country: CountryCode,
    public readonly companyId: CompanyId,
    public readonly outletId: OutletId,
    public readonly productId: string,
    public readonly userName: string,
  ) {}
}
export class DeleteProductPriceSuccessAction implements Action {
  readonly type = DELETE_PRODUCT_PRICE_SUCCESS;

  constructor(public readonly productId: string) {}
}

export class DeleteProductPriceFailureAction implements Action {
  readonly type = DELETE_PRODUCT_PRICE_FAILURE;

  constructor(public readonly error: HttpErrorResponse) {}
}
