import { createSelector, MemoizedSelector } from '@ngrx/store';
import { RootState } from '../root.state';
import { SessionState } from './session.state';
import { CommerceDomain, SellerCenterDomain, LanguageCode } from '@core/config/global.config';
import { AuthState } from '../auth/auth.state';
import { UserRole } from '@core/types/user-role';

const selectFeature = (state: RootState): SessionState => state.session;
const selectAuth = (state: RootState): AuthState => state.auth;

export const sessionState: MemoizedSelector<RootState, SessionState> = createSelector(
  selectFeature, (state: SessionState) => state);

export const commerceDomain: MemoizedSelector<RootState, CommerceDomain> = createSelector(
  selectFeature, (state: SessionState) => state.commerceDomain);

export const sellerCenterDomain: MemoizedSelector<RootState, SellerCenterDomain> = createSelector(
  selectFeature, (state: SessionState) => state.sellerCenterDomain);

export const uiLanguageCode: MemoizedSelector<RootState, LanguageCode> = createSelector(
  selectFeature, (state: SessionState) => state.selectedUiLanguage);

export const uiLanguages: MemoizedSelector<RootState, LanguageCode[]> = createSelector(
  selectFeature, (state: SessionState) => state.uilanguages);

export const isReady: MemoizedSelector<RootState, boolean> = createSelector(
  selectFeature, selectAuth,
  (state: SessionState, authState: AuthState): boolean => {
    const hasSession = Boolean(authState.identity) && Boolean(authState.profile);
    const userRole = authState.userRole;
    const selectedCompany = authState.selectedCompany;
    const currentCommerceDomain = state.commerceDomain;

    let isVisible = false;

    if (!hasSession || !currentCommerceDomain) {
      return isVisible;
    }

    if (userRole === UserRole.COUNTRY_MANAGER) {
      isVisible = true;
    }

    if (userRole === UserRole.SELLER) {
      switch (currentCommerceDomain) {
        case CommerceDomain.MARKETPLACE:
          isVisible = hasSession && Boolean(currentCommerceDomain);
          break;

        case CommerceDomain.SHOPS:
          isVisible = hasSession && currentCommerceDomain && Boolean(selectedCompany);
          break;

        default:
          isVisible = false;
          break;
      }
    }

    return isVisible;
  }
);

