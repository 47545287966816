import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nService } from './i18n.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [ I18nService ],
})
export class I18nModule {
  constructor(
    i18nService: I18nService,
  ) {
    i18nService.initialize();
  }
}
