import { v4 as uuid } from 'uuid';
import { ToasterNotificationType } from './toaster-notitfication-type';

export class ToasterNotification {
  public id;

  constructor(
    public message: string,
    public notificationType: ToasterNotificationType,
    public autoDismiss: boolean,
    public autoDismissTimeoutInMs: number,
    public actionLabel?: string,
    public action?: any,
  ) {
    this.id = uuid();
  }
}
