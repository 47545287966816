import { Action } from '@ngrx/store';
import { CommerceDomain, SellerCenterDomain, LanguageCode } from '@core/config/global.config';

export enum SessionActionTypes {
  RESET_SESSION_STATE = '[Session] RESET_SESSION_STATE',
  SET_COMMERCE_DOMAIN = '[Session] SET_COMMERCE_DOMAIN',
  SET_SELLER_CENTER_DOMAIN = '[Session] SET_SELLER_CENTER_DOMAIN',
  SET_LANGUAGE = '[Session] SET_LANGUAGE',
  SET_UI_LANGUAGES = '[Session] SET_UI_LANGUAGES'
}

export class ResetSessionStateAction implements Action {
  public readonly type = SessionActionTypes.RESET_SESSION_STATE;
}

export class SetCommerceDomainAction implements Action {
  public readonly type = SessionActionTypes.SET_COMMERCE_DOMAIN;

  constructor(
    public readonly commerceDomain: CommerceDomain,
  ) {}
}

export class SetSellerCenterDomainAction implements Action {
  public readonly type = SessionActionTypes.SET_SELLER_CENTER_DOMAIN;

  constructor(
    public readonly sellerCenterDomain: SellerCenterDomain,
  ) {}
}

export class SetLanguageAction implements Action {
  public readonly type = SessionActionTypes.SET_LANGUAGE;

  constructor(
    public readonly language: LanguageCode,
  ) {}
}

export class SetUiLanguagesAction implements Action {
  public readonly type = SessionActionTypes.SET_UI_LANGUAGES;

  constructor(
    public readonly languages: LanguageCode[],
  ) {}
}

