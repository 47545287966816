import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrowserUtilService } from '@shared/services/browser-util/browser-util.service';
import { Observable } from 'rxjs';

// See https://medium.com/@tiboprea/how-to-fix-internet-explorer-caching-of-ajax-requests-using-angular-5-2c489cf2d1f7
@Injectable()
export class NoIeCacheInterceptor implements HttpInterceptor {
  private isIE = false;

  constructor(
    browserUtilService: BrowserUtilService,
  ) {
    this.isIE = browserUtilService.isIE();
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isIE && request.method === 'GET') {
      // TODO: This is how it should be done. But: we have CORS issues.
      // Request header field Pragma is not allowed by Access-Control-Allow-Headers in preflight response.

      // const customRequest = request.clone({
      //   headers: request.headers.set('Cache-Control', 'no-cache').set('Pragma', 'no-cache')
      // });
      // return next.handle(customRequest);

      // Since the solution above does currently not work for mentioned reasons, we do the following:
      const customRequest = request.clone({
        params: request.params.set('cacheBust', String(Date.now())),
      });

      return next.handle(customRequest);
    }

    return next.handle(request);
  }
}
