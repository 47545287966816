import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AppRoute } from '@app/app-routes.enum';
import { ProfileRoute } from '@app/profile/profile.config';
import { CommerceDomain, LanguageCode } from '@core/config/global.config';
import { SessionService } from '@core/services/session/session.service';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { CurrentStateService } from '@core/store/current-state.service';
import { RootState } from '@core/store/root.state';
import * as SessionSelectors from '@core/store/session/session.selectors';
import { UserRole } from '@core/types/user-role';
import { Store } from '@ngrx/store';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { headerTopConfig, TopMenuItem } from '../header.config';

export const APPLICATION_NAME_SUFFIX_BACKOFFICE = 'Backoffice';
export const APPLICATION_NAME_SUFFIX_DEFAULT = 'Center';

@Component({
  selector: 'sss-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: [ './header-top.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'sss-header-top' },
})
export class HeaderTopComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public isLoggedIn = false;

  @Input()
  public isProd = false;

  @Input()
  public userRole: UserRole;

  @Output()
  public clickedCompanySelector: EventEmitter<void> = new EventEmitter();

  public isLanguageModalOpen = false;
  public isCompanySelectorOpen = false;
  public selectedLanguageCode: LanguageCode;

  public readonly PROFILE_ROUTE = ProfileRoute;

  public environmentLabel: string;
  public userName: string;
  public hasMultipleCompanies = true;
  public applicationNameSuffix: string;

  public readonly TopMenu: typeof TopMenuItem = TopMenuItem;
  public readonly Route: typeof AppRoute = AppRoute;

  private destroy$ = new Subject();

  constructor(
    private readonly windowRefService: WindowRefService,
    private readonly store: Store<RootState>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly sessionService: SessionService,
    private readonly currentStateService: CurrentStateService,
  ) {}

  public ngOnInit(): void {
    this.environmentLabel = this.windowRefService.environment.environmentLabel;

    this.onUILanguage$();
    this.onCompanies$();
    this.onFullname$();
    this.onCommerceDomain$();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public ngOnChanges(simpleChanges: SimpleChanges): void {
    const { userRole } = simpleChanges;
    const commerceDomain = this.currentStateService.get(SessionSelectors.commerceDomain);

    if (userRole) {
      this.applicationNameSuffix = userRole.currentValue === UserRole.COUNTRY_MANAGER && commerceDomain === CommerceDomain.SHOPS
        ? APPLICATION_NAME_SUFFIX_BACKOFFICE
        : APPLICATION_NAME_SUFFIX_DEFAULT;
    }
  }

  public openLanguageModal(): void {
    this.isLanguageModalOpen = true;
  }

  public closeLanguageModal(): void {
    this.isLanguageModalOpen = false;
  }

  public emitClickCompanySelector(): void {
    this.clickedCompanySelector.emit();
  }

  public isVisible(item: TopMenuItem): boolean {
    const commerceDomain = this.currentStateService.get(SessionSelectors.commerceDomain);
    const userPermissions = this.currentStateService.get(AuthSelectors.userPermissions);

    const menuItem = headerTopConfig[item];
    const hasRoleMatch = menuItem.userRoles.includes(this.userRole);
    const hasPermissionMatch = menuItem.userPermissions.some((permission) => userPermissions && userPermissions[permission]);
    const hasCommerceDomainMatch = this.userRole === UserRole.COUNTRY_MANAGER || menuItem.commerceDomain.includes(commerceDomain);

    if (this.isProd && menuItem.hideOnProd) {
      return false;
    }

    if (!this.isLoggedIn && menuItem.visibleJustWhenLoggedIn) {
      return false;
    }

    if (!this.isLoggedIn && !this.userRole && !menuItem.visibleJustWhenLoggedIn) {
      return true;
    }

    return hasRoleMatch && hasCommerceDomainMatch && hasPermissionMatch;
  }

  public logout(): void {
    this.sessionService.logout();
  }

  private onCommerceDomain$(): void {
    this.store
      .select(SessionSelectors.commerceDomain)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.changeDetectorRef.detectChanges());
  }

  private onFullname$(): void {
    this.store
      .select(AuthSelectors.fullName)
      .pipe(takeUntil(this.destroy$))
      .subscribe((fullName) => {
        this.userName = fullName;
        this.changeDetectorRef.markForCheck();
      });
  }

  private onCompanies$(): void {
    this.store
      .select(AuthSelectors.allCompanies)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companies) => {
        this.hasMultipleCompanies = companies.length > 1;
        this.changeDetectorRef.markForCheck();
      });
  }

  private onUILanguage$(): void {
    this.store
      .select(SessionSelectors.uiLanguageCode)
      .pipe(takeUntil(this.destroy$))
      .subscribe((language) => {
        this.selectedLanguageCode = language;
        this.changeDetectorRef.markForCheck();
      });
  }
}
