import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ProfileService } from '@app/profile/profile.service';
import { DropdownOption } from '@app/shared/components/dropdown/dropdown-option.class';
import { DropdownService } from '@app/shared/services/dropdown.service';
import { CompanyOutletSelectorFilter } from '@core/company-outlet-selector-2/store/company-outlet-selector-2.state';
import { SellerCenterDomain, SellerCenterService, WILDCARD } from '@core/config/global.config';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { CurrentStateService } from '@core/store/current-state.service';
import { RootState } from '@core/store/root.state';
import { Outlet, SSSPCountryCode } from '@core/types/types';
import { Store } from '@ngrx/store';
import { SortService } from '@shared/services/sort/sort.service';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

const ALL_DROPDOWN_OPTION = { id: WILDCARD, value: 'common.selector.all_my_outlets' };

@Component({
  selector: 'sss-dropdown-outlet',
  templateUrl: './dropdown-outlet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'sss-dropdown-outlet' },
})
export class DropdownOutletComponent implements OnChanges {
  @Input()
  public filter: CompanyOutletSelectorFilter;

  @Input()
  public allOption = false;

  @Input()
  public autoSelection = true;

  @Input()
  public disabled = false;

  @Input()
  public pickedOutletIds: string[] = [];

  @Input()
  public isCountryLevel = false;

  @Output()
  public changedOutlet: EventEmitter<Outlet> = new EventEmitter();

  public loading = true;
  public outletOptions: DropdownOption[] = [];
  public outletid: string;

  private outlets: Outlet[] = [];

  constructor(
    private readonly sortService: SortService,
    private readonly currentStateService: CurrentStateService,
    private readonly dropdownService: DropdownService,
    private readonly profileService: ProfileService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store<RootState>,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter) {
      this.makeOutletOptions();
    }
  }

  public emitOutlet(outletId: string): void {
    const outlet = this.outlets.find((outletInfo) => outletInfo.outletId === outletId);
    this.outletid = outletId;
    this.changedOutlet.emit(outlet);
  }

  private makeOutletOptions(): void {
    this.outletid = null;

    this.makeDropdownOptions().subscribe((outletOptions) => {
      this.outletOptions = outletOptions;
      if (this.outletOptions.length === 1 || this.outletOptions.length && this.allOption) {
        this.autoSelect();
      }

      this.changeDetectorRef.markForCheck();
    });
  }

  private autoSelect(): void {
    this.outletid = this.outletOptions[0].id;
    this.emitOutlet(this.outletid);
  }

  private makeDropdownOptions(): Observable<DropdownOption[]> {
    const { country, company: companyId, commerceDomain, sellerCenterDomain } = this.filter;
    const { COUNTRY_EDITOR } = this.currentStateService.get(AuthSelectors.userPermissions);

    if (!companyId) {
      return of([]);
    }

    const company = this.currentStateService.get(AuthSelectors.getCompanyById, { companyId: companyId, countryCode: country });

    // TODO DCPSSS-5187 fetch alaways instead of use store; remove documents workaround
    const unavailableOutlets  = COUNTRY_EDITOR && Boolean(company.permissions) && sellerCenterDomain === SellerCenterDomain.DOCUMENTS;
    const mustFetchOutlets = this.isCountryLevel || unavailableOutlets;

    let outlets$ = this.store.select(AuthSelectors.findOutlets, { country, company: companyId }).pipe(first());

    if (mustFetchOutlets) {
      outlets$ = this.profileService.fetchOutletsByCompany(companyId, country as SSSPCountryCode, !this.isCountryLevel);
    }

    return outlets$
      .pipe(
        map((outlets) => {
          this.outlets = outlets
            .filter((outlet) => this.intersectPickedOutletId(outlet.outletId))
            .filter((outlet) => {
              const skipFilter = mustFetchOutlets;

              const hasService = this.dropdownService.hasDomain(outlet, sellerCenterDomain, commerceDomain);
              const hasOutletPermission = Boolean(outlet?.permissions?.[SellerCenterService.SSSP]);
              const hasCompanyPermission =  Boolean(company.permissions);
              const hasPermission = hasOutletPermission || hasCompanyPermission;

              return true || skipFilter || hasService && hasPermission;
            });

          return this.mapDropdownOptions();
        })
      );
  }

  private intersectPickedOutletId(outletId: string): boolean {
    return !this.pickedOutletIds.length || this.pickedOutletIds.includes(outletId);
  }

  private mapDropdownOptions(): DropdownOption[] {
    const elements = this.outlets
      .map((outlet) => new DropdownOption(outlet.outletId, outlet.name))
      .sort((a, b) => this.sortService.sortStringAlphabeticallyIgnoreCase(a.value, b.value));

    if (this.allOption && (elements.length > 1 || !this.autoSelection)) {
      elements.unshift(new DropdownOption(ALL_DROPDOWN_OPTION.id, ALL_DROPDOWN_OPTION.value));
    }

    return elements;
  }
}
