import { Injectable } from '@angular/core';
import { Company } from '@core/types/types';

@Injectable()
export class PermissionsService {
  public hasCompanyOrOutletPermissions(company: Company): boolean {
    const hasCompanyPermissions = Boolean(company.permissions);
    const hasOutletPermissions = company.outlets && Object.values(company.outlets).some(( outlet ) => outlet.permissions);

    return hasCompanyPermissions || hasOutletPermissions;
  }
}
