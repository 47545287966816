import { Injectable } from '@angular/core';
import { validateRequestParams } from '@core/interceptors/params-validator';
import { CountryCode } from '@core/types/types';
import { BaseProduct } from '@generated/sssg/models/base-product';
import { SellerProductPrice } from '@generated/sssg/models/seller-product-price';
import { SellerProductPriceList } from '@generated/sssg/models/seller-product-price-list';
import { Seller } from '@generated/sssg/models/seller';
import { VehicleGroup } from '@generated/sssg/models/vehicle-group';
import {
  GetServiceContractBaseProductsParams,
  GetServiceContractProductsParams,
  GetVehicleGroupsParams,
  ProtectedService as PricingGPmeApiService,
  UpdateServiceContractSellerProductsPriceParams,
  GetSellerParams,
  PatchSellerParams,
  DeleteServiceContractSellerProductPriceParams,
} from '@generated/sssg/services/protected.service';
import { SortService } from '@shared/services/sort/sort.service';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { CompanyId, OutletId } from '@core/types/profile.types';

type DeleteProductPriceParams = 'country' | 'companyId' | 'outletId' | 'userName' | 'product';

@Injectable()
export class PricingGPmeService {
  constructor(
    private pricingGPmeApiService: PricingGPmeApiService,
    private windowRefService: WindowRefService,
    private sortService: SortService,
  ) {
    this.pricingGPmeApiService.setDomain(this.windowRefService.environment.api.secured.sssg);
  }

  public getVehicleGroups(country: CountryCode): Observable<Array<VehicleGroup>> {
    const params = validateRequestParams<GetVehicleGroupsParams>({ country });
    return this.pricingGPmeApiService.getVehicleGroups(params)
      .pipe(
        tap((vehicleGroups: Array<VehicleGroup>) => vehicleGroups.sort((a, b) => this.sortService.sortStringAlphabeticallyIgnoreCase(a.name, b.name))),
      );
  }

  public getServiceContractProducts(country: CountryCode, companyId: CompanyId, outletId: OutletId, productCode?: string, vehiclegroupId?: string): Observable<Array<BaseProduct>> {
    const params = validateRequestParams<GetServiceContractProductsParams>({ country, companyId, outletId, productCode, vehiclegroupId });
    return this.pricingGPmeApiService.getServiceContractProducts(params);
  }

  public updateServiceContractSellerProductsPrice(country: CountryCode, companyId: CompanyId, outletId: OutletId, products: SellerProductPriceList, userName: string): Observable<Array<SellerProductPrice>> {
    const params = validateRequestParams<UpdateServiceContractSellerProductsPriceParams>({ country, companyId, outletId, products, userName });
    return this.pricingGPmeApiService.updateServiceContractSellerProductsPrice(params);
  }

  public getServiceContractBaseProducts(country: CountryCode, level?: 'BASE' | 'VARIANT' | 'PRODUCT'): Observable<Array<BaseProduct>> {
    const params = validateRequestParams<GetServiceContractBaseProductsParams>({ country, level });
    return this.pricingGPmeApiService.getServiceContractBaseProducts(params)
      .pipe(
        tap((vehicleGroups: Array<BaseProduct>) => vehicleGroups.sort((a, b) => this.sortService.sortStringAlphabeticallyIgnoreCase(a.productName, b.productName))),
      );
  }

  public getSeller(country: CountryCode, companyId: CompanyId, outletId: OutletId): Observable<Seller> {
    const params = validateRequestParams<GetSellerParams>({ country, companyId, outletId });
    return this.pricingGPmeApiService.getSeller(params);
  }

  public patchSeller(params: PatchSellerParams): Observable<Seller> {
    const validateParams = validateRequestParams<PatchSellerParams>(params);
    return this.pricingGPmeApiService.patchSeller(validateParams);
  }

  public deleteProductPrice(params: Pick<DeleteServiceContractSellerProductPriceParams, DeleteProductPriceParams>): Observable<Seller> {
    const validateParams = validateRequestParams<DeleteServiceContractSellerProductPriceParams>(params);
    return this.pricingGPmeApiService.deleteServiceContractSellerProductPrice(validateParams).pipe(first());
  }
}
