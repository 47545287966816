/* tslint:disable */

/**
 * List of legacy shops.
 */
export enum Shop {
  ACCESSORIES = 'ACCESSORIES',
  COLLECTION = 'COLLECTION',
  CONNECT = 'CONNECT',
  GPME = 'GPME',
  NCOS = 'NCOS',
  UCOS = 'UCOS'
}
