/* tslint:disable */

/**
 * ISO 3166 Codes of currently supported countries on Seller Center.
 */
export enum CountryCode {
  AQ = 'AQ',
  AT = 'AT',
  AU = 'AU',
  BE = 'BE',
  BR = 'BR',
  CH = 'CH',
  CZ = 'CZ',
  DE = 'DE',
  DK = 'DK',
  ES = 'ES',
  FR = 'FR',
  GB = 'GB',
  HU = 'HU',
  IN = 'IN',
  IT = 'IT',
  JP = 'JP',
  KR = 'KR',
  LU = 'LU',
  MY = 'MY',
  MX = 'MX',
  NL = 'NL',
  NZ = 'NZ',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  SE = 'SE',
  SG = 'SG',
  SK = 'SK',
  TH = 'TH',
  TR = 'TR',
  UA = 'UA',
  ZA = 'ZA'
}
