import { createSelector, MemoizedSelector } from '@ngrx/store';
import { RootState } from '../root.state';
import { TranslationState } from './translation.state';
import { LanguageCode } from '@core/config/global.config';

const selectFeature = (state: RootState): TranslationState => state.translation;

export const getTranslations: MemoizedSelector<RootState, Record<string, string>> = createSelector(
  selectFeature, (state: TranslationState) => state.translations);

export const locale: MemoizedSelector<RootState, LanguageCode> = createSelector(
  selectFeature, (state: TranslationState) => state.locale);
