import { MediaMatcher } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const MQ_1_MAX_WIDTH = '479px';
const MQ_2_MIN_WIDTH = '480px';
const MQ_2_MAX_WIDTH = '767px';
const MQ_3_MIN_WIDTH = '768px';
const MQ_3_MAX_WIDTH = '1023px';
const MQ_4_MIN_WIDTH = '1024px';
const MQ_4_MAX_WIDTH = '1279px';
const MQ_5_MIN_WIDTH = '1280px';
const MQ_5_MAX_WIDTH = '1439px';
const MQ_6_MIN_WIDTH = '1440px';

const MEDIA_QUERY_MQ1 = `(max-width: ${ MQ_1_MAX_WIDTH })`;
const MEDIA_QUERY_MQ2 = `(min-width: ${ MQ_2_MIN_WIDTH }) and (max-width: ${ MQ_2_MAX_WIDTH })`;
const MEDIA_QUERY_MQ3 = `(min-width: ${ MQ_3_MIN_WIDTH }) and (max-width: ${ MQ_3_MAX_WIDTH })`;
const MEDIA_QUERY_MQ4 = `(min-width: ${ MQ_4_MIN_WIDTH }) and (max-width: ${ MQ_4_MAX_WIDTH })`;
const MEDIA_QUERY_MQ5 = `(min-width: ${ MQ_5_MIN_WIDTH }) and (max-width: ${ MQ_5_MAX_WIDTH })`;
const MEDIA_QUERY_MQ6 = `(min-width: ${ MQ_6_MIN_WIDTH })`;

export interface MediaQueryMap {
  isMQ1: boolean;
  isMQ2: boolean;
  isMQ3: boolean;
  isMQ4: boolean;
  isMQ5: boolean;
  isMQ6: boolean;
}

@Injectable()
export class MediaQueryService {
  private mediaQueryMap$: BehaviorSubject<MediaQueryMap> = new BehaviorSubject(null);
  private currentMediaMatchMap: MediaQueryMap = null;

  constructor(
    public mediaMatcher: MediaMatcher,
  ) {
    this.initialize();
  }

  public observeMediaQueryMap(): Observable<MediaQueryMap> {
    return this.mediaQueryMap$.asObservable();
  }

  public initialize(): void {
    this.setInitialState();

    this.mediaMatcher.matchMedia(MEDIA_QUERY_MQ1).addListener(e => this.updateState('isMQ1', e.matches));
    this.mediaMatcher.matchMedia(MEDIA_QUERY_MQ2).addListener(e => this.updateState('isMQ2', e.matches));
    this.mediaMatcher.matchMedia(MEDIA_QUERY_MQ3).addListener(e => this.updateState('isMQ3', e.matches));
    this.mediaMatcher.matchMedia(MEDIA_QUERY_MQ4).addListener(e => this.updateState('isMQ4', e.matches));
    this.mediaMatcher.matchMedia(MEDIA_QUERY_MQ5).addListener(e => this.updateState('isMQ5', e.matches));
    this.mediaMatcher.matchMedia(MEDIA_QUERY_MQ6).addListener(e => this.updateState('isMQ6', e.matches));
  }

  public getCurrentMediaMatchMap(): MediaQueryMap {
    return this.currentMediaMatchMap;
  }

  private setInitialState(): void {
    this.currentMediaMatchMap = {
      isMQ1: this.mediaMatcher.matchMedia(MEDIA_QUERY_MQ1).matches,
      isMQ2: this.mediaMatcher.matchMedia(MEDIA_QUERY_MQ2).matches,
      isMQ3: this.mediaMatcher.matchMedia(MEDIA_QUERY_MQ3).matches,
      isMQ4: this.mediaMatcher.matchMedia(MEDIA_QUERY_MQ4).matches,
      isMQ5: this.mediaMatcher.matchMedia(MEDIA_QUERY_MQ5).matches,
      isMQ6: this.mediaMatcher.matchMedia(MEDIA_QUERY_MQ6).matches,
    };

    this.mediaQueryMap$.next(this.currentMediaMatchMap);
  }

  private updateState(mediaQueryName: string, isMatching: boolean): void {
    this.currentMediaMatchMap[ mediaQueryName ] = isMatching;
    this.mediaQueryMap$.next(this.currentMediaMatchMap);
  }
}
