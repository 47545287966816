import { Action } from '@ngrx/store';
import { Company, Market, Outlet } from '@core/types/types';

export enum CompanyOutletSelector2ActionTypes {
  RESET = '[CompanyOutletSelector2] RESET',
  ACTIVATE_SELECTOR = '[CompanyOutletSelector2] ACTIVATE_SELECTOR',
  SELECT_COUNTRY = '[CompanyOutletSelector2] SELECT_COUNTRY',
  SELECT_MARKET = '[CompanyOutletSelector2] SELECT_MARKET',
  SELECT_COMPANY = '[CompanyOutletSelector2] SELECT_COMPANY',
  SELECT_OUTLET = '[CompanyOutletSelector2] SELECT_OUTLET',
  ENABLE_SELECTOR = '[CompanyOutletSelector2] ENABLE_SELECTOR',
  DISABLE_SELECTOR = '[CompanyOutletSelector2] DISABLE_SELECTOR'
}

export class ResetCompanyOutletSelector2Action implements Action {
  public readonly type = CompanyOutletSelector2ActionTypes.RESET;
}

export class ActivateSelectorAction implements Action {
  public readonly type = CompanyOutletSelector2ActionTypes.ACTIVATE_SELECTOR;
}

export class EnableSelectorAction implements Action {
  public readonly type = CompanyOutletSelector2ActionTypes.ENABLE_SELECTOR;
}
export class DisableSelectorAction implements Action {
  public readonly type = CompanyOutletSelector2ActionTypes.DISABLE_SELECTOR;
}
export class SelectCompanyAction implements Action {
  public readonly type = CompanyOutletSelector2ActionTypes.SELECT_COMPANY;

  constructor(
    public readonly company: Company,
  ) {}
}

export class SelectOutletAction implements Action {
  public readonly type = CompanyOutletSelector2ActionTypes.SELECT_OUTLET;

  constructor(
    public readonly outlet: Outlet,
  ) {}
}

export class SelectMarketAction implements Action {
  public readonly type = CompanyOutletSelector2ActionTypes.SELECT_MARKET;

  constructor(
    public readonly market: Market,
  ) {}
}
