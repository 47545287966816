export const AVAILABLE_BACKGROUND_IMAGES = [
  'assets/img/splash/dashboard_01.jpg',
  'assets/img/splash/dashboard_02.jpg',
  'assets/img/splash/dashboard_03.jpg',
  'assets/img/splash/dashboard_04.jpg',
  'assets/img/splash/dashboard_05.jpg',
  'assets/img/splash/dashboard_06.jpg',
  'assets/img/splash/dashboard_07.jpg',
  'assets/img/splash/dashboard_08.jpg',
  'assets/img/splash/dashboard_09.jpg',
  'assets/img/splash/dashboard_10.jpg',
  'assets/img/splash/dashboard_11.jpg',
  'assets/img/splash/dashboard_12.jpg',
  'assets/img/splash/dashboard_13.jpg',
  'assets/img/splash/dashboard_14.jpg',
  'assets/img/splash/dashboard_15.jpg',
  'assets/img/splash/dashboard_16.jpg',
];
