import { Injectable } from '@angular/core';
import { SellerCenterShopName } from '@core/config/global.config';
import { I18nService } from '@core/i18n/i18n.service';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { ShopParticipation } from '@core/store/auth/shop-participation.interface';
import { DropdownOption } from '@shared/components/dropdown/dropdown-option.class';
import { SortService } from '@shared/services/sort/sort.service';
import * as ShopOutletSelectorSelectors from './store/shop-outlet-selector.selectors';
import { CurrentStateService } from '@core/store/current-state.service';

@Injectable()
export class ShopOutletSelectorService {
  constructor(
    private i18nService: I18nService,
    private sortService: SortService,
    private currentStateService: CurrentStateService,
  ) {}

  public getShopDropdownOptions(): DropdownOption[] {
    const shopParticipation = this.currentStateService.get(AuthSelectors.shopParticipations);
    const availableShopsFilter = this.currentStateService.get(ShopOutletSelectorSelectors.availableShopsFilter);

    return this.getAvailableShopDropdownOptions(shopParticipation, availableShopsFilter);
  }

  public makeOutletDropdownOptions(): DropdownOption[] {
    const shop = this.currentStateService.get(ShopOutletSelectorSelectors.selectedShop);
    const outlets = this.currentStateService.get(AuthSelectors.outlets)
      .filter(outlet => outlet.onboardedLegacyShops && outlet.onboardedLegacyShops.includes(shop));

    const options = outlets
      .map(i => new DropdownOption(i.outletId, i.name))
      .sort((a, b) => this.sortService.sortStringAlphabeticallyIgnoreCase(a.value, b.value));

    return options || [];
  }

  private getAvailableShopDropdownOptions(shopParticipation: Array<ShopParticipation>, availableShopsFilter: Array<SellerCenterShopName>): Array<DropdownOption> {
    let shops = [];

    shops = shops.concat(shopParticipation
      .filter((participation: ShopParticipation) => Boolean(participation.isParticipating && availableShopsFilter.includes(participation.name)))
      .map((participation: ShopParticipation) => {
        const name = this.i18nService.getInstantTranslation(`common.shops.${ participation.name }`);
        return new DropdownOption(participation.name, name);
      })
      .sort((a, b) => this.sortService.sortStringAlphabeticallyIgnoreCase(a.value, b.value)));

    return shops;
  }
}
