import { DocumentsRoute } from '@app/document/document.config';
import { OrdersRoute } from '@app/orders/orders.config';
import { ProfileRoute } from '@app/profile/profile.config';
import { SetupRoute } from '@app/setup/setup.types';
import { ShippingCostsRoute } from '@app/shipping-costs/shipping-costs.config';
import { WarrantyPackagesRoute } from '@app/warranty-packages/warranty-packages.config';
export interface SelectorConfig {
  selectorMarket: boolean;
  selectorCompany: boolean;
  selectorOutlet: boolean;
  allOutletOption: boolean;
  autoSelection: boolean;
  countryLevel: boolean;
}

export const SELECTOR_CONFIG: Record<string, SelectorConfig> = {
  [ ProfileRoute.MANAGE ]: {
    selectorMarket: false,
    selectorCompany: true,
    selectorOutlet: true,
    allOutletOption: false,
    autoSelection: true,
    countryLevel: false,
  },
  [ ProfileRoute.OVERVIEW ]: {
    selectorMarket: false,
    selectorCompany: true,
    selectorOutlet: true,
    allOutletOption: false,
    autoSelection: true,
    countryLevel: false,
  },
  [ SetupRoute.ROOT ]: {
    selectorMarket: false,
    selectorCompany: true,
    selectorOutlet: false,
    allOutletOption: false,
    autoSelection: true,
    countryLevel: false,

  },
  [ DocumentsRoute.REPOSITORY ]: {
    selectorMarket: false,
    selectorCompany: true,
    selectorOutlet: true,
    allOutletOption: false,
    autoSelection: true,
    countryLevel: false,

  },
  [ DocumentsRoute.OUTLET_UPLOAD ]: {
    selectorMarket: true,
    selectorCompany: true,
    selectorOutlet: true,
    allOutletOption: true,
    autoSelection: true,
    countryLevel: false,

  },
  [ ShippingCostsRoute.MANAGE ]: {
    selectorMarket: false,
    selectorCompany: true,
    selectorOutlet: true,
    allOutletOption: false,
    autoSelection: true,
    countryLevel: false,

  },
  [ ShippingCostsRoute.OVERVIEW ]: {
    selectorMarket: false,
    selectorCompany: true,
    selectorOutlet: true,
    allOutletOption: false,
    autoSelection: true,
    countryLevel: true,
  },
  [ OrdersRoute.ROOT ]: {
    selectorMarket: false,
    selectorCompany: true,
    selectorOutlet: true,
    allOutletOption: false,
    autoSelection: true,
    countryLevel: false,
  },
  [ OrdersRoute.DETAILS ]: {
    selectorMarket: false,
    selectorCompany: false,
    selectorOutlet: false,
    allOutletOption: false,
    autoSelection: false,
    countryLevel: false,
  },
  [ WarrantyPackagesRoute.ROOT]: {
    selectorMarket: false,
    selectorCompany: false,
    selectorOutlet: true,
    allOutletOption: false,
    autoSelection: false,
    countryLevel: false,
  },
};
