import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@shared/components/button/button.module';
import { ComboBoxModule } from '@shared/components/combo-box/combo-box.module';
import { HintModule } from '@shared/components/hint/hint.module';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { ModalModule } from '@shared/components/modal/modal.module';
import { CompanySelectorComponent } from './company-selector.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';

@NgModule({
  declarations: [
    CompanySelectorComponent,
  ],
  exports: [
    CompanySelectorComponent,
  ],
  imports: [
    CommonModule,

    FormsModule,
    PipesModule,

    LoaderModule,
    ComboBoxModule,
    ModalModule,
    ButtonModule,
    HintModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class CompanySelectorModule {}
