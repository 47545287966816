import { State } from '../root.state';
import { CommerceDomain, SellerCenterDomain, LanguageCode } from '@core/config/global.config';

export interface SessionState extends State {
  commerceDomain: CommerceDomain;
  sellerCenterDomain: SellerCenterDomain;
  selectedUiLanguage: LanguageCode;
  uilanguages: LanguageCode[];
}

export const SESSION_INITIAL_STATE: SessionState = {
  commerceDomain: null,
  sellerCenterDomain: null,
  selectedUiLanguage: LanguageCode.EN,
  uilanguages: [ LanguageCode.EN ],
};
