export enum AppRoute {
  APP_ROOT = '/',
  PRICING = 'pricing',
  FEEDBACK = 'feedback',
  HELP = 'help', // TODO: rename to 'contact'
  PROFILE = 'profile',
  DASHBOARD = 'dashboard',
  DOCUMENT = 'documents',
  MASTER_DATA = 'shop-management', // TODO: rename to master-data
  ORDERS = 'orders',
  SETUP = 'onboarding',
  SETUP_PRODUCT_PARTICIPATION = 'setup',
  BACKOFFICE = 'backoffice',
  SHIPPING_COSTS = 'shipping-costs',
  WARRANTY_PACKAGES = 'warranty-packages'
}
