<sss-modal
  (onClose)="closeModal()"
  closeOnBackdropClick
  compact
  [headline]="'common.languageSwitcher.headline' | sssTranslate | async">

  <p>{{ 'common.languageSwitcher.description' | sssTranslate | async }}</p>

  <sss-combo-box
    (selectionChange)="onLanguageSelectionChange($event)"
    [defaultText]="'common.dropdown.chooseOptionText' | sssTranslate | async"
    [options]="languageOptions"
    [selection]="selectedLanguageCode"
    [label]="'common.languageSwitcher.switcherHeadline' | sssTranslate | async"
    class="h-margin-top sss-language-switcher__content"
    inline>
  </sss-combo-box>

  <modal-footer
    class="h-flex-row h-flex-spread">
    <wb-button
      (click)="closeModal()"
      size="s"
      variant="secondary"
    >
      {{ 'common.languageSwitcher.cancel' | sssTranslate | async }}
    </wb-button>
    <wb-button
      (click)="changeLanguage()"
      size="s"
      variant="primary"
    >
      {{ 'common.languageSwitcher.save' | sssTranslate | async }}
    </wb-button>
  </modal-footer>
</sss-modal>
