import { ActionMap, reduce } from '@core/store/root.state';
import { Action } from '@ngrx/store';
import { DocumentTableRow } from '../document.typings';
import * as DocumentActions from './document.actions';
import { DocumentState, DOCUMENT_INITIAL_STATE } from './document.state';

const REDUCER_ACTION_MAP: ActionMap = {
  [ DocumentActions.FETCH_DOCUMENTS_REQUEST ]: fetchDocumentsRequest,
  [ DocumentActions.FETCH_DOCUMENTS_SUCCESS ]: fetchDocumentsSuccess,
  [ DocumentActions.FETCH_DOCUMENTS_FAILURE ]: fetchDocumentsFailure,
  [ DocumentActions.DOCUMENT_UPLOADED ]: documentUploaded,
  [ DocumentActions.CLEAR_OVERVIEW ]: clearDocumentOverview,
  [ DocumentActions.SAVE_FILE_FORM ]: saveFileForm,
  [ DocumentActions.RESET_FILE_FORM ]: ResetFileForm,
};

export function documentReducer(state: DocumentState = DOCUMENT_INITIAL_STATE, action: Action): DocumentState {
  return reduce(REDUCER_ACTION_MAP, action, state);
}

function fetchDocumentsRequest(state: DocumentState): DocumentState {
  return {
    ...state,
    overview: {
      ...state.overview,
      isPending: true,
      error: null,
    },
  };
}

function fetchDocumentsSuccess(state: DocumentState, action: DocumentActions.FetchDocumentsSuccessAction): DocumentState {
  return {
    ...state,
    overview: {
      isPending: false,
      error: null,
      data: action.overview,
    },
  };
}

function fetchDocumentsFailure(state: DocumentState, action: DocumentActions.FetchDocumentsFailureAction): DocumentState {
  return {
    ...state,
    overview: {
      isPending: false,
      error: action.error,
      data: null,
    },
  };
}

function clearDocumentOverview(state: DocumentState): DocumentState {
  return {
    ...state,
    overview: {
      isPending: false,
      error: null,
      data: null,
    },
  };
}

function documentUploaded(state: DocumentState, action: DocumentActions.DocumentUploadedAction): DocumentState {
  const { customerType, language } = action.document;

  return {
    ...state,
    overview: {
      ...state.overview,
      data: {
        [ customerType ]: state.overview.data[ customerType ]
          .map((row: DocumentTableRow) => (row.type === action.document.type)
            ? {
              ...row,
              documentLanguageMap: {
                ...row.documentLanguageMap,
                [ language ]: action.document,
              },
            }
            : row),
      },
    },
  };
}

function saveFileForm(state: DocumentState, action: DocumentActions.SaveFileForm): DocumentState {
  return {
    ...state,
    fileForm: { ...action.fileForm },
  };
}

function ResetFileForm(): DocumentState {
  return {
    ...DOCUMENT_INITIAL_STATE,
  };
}
