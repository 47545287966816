import { Injectable } from '@angular/core';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';

@Injectable()
export class MailtoService {
  constructor(
    private windowRefService: WindowRefService,
  ) { }

  public open(recipient: string, subject: string, body: string, cc?: string): void {
    this.windowRefService.nativeWindow.open(`mailto:${ encodeURIComponent(recipient) }?subject=${ encodeURIComponent(subject) }&body=${ encodeURIComponent(body) }${ cc ? '&cc=' + encodeURIComponent(cc) : '' }`);
  }
}
