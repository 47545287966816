import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DropdownComponent } from './dropdown.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';

@NgModule({
  declarations: [
    DropdownComponent,
  ],
  exports: [
    DropdownComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class DropdownModule {}
