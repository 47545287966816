import { Async } from '@core/store/async.interfaces';
import { State } from '@core/store/root.state';
import { CompanyDocumentUploads, OutletDocumentUploads } from '../document-marketplace/document-marketplace.typings';

export interface MarketplaceDocumentState extends State {
  documents: Async<Array<OutletDocumentUploads | CompanyDocumentUploads>>;
}

export const MARKETPLACE_DOCUMENT_INITIAL_STATE: MarketplaceDocumentState = {
  documents: {
    data: null,
    isPending: false,
    error: null,
  },
};
