import { animate, style, transition, trigger } from '@angular/animations';

const DEFAULT_ANIMATION_DURATION_MS = 200;
const FADE_IN_ANIMATION_DURATION_MS = 200;
const FADE_OUT_ANIMATION_DURATION_MS = 400;

const FLY_IN__FLY_OUT = trigger('flyInFlyOut', [
  transition(':enter', [
    style({ transform: 'translateX(100%)' }),
    animate(DEFAULT_ANIMATION_DURATION_MS),
  ]),
  transition(':leave', [
    animate(DEFAULT_ANIMATION_DURATION_MS, style({ transform: 'translateX(100%)' })),
  ]),
]);

const FLY_IN__FADE_OUT = trigger('flyInFadeOut', [
  transition(':enter', [
    style({ transform: 'translateX(100%)' }),
    animate(DEFAULT_ANIMATION_DURATION_MS),
  ]),
  transition(':leave', [
    animate(FADE_OUT_ANIMATION_DURATION_MS, style({ opacity: 0 })),
  ]),
]);

const FADE_IN__FADE_OUT = trigger('fadeInFadeOut', [
  transition(':enter', [
    style({ opacity: '0' }),
    animate(FADE_IN_ANIMATION_DURATION_MS),
  ]),
  transition(':leave', [
    animate(FADE_OUT_ANIMATION_DURATION_MS, style({ opacity: 0 })),
  ]),
]);

export const ANIMATIONS = {
  FLY_IN__FLY_OUT,
  FLY_IN__FADE_OUT,
  FADE_IN__FADE_OUT,
};
