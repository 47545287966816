/* eslint-disable max-len */
import { CATEGORY_PRODUCTS_MAP, CommerceDomain, COMMERCE_DOMAIN_PROP, ProductCategory, SellerCenterMarket, SellerCenterService, SellerCenterShopName } from '@core/config/global.config';
import { Identity } from '@core/services/identity/identity.typings';
import { ShopParticipation } from '@core/store/auth/shop-participation.interface';
import { CompanyId } from '@core/types/profile.types';
import { Company, Jwt, Outlet, SSSPCountryCode } from '@core/types/types';
import { UserPermissions, UserRole } from '@core/types/user-role';
import { CompanyInfo, CountryCode, CountryInfo, OutletInfo, ProductType, UserEntitlements } from '@generated/sssp-v2/models';
import { SellerOutlet } from '@generated/sssp/models/seller-outlet';
import { createSelector, MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';
import { RootState } from '../root.state';
import { SessionState } from '../session/session.state';
import { AuthState } from './auth.state';

const selectFeature = (state: RootState): AuthState => state.auth;
const fromSession = (state: RootState): SessionState => state.session;

export const authState: MemoizedSelector<RootState, AuthState> = createSelector(selectFeature, (state: AuthState) => state);
export const commerceDomains: MemoizedSelector<RootState, CommerceDomain[]> = createSelector(selectFeature, (state: AuthState) => state.commerceDomains);
export const hasSession: MemoizedSelector<RootState, boolean> = createSelector(selectFeature, (state: AuthState) => Boolean(state.token) && Boolean(state.identity) && Boolean(state.profile));
export const userRole: MemoizedSelector<RootState, UserRole> = createSelector(selectFeature, (state: AuthState) => state.userRole);
export const userPermissions: MemoizedSelector<RootState, UserPermissions> = createSelector(selectFeature, (state: AuthState) => state.userPermissions);
export const userName: MemoizedSelector<RootState, string> = createSelector(selectFeature, (state: AuthState) => state && state.identity && state.identity.sub);
export const fullName: MemoizedSelector<RootState, string> = createSelector(selectFeature, (state: AuthState) => state && state.identity && `${ state.identity.given_name } ${ state.identity.family_name }`);
export const token: MemoizedSelector<RootState, Jwt> = createSelector(selectFeature, (state: AuthState) => state.token);
export const identity: MemoizedSelector<RootState, Identity> = createSelector(selectFeature, (state: AuthState) => state.identity);
export const profile: MemoizedSelector<RootState, UserEntitlements> = createSelector(selectFeature, (state: AuthState) => state.profile);
export const selectedCompany: MemoizedSelector<RootState, Company> = createSelector(selectFeature, (state: AuthState) => state.selectedCompany);
export const companyId: MemoizedSelector<RootState, CompanyId> = createSelector(selectFeature, (state: AuthState) => state.selectedCompany && state.selectedCompany.companyId);
export const shopParticipations: MemoizedSelector<RootState, Array<ShopParticipation>> = createSelector(selectFeature, (state: AuthState) => state.shopParticipations);
export const companyCountryCode: MemoizedSelector<RootState, string> = createSelector(selectFeature, (state: AuthState) => state.companyCountryCode);
export const legalEntity: MemoizedSelector<RootState, SellerOutlet> = createSelector(selectFeature, (state: AuthState) => state.legalEntity);

export const hasCountryDE: MemoizedSelector<RootState, boolean> = createSelector(selectFeature,
  (state: AuthState) => SellerCenterMarket.DE in state.profile.countries
);

export const isOnlyCountryEditor: MemoizedSelector<RootState, boolean> = createSelector(selectFeature,
  (state: AuthState) => state.userPermissions.COUNTRY_EDITOR && !state.userPermissions.COMPANY_EDITOR && !state.userPermissions.OUTLET_EDITOR
);

export const hasDECountryPermissions: MemoizedSelector<RootState, boolean> = createSelector(selectFeature,
  (state: AuthState) => SellerCenterMarket.DE in state.profile.countries && Boolean(state.profile.countries[SellerCenterMarket.DE].permissions));
/**
   * list all oulets of selected company
   */
export const outlets: MemoizedSelector<RootState, Outlet[]> = createSelector(selectFeature, (state: AuthState) => {
  const { companyId: cid, countryCode } = state.selectedCompany;

  if (!state.profile) {
    return [];
  }

  const companyOutlets = state.profile.countries[countryCode].companies[cid].outlets;

  return Object.entries(companyOutlets).map(([ id, props ]) => ({ outletId: id,  companyId: cid, ...props })) || [];
});

/**
   * list all companies available
   */
export const allCompanies: MemoizedSelector<RootState, Company[]> = createSelector(selectFeature, (state: AuthState) => {
  if (!state.profile) {
    return [];
  }

  return mapCompanies(state.profile.countries);
});

/**
   * list all outlets available
   */

export const allOutlets: MemoizedSelector<RootState, Outlet[]> = createSelector(
  selectFeature,
  (state: AuthState) => {
    if (!state.profile) {
      return [];
    }

    return mapOutlets(state.profile.countries);
  });


export const countryEditorServicesAvailable: MemoizedSelector<RootState, SellerCenterService[]> = createSelector(
  selectFeature,
  (state: AuthState) =>  {
    if (!state.profile) {
      return null;
    }

    const countries =  Object.keys(state.profile.countries)
      .filter((countryCode) => Boolean(state.profile.countries[countryCode].permissions)) as SSSPCountryCode[];

    const services = countries.flatMap((country) => {
      const entity = state.profile.countries[country];
      const allServices = {
        ...entity.services,
        ...entity.externalServices,
      };

      return Object.entries(allServices).map(([ service ]) => service as SellerCenterService);
    });

    return services.filter((value, index, self) => self.indexOf(value) === index);
  });

export const servicesAvailable: MemoizedSelector<RootState, SellerCenterService[]> = createSelector(
  selectFeature, fromSession,
  (state, sessionState) => {
    const PROP = {
      [ CommerceDomain.MARKETPLACE ]: 'profile',
      [ CommerceDomain.SHOPS ]: 'selectedCompany',
    };

    const { commerceDomain } = sessionState;

    if (!commerceDomain) {
      return [];
    }

    const commerceDomainProp = COMMERCE_DOMAIN_PROP[commerceDomain];

    const entity = state[PROP[commerceDomain]];

    const allServices = {
      ...entity.services,
      ...entity.externalServices,
    };

    return Object.entries(allServices)
      .filter(([ , o ]) => o[commerceDomainProp])
      .map(([ service ]) => service as SellerCenterService);
  });

export const countriesWithPermissions: MemoizedSelector<RootState, CountryCode[]> = createSelector(
  selectFeature,
  (state: AuthState) => {
    if (!state.profile) {
      return [];
    }

    return Object.keys(state.profile.countries)
      .filter((countryCode) => Boolean(state.profile.countries[countryCode].permissions)) as SSSPCountryCode[];
  }
);

export const countriesWithSSSDPermissions: MemoizedSelector<RootState, CountryCode[]> = createSelector(
  selectFeature,
  (state: AuthState) => {
    if (!state.profile) {
      return [];
    }

    return Object.keys(state.profile.countries)
      .filter((countryCode) => Boolean(state.profile.countries[countryCode].permissions &&
        Object.keys(state.profile.countries[countryCode].permissions).includes(SellerCenterService.SSSD))) as SSSPCountryCode[];
  }
);

export const outletsWithPermissions: MemoizedSelector<RootState, Outlet[]> = createSelector(
  selectFeature,
  (state: AuthState) => {
    if (!state.profile) {
      return [];
    }

    const companiesWithPermissions = mapCompanies(state.profile.countries)
      .filter((company) => company.permissions && Object.keys(company.permissions).includes(SellerCenterService.SSSP));

    return mapOutlets(state.profile.countries)
      .filter((outlet) => companiesWithPermissions.some((company) => company.companyId === outlet.companyId) || outlet.permissions && Object.keys(outlet.permissions).includes(SellerCenterService.SSSP));
  }
);

export const countryUserRoles: MemoizedSelector<RootState, Record<string, UserRole[]>> = createSelector(
  selectFeature,
  (state: AuthState) => {
    if (!state.profile) {
      return null;
    }

    return Object.entries(state.profile.countries).reduce((acc, [ countryCode, countryInfo ]) => {
      const roles = [];
      const hasCompanyOrOutletPermissions = Object.values(countryInfo.companies)
        .some((company) => Boolean(company.permissions) || Object.values(company.outlets).some((outlet) => Boolean(outlet.permissions)));

      if (countryInfo.permissions) {
        roles.push(UserRole.COUNTRY_MANAGER);
      }
      if (hasCompanyOrOutletPermissions) {
        roles.push(UserRole.SELLER);
      }
      acc[countryCode] = roles;

      return acc;
    }, {});
  });

interface FindOutletsProps { country: SSSPCountryCode; company: string }

export const findOutlets:  MemoizedSelectorWithProps<RootState, FindOutletsProps, Outlet[]> = createSelector(
  selectFeature,
  (state: AuthState, params: FindOutletsProps) => {
    const { country, company } = params;

    if (!state.profile) {
      return [];
    }

    const outletsInfo = state.profile.countries[country].companies[company].outlets;

    if (!outletsInfo) {
      return [];
    }

    return Object.entries<OutletInfo>(outletsInfo)
      .map(([ oid, o ]) => {
        const mappedOutlet: Outlet = {
          ...o,
          outletId: oid,
          companyId: company,
          countryCode: country,
        };

        return mappedOutlet;
      });
  });

interface GetCompanyByIdProps { companyId: string; countryCode: SSSPCountryCode }

export const getCompanyById: MemoizedSelectorWithProps<RootState, GetCompanyByIdProps, CompanyInfo> = createSelector(
  selectFeature,
  (state: AuthState, params: GetCompanyByIdProps) => {
    const { companyId: cId, countryCode } = params;
    if (!state.profile) {
      return null;
    }
    return state.profile.countries[countryCode].companies[cId];
  }
);
interface availableProductTypesProps { countryCode: SSSPCountryCode }
export const availableProductTypes: MemoizedSelectorWithProps<RootState, availableProductTypesProps, ProductType[]> = createSelector(
  selectFeature,
  (state: AuthState, params: availableProductTypesProps) => {
    const { countryCode } = params;

    if (!countryCode || !state.profile) {
      return [];
    }

    return state.profile.countries[countryCode].availableProductTypes;
  });

interface availableProductCategoryProps { countryCode: SSSPCountryCode }
export const availableProductCategories: MemoizedSelectorWithProps<RootState, availableProductCategoryProps, ProductCategory[]> = createSelector(
  selectFeature,
  (state: AuthState, params: availableProductCategoryProps) => {
    const { countryCode } = params;

    if (!countryCode || !state.profile) {
      return [];
    }
    const productTypes = state.profile.countries[countryCode].availableProductTypes;
    const productCategories = Object.keys(CATEGORY_PRODUCTS_MAP)
      .filter((category) => productTypes.some(product => CATEGORY_PRODUCTS_MAP[category].includes(product)))
      .map((category) => category as ProductCategory);

    return productCategories;
  });

function findShops(company: Company): SellerCenterShopName[] {
  return Object.values({ ...company.outlets })
    .filter(o => o.onboardedLegacyShops && o.onboardedLegacyShops.length)
    .map(o  => o.onboardedLegacyShops)
    .flat()
    .filter((i, idx, arr) => arr.indexOf(i) === idx);
}

function mapOutlets(countries: Record<string, CountryInfo>): Outlet[] {
  const outletList: Outlet[] = [];

  Object.entries(countries).forEach(([ countryCode, country ]) => {
    const companies = country.companies;

    Object.entries(companies).forEach(([ cid, company ]) => {
      if (!company.outlets) {
        return;
      }

      const mappedOutlets: Outlet[] = Object.entries(company.outlets)
        .map(([ id, outlet ]) => ({
          outletId: id,
          companyId: cid,
          countryCode: countryCode as CountryCode,
          ...outlet,
        }));

      outletList.push(...mappedOutlets);
    });
  });
  return outletList;
}

function mapCompanies(countries: Record<string, CountryInfo>): Company[] {
  const companies = Object.entries(countries)
    .reduce((acc, [ country, { companies: co }]) => {
      Object.entries(co)
        .forEach(([ companyid, company ]) => {
          acc.push({
            companyId: companyid,
            countryCode: country,
            shops: findShops(company as Company),
            ...company,
          });
        });
      return acc;
    }, []);

  return companies || [];
}
