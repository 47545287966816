import { Identity } from '@core/services/identity/identity.typings';
import { ShopParticipation } from '@core/store/auth/shop-participation.interface';
import { Jwt, Company } from '@core/types/types';
import { UserPermissions, UserRole } from '@core/types/user-role';
import { SellerOutlet } from '@generated/sssp/models/seller-outlet';
import { State } from '../root.state';
import { UserEntitlements } from '@generated/sssp-v2/models';
import { CommerceDomain } from '@core/config/global.config';

export interface AuthState extends State {
  token: Jwt;
  identity: Identity;
  profile: UserEntitlements;
  userRole: UserRole;
  userPermissions: UserPermissions;
  commerceDomains: CommerceDomain[];
  selectedCompany: Company;
  shopParticipations: ShopParticipation[];
  companyCountryCode: string;
  legalEntity: SellerOutlet;
}

export const AUTH_INITIAL_STATE: AuthState = {
  token: null,
  identity: null,
  profile: null,
  userRole: null,
  userPermissions: null,
  commerceDomains: [],
  selectedCompany: null,
  shopParticipations: null,
  companyCountryCode: null,
  legalEntity: null,
};
