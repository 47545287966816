import { Injectable } from '@angular/core';
import { ResetAuthStateAction } from '@core/store/auth/auth.actions';
import { RootState } from '@core/store/root.state';
import { Store } from '@ngrx/store';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';

const SELLER_CENTER_CLI_OBJECT_KEY = 'cli';

enum Endpoint {
  SSSS_DEV = 'https://api-dev.apps.urso.cf.intra.oneweb.mercedes-benz.io/ssss/v1',
}

// Note: This functionality is for testing purposes.
// (Quote) testers can change - for non PROD SSS env - the DCP env in the Browser Console.
// This functionality might be removed in the future.

@Injectable()
export class ConsoleCommandService {
  constructor(
    private readonly windowRefService: WindowRefService,
    private readonly store: Store<RootState>,
  ) {}

  public initialize(): void {
    if (!this.windowRefService.environment.production) {
      this.windowRefService.nativeWindow[ SELLER_CENTER_CLI_OBJECT_KEY ] = {
        switchShippingCostsToDev: (): void => this.updateShippingCostsDomain(Endpoint.SSSS_DEV),
        logout: (): void => this.logout(),
      };
    }
  }

  private updateShippingCostsDomain(endpoint: string): void {
    this.windowRefService.environment.api.secured.ssss = endpoint;
  }

  private logout(): void {
    this.store.dispatch(new ResetAuthStateAction());
  }
}
