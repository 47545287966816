/* eslint-disable */

export const SNOW_CANVAS_ID = 'snow';
export const letItSnow = (): void => {
  const canvas = document.createElement('canvas'), ctx = canvas.getContext('2d'), windowW = window.innerWidth, windowH = window.innerHeight, numFlakes = 120, flakes = [];
  canvas.id = SNOW_CANVAS_ID;
  canvas.style.position = 'fixed';
  canvas.style.top = '0';
  canvas.style.bottom = '0';
  canvas.style.left = '0';
  canvas.style.right = '0';
  canvas.style.zIndex = '-1';

  function Flake(t: any, e: any): void {
    this.x = t, this.y = e, this.r = randomBetween(0, 1), this.a = randomBetween(0, Math.PI), this.aStep = .01, this.weight = randomBetween(2, 5), this.alpha = this.weight / 5, this.speed = this.weight / 5 * 3, this.update = function(): any {
      this.x += Math.cos(this.a) * this.r, this.a += this.aStep, this.y += this.speed;
    };
  }

  function scaleCanvas(): void {
    canvas.width = windowW, canvas.height = windowH;
  }

  // tslint:disable:no-unused-expression
  function loop(): void {
    let t: any;
    let e = flakes.length;
    for (ctx.save(), ctx.setTransform(1, 0, 0, 1, 0, 0), ctx.clearRect(0, 0, windowW, windowH), ctx.restore(); e--;) {
      (t = flakes[e]).update(), ctx.beginPath(), ctx.arc(t.x, t.y, t.weight, 0, 2 * Math.PI, !1), ctx.fillStyle = 'rgba(255, 255, 255, ' + t.alpha + ')', ctx.fill(), (t.y >= windowH) && (t.y = -t.weight);
    }
    requestAnimationFrame(loop);
  }

  function randomBetween(t: any, e: any, a: any = null): any {
    const n = Math.random() * (e - t + 1) + t;
    return a ? Math.floor(n) : n;
  }
  document.body.appendChild(canvas);
  for (let flake: any, x: any, y: any, i = numFlakes; i--;) {
    flake = new Flake(x = randomBetween(0, windowW, !0), y = randomBetween(0, windowH, !0)), flakes.push(flake);
  }
  scaleCanvas(), loop();
};
