import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@core/i18n/i18n.service';
import * as TranslationSelectors from '@core/store/translation/translation.selectors';
import { RootState } from '@core/store/root.state';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'sssTranslate',
})
export class CustomTranslatePipe implements PipeTransform {
  constructor(
    private i18nService: I18nService,
    private readonly store: Store<RootState>,
  ) {}

  transform(key: string, args?: Record<string, string>): any {
    return this.store.select(TranslationSelectors.getTranslations)
      .pipe(map(translations => translations?.[key] || key))
      .pipe(map(translation => {
        if (args) {
          return this.i18nService.replaceParams(translation, args);
        }

        return translation;
      }));
  }
}
