import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CompanySelectorModule } from '@core/company-selector/company-selector.module';
import { HeaderModule } from '@core/header/header.module';
import { I18nModule } from '@core/i18n/i18n.module';
import { metaReducers, rootReducer } from '@core/store/root.reducer';
import { ROOT_INITIAL_STATE } from '@core/store/root.state';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { ToasterModule } from '@shared/components/toaster/toaster.module';
import { MarkdownModule } from 'ngx-markdown';
import { AppRoutingModule } from './app-routing.module';
import { WarningBarModule } from '@shared/components/warning-bar/warning-bar.module';
import { CommerceDomainSelectorModule } from '@core/commerce-domain-selector/commerce-domain-selector.module';
import { SidebarModule } from '@core/sidebar/sidebar.module';

export const APP_IMPORTS = [
  BrowserModule,
  BrowserAnimationsModule,
  FormsModule,
  HttpClientModule,
  LoaderModule,
  StoreModule.forRoot(rootReducer, {
    initialState: ROOT_INITIAL_STATE,
    metaReducers,
    runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true } }),
  EffectsModule.forRoot([ /* GLOBALLY AVAILABLE EFFECTS GO HERE */ ]),
  AppRoutingModule,
  HeaderModule,
  I18nModule,
  CompanySelectorModule,
  ToasterModule,
  WarningBarModule,
  CommerceDomainSelectorModule,
  SidebarModule,
  MarkdownModule.forRoot(),
];
