import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CurrentStateService } from '@core/store/current-state.service';
import { AppRoute } from '../../app-routes.enum';
import * as AuthSelectors from '../store/auth/auth.selectors';

@Injectable()
export class CountryManagerGuard implements CanActivate {
  constructor(
    private router: Router,
    private currentStateService: CurrentStateService,
  ) {}

  public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const { COUNTRY_EDITOR } = this.currentStateService.get(AuthSelectors.userPermissions);

    if (!COUNTRY_EDITOR) {
      this.redirectToLogin(state.url);
    }

    return COUNTRY_EDITOR;
  }

  // TODO: since this is the same like in other guard, think about extracting that code into a service...
  private redirectToLogin(currentRoute = ''): void {
    const requestedRoute = currentRoute.split('?')[ 0 ];
    this.router.navigate([ AppRoute.DASHBOARD ], { queryParams: { next: requestedRoute } });
  }
}
