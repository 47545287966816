import { Document as PdfDocument } from '@generated/sssd/models/document';
import { DocumentCountry } from '@generated/sssd/models/document-country';
import { DocumentCustomerType } from '@generated/sssd/models/document-customer-type';
import { DocumentLanguage } from '@generated/sssd/models/document-language';
import { DocumentScope } from '@generated/sssd/models/document-scope';
import { DocumentType } from '@generated/sssd/models/document-type';
import { CompanyId, OutletId } from '@core/types/profile.types';
import { CountryCode, DocumentUploads, MimeType } from '@generated/sssd-v3/models';

export class DocumentTableRow {
  constructor(
    public readonly type: DocumentType = null,
    public readonly required: boolean = true,
    public readonly documentLanguageMap: { [ languageKey: string ]: PdfDocument } = {},
  ) {}
}

export interface DocumentOverview {
  [ DocumentCustomerType.PRIVATE ]?: Array<DocumentTableRow>;
  [ DocumentCustomerType.BUSINESS ]?: Array<DocumentTableRow>;
}

export interface DocumentWithContent extends PdfDocument {
  content: string;
}

export class DocumentModalConfig {
  constructor(
    public country: DocumentCountry,
    public language: DocumentLanguage,
    public documentType: DocumentType,
    public scope: DocumentScope,
    public companyId: CompanyId,
    public outletId: OutletId,
    public customerType: DocumentCustomerType,
  ) {}
}

export interface MarketplaceContext {
  marketId: string;
  country: CountryCode;
  companyId: string;
  outletId: string;
}

export enum FileAllow {
  FILE_SIZE_ALLOWED = 4194304,
  FILE_TYPE = 'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  FILE_NAME_LENGTH_ALLOWED = 200,
}
export interface SellerDocumentEditing extends DocumentUploads {
  editing: boolean;
  uploading: boolean;
}

export interface DocumentUploadResponse {
  outletId?: string;
  success: boolean;
}

export enum FileFormat {
  PDF = 'pdf',
  WORD = 'word'
}

export const FileMimeType = {
  [FileFormat.PDF]: MimeType.PDF,
  [FileFormat.WORD]: MimeType.HTML,
};
