import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button.component';

@NgModule({
  declarations: [
    ButtonComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ButtonComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class ButtonModule { }
