import { HttpErrorResponse } from '@angular/common/http';
import { VehicleGroup } from '@generated/sssg-v2/models/vehicle-group';
import { Action } from '@ngrx/store';
import { CompanyId, OutletId } from '@core/types/profile.types';
import { WarrantyPackage } from '@generated/sssg-v2/models/warranty-package';
import { CountryCode } from '@generated/sssg-v2/models/country-code';
import { CreateUpdateSellerWarrantyPrice } from '@generated/sssg-v2/models/create-update-seller-warranty-price';
import { Price } from '@generated/sssg-v2/models/price';

export const FETCH_PRODUCTS_REQUEST = '[WarrantyPackages] FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = '[WarrantyPackages] FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = '[WarrantyPackages] FETCH_PRODUCTS_FAILURE';

export const UPDATE_PRODUCT_PRICE_REQUEST = '[WarrantyPackages] UPDATE_PRODUCT_PRICE_REQUEST';
export const UPDATE_PRODUCT_PRICE_SUCCESS = '[WarrantyPackages] UPDATE_PRODUCT_PRICE_SUCCESS';
export const UPDATE_PRODUCT_PRICE_FAILURE = '[WarrantyPackages] UPDATE_PRODUCT_PRICE_FAILURE';

export const DELETE_PRODUCT_PRICE_REQUEST = '[WarrantyPackages] DELETE_PRODUCT_PRICE_REQUEST';
export const DELETE_PRODUCT_PRICE_SUCCESS = '[WarrantyPackages] DELETE_PRODUCT_PRICE_SUCCESS';
export const DELETE_PRODUCT_PRICE_FAILURE = '[WarrantyPackages] DELETE_PRODUCT_PRICE_FAILURE';

export const FETCH_VEHICLE_GROUPS_REQUEST = '[WarrantyPackages] FETCH_VEHICLE_GROUPS_REQUEST';
export const FETCH_VEHICLE_GROUPS_SUCCESS = '[WarrantyPackages] FETCH_VEHICLE_GROUPS_SUCCESS';
export const FETCH_VEHICLE_GROUPS_FAILURE = '[WarrantyPackages] FETCH_VEHICLE_GROUPS_FAILURE';

export const SELECT_PRODUCT_FILTER = '[WarrantyPackages] SELECT_PRODUCT_FILTER';

export class FetchProductsRequestAction implements Action {
  readonly type = FETCH_PRODUCTS_REQUEST;

  constructor(
    public readonly country: CountryCode,
    public readonly companyId: CompanyId,
    public readonly outletId: OutletId,
    public readonly productCodeFilter?: string,
    public readonly vehicleGroupIdFilter?: string,
  ) {}
}
export class FetchProductsSuccessAction implements Action {
  readonly type = FETCH_PRODUCTS_SUCCESS;

  constructor(public readonly products: Array<WarrantyPackage>) {}
}

export class FetchProductsFailureAction implements Action {
  readonly type = FETCH_PRODUCTS_FAILURE;

  constructor(public readonly error: HttpErrorResponse) {}
}

export class UpdateProductsRequestAction implements Action {
  readonly type = UPDATE_PRODUCT_PRICE_REQUEST;

  constructor(
    public readonly body: CreateUpdateSellerWarrantyPrice,
    public readonly country: CountryCode,
    public readonly companyId: CompanyId,
    public readonly outletId: OutletId,
    public readonly vehicleGroupId: string,
    public readonly warrantyId: string,
  ) {}
}

export class UpdateProductPriceSuccessAction implements Action {
  readonly type = UPDATE_PRODUCT_PRICE_SUCCESS;

  constructor(public readonly productId: string, public readonly price: Price) {}
}

export class UpdateProductPriceFailureAction implements Action {
  readonly type = UPDATE_PRODUCT_PRICE_FAILURE;

  constructor(public readonly error: HttpErrorResponse) {}
}

export class FetchVehicleGroupsRequestAction implements Action {
  readonly type = FETCH_VEHICLE_GROUPS_REQUEST;

  constructor(
    public readonly country: CountryCode,
    public readonly companyId: CompanyId,
    public readonly outletId: OutletId,
  ) {}
}

export class FetchVehicleGroupsSuccessAction implements Action {
  readonly type = FETCH_VEHICLE_GROUPS_SUCCESS;

  constructor(public readonly vehicleGroups: Array<VehicleGroup>) {}
}

export class FetchVehicleGroupsFailureAction implements Action {
  readonly type = FETCH_VEHICLE_GROUPS_FAILURE;

  constructor(public readonly error: HttpErrorResponse) {}
}

export class DeleteProductPriceRequestAction implements Action {
  readonly type = DELETE_PRODUCT_PRICE_REQUEST;

  constructor(
    public readonly country: CountryCode,
    public readonly companyId: CompanyId,
    public readonly outletId: OutletId,
    public readonly vehicleGroupIdFilter: string,
    public readonly productId: string,
    public readonly productCode: string,
  ) {}
}
export class DeleteProductPriceSuccessAction implements Action {
  readonly type = DELETE_PRODUCT_PRICE_SUCCESS;

  constructor(public readonly productId: string) {}
}

export class DeleteProductPriceFailureAction implements Action {
  readonly type = DELETE_PRODUCT_PRICE_FAILURE;

  constructor(public readonly error: HttpErrorResponse) {}
}

export class SelectProductFilterAction implements Action {
  readonly type = SELECT_PRODUCT_FILTER;

  constructor(public readonly productCode: string) {}
}
