import { RootState } from '@core/store/root.state';
import { Company, Market, Outlet, SSSPCountryCode } from '@core/types/types';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { CompanyOutletSelector2State, CompanyOutletSelectorFilter } from './company-outlet-selector-2.state';

const selectFeature = (state: RootState): CompanyOutletSelector2State => state.companyOutletSelector;

function mapCompanyOutlets(company: Company): Outlet[] {
  const { companyId: cid, outlets, countryCode } = company;

  return Object.entries(outlets)
    .map(([ id, outlet ]) => ({ outletId: id,  companyId: cid, countryCode: countryCode as SSSPCountryCode, ...outlet }));
}

export const filter: MemoizedSelector<RootState, CompanyOutletSelectorFilter> = createSelector(
  selectFeature, (state: CompanyOutletSelector2State) => state.filter);

export const selectedCompany: MemoizedSelector<RootState, Company> = createSelector(
  selectFeature, (state: CompanyOutletSelector2State) => state.company);

export const selectedOutlet: MemoizedSelector<RootState, Outlet> = createSelector(
  selectFeature, (state: CompanyOutletSelector2State) => state.outlet);

export const selectedMarket: MemoizedSelector<RootState, Market> = createSelector(
  selectFeature, (state: CompanyOutletSelector2State) => state.market);

export const getCompanyOutlets: MemoizedSelector<RootState, Outlet[]> = createSelector(
  selectFeature, (state: CompanyOutletSelector2State) => {
    if (!state.company) {
      return [];
    }

    return mapCompanyOutlets(state.company);
  }
);

export const getCompanyOutletsWithPermissions: MemoizedSelector<RootState, Outlet[]> = createSelector(
  selectFeature, (state: CompanyOutletSelector2State) => {
    const company = state.company;

    if (!state.company) {
      return [];
    }

    const outlets = mapCompanyOutlets(company);

    if (company.permissions) {
      return outlets;
    }

    return outlets.filter((outlet) => Boolean(outlet.permissions));
  });

export const active: MemoizedSelector<RootState, boolean> = createSelector(
  selectFeature, (state: CompanyOutletSelector2State) => state.active);

export const disabled: MemoizedSelector<RootState, boolean> = createSelector(
  selectFeature, (state: CompanyOutletSelector2State) => state.disabled);

