import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';

@NgModule({
  declarations: [
    SidebarComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
    RouterModule,
  ],
  exports: [
    SidebarComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class SidebarModule { }
