import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DropdownOption } from '@app/shared/components/dropdown/dropdown-option.class';
import { SelectorConfig } from '@core/company-outlet-selector-2/company-outlet-selector.config';
import { DocumentsRoute } from '@app/document/document.config';
import { CompanyOutletSelectorFilter } from '@core/company-outlet-selector-2/store/company-outlet-selector-2.state';
import { I18nService } from '@core/i18n/i18n.service';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { CurrentStateService } from '@core/store/current-state.service';
import { SortService } from '@shared/services/sort/sort.service';
import { CountryCode } from '@generated/sssd-v3/models';

@Component({
  selector: 'sss-dropdown-market',
  templateUrl: './dropdown-market.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'sss-dropdown-market' },
})
export class DropdowMarketComponent implements OnInit, OnChanges {
  @Input()
  public dark = false;

  @Input()
  public filter: CompanyOutletSelectorFilter;

  @Input()
  public disabled = false;

  @Input()
  public isCountryLevel = false;

  @Output()
  public changedMarket: EventEmitter<string> = new EventEmitter();

  public marketOptions: DropdownOption[] = [];
  public market: string;
  public config: SelectorConfig;
  public url: string;

  constructor(
    private readonly sortService: SortService,
    private readonly currentStateService: CurrentStateService,
    private readonly i18nService: I18nService,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    this.url = this.router.url.replace(/\?.*/g, '');
    this.makeMarketOptions();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter) {
      this.market = this.filter.market;
    }
  }

  public emitMarket(market: string): void {
    this.changedMarket.emit(market);
  }

  public makeMarketOptions(): void {
    this.marketOptions = this.makeDropdownOptions();
  }


  public makeDropdownOptions(): DropdownOption[] {
    let countries: CountryCode[];

    if (this.url === DocumentsRoute.OUTLET_UPLOAD) {
      countries = this.currentStateService.get(AuthSelectors.countriesWithSSSDPermissions);
    } else {
      countries = this.currentStateService.get(AuthSelectors.countriesWithPermissions);
    }


    const elements = countries
      ?.map((country) => new DropdownOption(country, this.i18nService.getInstantTranslation(`common.countries.${country}`)))
      .sort((a, b) => this.sortService.sortStringAlphabeticallyIgnoreCase(a.value, b.value));

    return elements || [];
  }
}
