import { State } from '@core/store/root.state';
import { Company, Market, Outlet } from '@core/types/types';
import { CommerceDomain, SellerCenterDomain } from '@core/config/global.config';

export interface CompanyOutletSelectorFilter {
  country?: string;
  market?: string;
  company?: string;
  outlet?: string;
  commerceDomain: CommerceDomain;
  sellerCenterDomain: SellerCenterDomain;
}

export interface CompanyOutletSelector2State extends State {
  active: boolean;
  disabled: boolean;
  market: Market;
  company: Company;
  outlet: Outlet;
  filter: CompanyOutletSelectorFilter;
}

export const COMPANY_OUTLET_SELECTOR_2_INITIAL_STATE: CompanyOutletSelector2State = {
  active: false,
  disabled: false,
  market: null,
  company: null,
  outlet: null,
  filter: null,
};
