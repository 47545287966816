import { ActionMap, reduce } from '@core/store/root.state';
import { PriceType } from '@generated/sssg-v2/models/price-type';
import { WarrantyPackage } from '@generated/sssg-v2/models/warranty-package';
import { Action } from '@ngrx/store';
import * as PricingActions from './warranty-packages.actions';
import { WarrantyPackagesState, WARRANTY_PACKAGES_INITIAL_STATE } from './warranty-packages.state';

const REDUCER_ACTION_MAP: ActionMap = {
  [ PricingActions.FETCH_PRODUCTS_REQUEST ]: fetchProductsRequest,
  [ PricingActions.FETCH_PRODUCTS_SUCCESS ]: fetchProductsSuccess,
  [ PricingActions.FETCH_PRODUCTS_FAILURE ]: fetchProductsFailure,
  [ PricingActions.UPDATE_PRODUCT_PRICE_REQUEST ]: updateProductsRequest,
  [ PricingActions.UPDATE_PRODUCT_PRICE_SUCCESS ]: updateProductsSuccess,
  [ PricingActions.UPDATE_PRODUCT_PRICE_FAILURE ]: updateProductsFailure,
  [ PricingActions.DELETE_PRODUCT_PRICE_REQUEST ]: deleteProductPriceRequest,
  [ PricingActions.DELETE_PRODUCT_PRICE_SUCCESS ]: deleteProductPriceSuccess,
  [ PricingActions.DELETE_PRODUCT_PRICE_FAILURE ]: deleteProductPriceFailure,
  [ PricingActions.FETCH_VEHICLE_GROUPS_REQUEST ]: fetchVehicleGroupsRequest,
  [ PricingActions.FETCH_VEHICLE_GROUPS_SUCCESS ]: fetchVehicleGroupsSuccess,
  [ PricingActions.FETCH_VEHICLE_GROUPS_FAILURE ]: fetchVehicleGroupsFailure,
  [ PricingActions.SELECT_PRODUCT_FILTER ]: selectProductFilter,
};

export function warrantyPackagesReducer(state: WarrantyPackagesState = WARRANTY_PACKAGES_INITIAL_STATE, action: Action): WarrantyPackagesState {
  return reduce(REDUCER_ACTION_MAP, action, state);
}

function resetState(state: WarrantyPackagesState): WarrantyPackagesState {
  return {
    ...state,
    products: {
      ...state.products,
      error: null,
      // note: we don't want to set the global loading state of products, because not all products are reloading but just one
      isPending: false,
    },
  };
}

function fetchProductsRequest(state: WarrantyPackagesState): WarrantyPackagesState {
  return {
    ...state,
    products: {
      error: null,
      isPending: true,
      data: null,
    },
  };
}

function fetchProductsSuccess(state: WarrantyPackagesState, action: PricingActions.FetchProductsSuccessAction): WarrantyPackagesState {
  return {
    ...state,
    products: {
      error: null,
      isPending: false,
      data: action.products,
    },
  };
}

function fetchProductsFailure(state: WarrantyPackagesState, action: PricingActions.FetchProductsFailureAction): WarrantyPackagesState {
  return {
    ...state,
    products: {
      error: action.error,
      isPending: false,
      data: null,
    },
  };
}

function updateProductsRequest(state: WarrantyPackagesState, action: PricingActions.UpdateProductsRequestAction): WarrantyPackagesState {
  return resetState({
    ...state,
    products: {
      ...state.products,
      data: state.products.data.map((product: WarrantyPackage) => {
        if (product.id === action.warrantyId) {
          return {
            ...product,
            prices: {
              ...product.prices,
              calculatedPriceType: PriceType.SELLER,
              calculatedPrice: {
                ...product.prices.calculatedPrice,
                price: {
                  ...product.prices.calculatedPrice.price,
                  amount: null,
                },
              },
            },
          };
        }

        return product;
      }),
    },
  });
}


function updateProductsSuccess(state: WarrantyPackagesState, action: PricingActions.UpdateProductPriceSuccessAction): WarrantyPackagesState {
  return {
    ...state,
    products: {
      error: null,
      isPending: false,
      data: state.products.data.map((product: WarrantyPackage) => {
        if (product.id === action.productId) {
          return {
            ...product,
            prices: {
              ...product.prices,
              calculatedPrice: action.price,
              calculatedPriceType: PriceType.SELLER,
            },
          };
        }

        return product;
      }),
    },
  };
}

function updateProductsFailure(state: WarrantyPackagesState): WarrantyPackagesState {
  return state; // note: single product update error won't cause the whole view to display error state but will trigger toaster
}

function fetchVehicleGroupsRequest(state: WarrantyPackagesState): WarrantyPackagesState {
  return {
    ...state,
    vehicleGroups: {
      error: null,
      isPending: true,
      data: null,
    },
  };
}

function fetchVehicleGroupsSuccess(state: WarrantyPackagesState, action: PricingActions.FetchVehicleGroupsSuccessAction): WarrantyPackagesState {
  return {
    ...state,
    vehicleGroups: {
      error: null,
      isPending: false,
      data: action.vehicleGroups,
    },
    products: {
      error: null,
      isPending: true,
      data: null,
    },
  };
}

function fetchVehicleGroupsFailure(state: WarrantyPackagesState, action: PricingActions.FetchVehicleGroupsFailureAction): WarrantyPackagesState {
  return {
    ...state,
    vehicleGroups: {
      error: action.error,
      isPending: false,
      data: null,
    },
  };
}

function deleteProductPriceRequest(state: WarrantyPackagesState): WarrantyPackagesState {
  return resetState(state);
}

function deleteProductPriceSuccess(state: WarrantyPackagesState, action: PricingActions.DeleteProductPriceSuccessAction): WarrantyPackagesState {
  return {
    ...state,
    products: {
      error: null,
      isPending: false,
      data: state.products.data.map((product: WarrantyPackage) => {
        if (action.productId === product.id) {
          return {
            ...product,
            prices: {
              ...product.prices,
              calculatedPrice: product.prices.basePrice,
              calculatedPriceType: PriceType.BASE,
            },
          };
        }

        return product;
      }),
    },
  };
}

function deleteProductPriceFailure(state: WarrantyPackagesState): WarrantyPackagesState {
  return state; // note: single product update error won't cause the whole view to display error state but will trigger toaster
}

function selectProductFilter(state: WarrantyPackagesState, action: PricingActions.SelectProductFilterAction): WarrantyPackagesState {
  return {
    ...state,
    selectedProductCode: action.productCode,
  };
}
