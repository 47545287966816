import { OrderStatus } from './orders.typings';

export enum OrdersRoute {
  ROOT = '/orders',
  DETAILS = '/orders/details'
}


export const ORDER_STATUS_TAG_OUTLINE = {
  [OrderStatus.CREATED]: true,
  [OrderStatus.CANCELLED]: false,
  [OrderStatus.COMPLETED]: false,
  [OrderStatus.IN_PROGRESS]: true,
  [OrderStatus.REFUNDED]: true,
  [OrderStatus.CANCELLING]: false,
  [OrderStatus.ERROR]: true,
};

export const ORDER_STATUS_TAG_VARIANT = {
  [OrderStatus.CREATED]: 'tertiary',
  [OrderStatus.IN_PROGRESS]: 'primary',
  [OrderStatus.CANCELLED]: 'tertiary',
  [OrderStatus.COMPLETED]: 'primary',
  [OrderStatus.REFUNDED]: 'tertiary',
  [OrderStatus.CANCELLING]: 'tertiary',
  [OrderStatus.ERROR]: 'tertiary',
};
