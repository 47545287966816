import { Routes } from '@angular/router';

export enum BackofficeRoute {
  DOCUMENT = 'document',
}

/* eslint-disable @typescript-eslint/explicit-function-return-type */

export const BACKOFFICE_ROUTES: Routes = [{
  path: BackofficeRoute.DOCUMENT,
  loadChildren: () => import('./documents/backoffice-documents.module').then(m => m.BackofficeDocumentsModule),
}];
