import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Logger } from 'loglevel';
import { I18nService } from '@core/i18n/i18n.service';
import { ResetAuthStateAction } from '@core/store/auth/auth.actions';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { CurrentStateService } from '@core/store/current-state.service';
import { RootState } from '@core/store/root.state';
import { ToasterService } from '@shared/components/toaster/toaster.service';
import { AppRoute } from '@app/app-routes.enum';
import { LoggerService } from '@core/services/logger/logger.service';
import { HttpStatusCode, HttpErrorCodeKey } from './error-codes';

export const HANDLING_ERRORS =  [
  HttpStatusCode.PERMISSION_DENIED,
  HttpStatusCode.FORBIDDEN_ACCESS,
  HttpStatusCode.INTERNAL_SERVER_ERROR,
];

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private log: Logger;

  constructor(
    private toasterService: ToasterService,
    private i18nService: I18nService,
    private router: Router,
    private store: Store<RootState>,
    private currentStateService: CurrentStateService,
    private loggerService: LoggerService,
  ) {
    this.log = this.loggerService.getLogger('HTTPErrorInterceptor');
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(err: HttpErrorResponse): Observable<any> {
    const hasToken = Boolean(this.currentStateService.get(AuthSelectors.token));
    const showError = HANDLING_ERRORS.includes(err.status);

    if (!(err instanceof HttpErrorResponse)) {
      return;
    }

    if (showError) {
      this.showError(err);
    }

    if (err.status === HttpStatusCode.PERMISSION_DENIED && hasToken) {
      this.logout();
    }

    return throwError(err);
  }

  private showError(err: HttpErrorResponse): void {
    const key = HttpErrorCodeKey[err.status];
    const message = this.i18nService.getInstantTranslation(key);

    this.toasterService.showError(message);
    this.log.error(err);
  }

  private logout(): void {
    // Add the redirect route as the query param next
    const requestedRoute = this.router.url.slice(1, this.router.url.length);

    // Reset user login so the dashboard shows the login page
    this.store.dispatch(new ResetAuthStateAction());
    this.router.navigate([ AppRoute.DASHBOARD ], { queryParams: { next: requestedRoute } });
  }
}
