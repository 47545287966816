import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CompanySelectionMode } from '@core/company-selector/company-selector.component';
import { AppInitializerService } from '@core/services/app-initializer/app-initializer.service';
import { ConsoleCommandService } from '@core/services/console-command/console-command.service';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { RootState } from '@core/store/root.state';
import { UserRole } from '@core/types/user-role';
import { Store } from '@ngrx/store';
import { SpecialEditionService } from '@shared/services/special-edition/special-edition.service';
import { combineLatest, Observable } from 'rxjs';
import { BrowserUtilService } from '@shared/services/browser-util/browser-util.service';
import { map } from 'rxjs/operators';
import * as SessionSelectors from '@core/store/session/session.selectors';
import { CommerceDomainSelectorService } from '@core/commerce-domain-selector/commerce-domain-selector.service';
import { CommerceDomain } from '@core/config/global.config';
import * as TranslationSelectors from '@core/store/translation/translation.selectors';
import { AVAILABLE_BACKGROUND_IMAGES } from './dashboard/dashboard.config';
import { NavigationEnd, Router } from '@angular/router';
import { AppRoute } from './app-routes.enum';
import { MarkdownService } from 'ngx-markdown';
import * as SessionActions from '@core/store/session/session.actions';

@Component({
  selector: 'sss-app',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: [ './app.component.scss' ],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public showCompanySelector = false;
  public companySelectionMode: CompanySelectionMode = CompanySelectionMode.INITIAL;
  public showBrowserWarning = false;

  public loading$: Observable<boolean>;
  public commerceDomainSelector$: Observable<boolean>;
  public companySelector$: Observable<boolean>;
  public isLoggedIn$: Observable<boolean>;
  public isDashboard = false;

  translationsLoaded = false;

  constructor(
    private readonly store: Store<RootState>,
    private readonly consoleCommandService: ConsoleCommandService,
    private readonly specialEditionService: SpecialEditionService,
    private readonly appInitializerService: AppInitializerService,
    private readonly browserUtilService: BrowserUtilService,
    private readonly commerceDomainSelectorService: CommerceDomainSelectorService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly markdownService: MarkdownService,
  ) {
    this.appInitializerService.initialize();

    this.markdownService.renderer.paragraph = (text: string): string => `<p class="markdown-paragraph">${text}</p>`;
    this.markdownService.renderer.link = (url: string, title: string, text: string): string => `<a target="_blank" class="markdown-link" href="${url}">${text}</a>`;
  }

  public ngOnInit(): void {
    this.consoleCommandService.initialize();
    this.specialEditionService.initialize();
    this.showBrowserWarning = this.browserUtilService.isIE();

    this.loading$ = combineLatest([
      this.store.select(AuthSelectors.token),
      this.store.select(AuthSelectors.identity),
      this.store.select(AuthSelectors.profile),
    ])
      .pipe(map(([ token, identity, profile ]) => Boolean(token) &&  !identity && !profile));

    this.isLoggedIn$ = this.store
      .select(AuthSelectors.hasSession);

    this.onCommerceDomainSelector$();
    this.onCompanySelector$();
    this.onTranslationsLoaded$();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.store.select(SessionSelectors.commerceDomain).subscribe(commerceDomain => {
          if (commerceDomain) {
            this.isDashboard = this.router.url === `/${AppRoute.DASHBOARD}`;
            this.changeDetectorRef.markForCheck();
          }
        });
      }
    });
  }

  public getBackgroundImage(): string {
    let image;
    if (this.isDashboard) {
      image = Array.from(AVAILABLE_BACKGROUND_IMAGES).pop();
    }
    return image;
  }

  public openCompanySelector(): void {
    this.companySelectionMode = CompanySelectionMode.UPDATE;
    this.showCompanySelector = true;
  }

  public closeCompanySelector(): void {
    this.showCompanySelector = false;
  }

  public dismissWarning(): void {
    this.showBrowserWarning = false;
  }

  private onCommerceDomainSelector$(): void {
    this.commerceDomainSelector$ = combineLatest([
      this.store.select(AuthSelectors.userRole),
      this.store.select(SessionSelectors.commerceDomain),
    ])
      .pipe(
        map(([ userRole, commerceDomain ]) => {
          if (!userRole || commerceDomain) {
            return false;
          }
          return this.commerceDomainSelectorService.enableCommerceDomainSelector();
        })
      );

    this.store.dispatch(new SessionActions.SetCommerceDomainAction(CommerceDomain.MARKETPLACE));
  }

  private onCompanySelector$(): void {
    this.companySelector$ = combineLatest([
      this.store.select(AuthSelectors.userRole),
      this.store.select(SessionSelectors.commerceDomain),
      this.store.select(AuthSelectors.selectedCompany),
    ])
      .pipe(
        map(([ userRole, commerceDomain, selectedCompany ]) => {
          if (userRole === UserRole.SELLER && commerceDomain === CommerceDomain.SHOPS && !selectedCompany) {
            return true;
          }

          return false;
        }),
      );
  }

  private onTranslationsLoaded$(): void {
    this.store.select(TranslationSelectors.getTranslations)
      .pipe(
        map((translations) => {
          if (translations) {
            this.translationsLoaded = true;
            this.changeDetectorRef.markForCheck();
          }
        })
      ).subscribe();
  }
}
