import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { WarningBarComponent } from './warning-bar.component';
import { ButtonModule } from '../button/button.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    PipesModule,
  ],
  declarations: [ WarningBarComponent ],
  exports: [ WarningBarComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class WarningBarModule {}
