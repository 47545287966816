import { Injectable } from '@angular/core';
import { CommerceDomain, DOMAIN_SERVICES_MAP, SellerCenterDomain, SellerCenterService } from '@core/config/global.config';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { CurrentStateService } from '@core/store/current-state.service';
import * as SessionSelectors from '@core/store/session/session.selectors';

interface CanAccessDomainOptions {
  forCountryEditor: boolean;
}

const CAN_ACCESS_DOMAIN_OPTIONS_DEFAULT: CanAccessDomainOptions = {
  forCountryEditor: false,
};

@Injectable()
export class DomainAccessService {
  constructor(
    private readonly currentStateService: CurrentStateService,
  ) {}

  public canAccessDomain(domain: SellerCenterDomain | SellerCenterService, options: CanAccessDomainOptions = CAN_ACCESS_DOMAIN_OPTIONS_DEFAULT): boolean {
    const onShopsCommerceDomain = this.currentStateService.get(SessionSelectors.commerceDomain) === CommerceDomain.SHOPS;
    const onlyCountryEditor = this.currentStateService.get(AuthSelectors.isOnlyCountryEditor);

    switch (domain) {
      case SellerCenterDomain.PROFILE:
        return true;
      case SellerCenterDomain.MASTERDATA:
        return true;
      case SellerCenterDomain.HELP:
        return true;
      case SellerCenterDomain.BACKOFFICE:
        return true; // TODO check real conditions
      case SellerCenterDomain.DOCUMENTS:
        return onShopsCommerceDomain || this.isDomainAvailable(SellerCenterDomain.DOCUMENTS, options);
      case SellerCenterDomain.PRICING:
        return this.isDomainAvailable(SellerCenterDomain.PRICING, options);
      case SellerCenterDomain.WARRANTY_PACKAGE:
        return this.isDomainAvailable(SellerCenterDomain.WARRANTY_PACKAGE, options);
      case SellerCenterDomain.SHIPPING_COSTS:
        return this.isDomainAvailable(SellerCenterDomain.SHIPPING_COSTS, options);
      case SellerCenterDomain.SETUP_PRODUCT_PARTICIPATION:
        return !onShopsCommerceDomain && !onlyCountryEditor;
      case SellerCenterDomain.ORDERS:
        return this.isDomainAvailable(SellerCenterDomain.ORDERS, options);
      default:
        return false;
    }
  }

  private isDomainAvailable(domain: SellerCenterDomain | SellerCenterService, options: CanAccessDomainOptions): boolean {
    const domainServices = DOMAIN_SERVICES_MAP[domain];
    const { forCountryEditor } = options;

    const servicesAvailable =  forCountryEditor
      ? this.currentStateService.get(AuthSelectors.countryEditorServicesAvailable)
      : this.currentStateService.get(AuthSelectors.servicesAvailable);

    return servicesAvailable.some(i => domainServices.includes(i));
  }
}
