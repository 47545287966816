import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref/window-ref.service';

@Injectable()
export class BrowserUtilService {
  constructor(
    private windowRefService: WindowRefService,
  ) {}

  public isIE(): boolean {
    const userAgent = this.windowRefService.nativeWindow.navigator.userAgent;
    return Boolean(userAgent.includes('MSIE ') || userAgent.includes('Trident/'));
  }
}
