import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './assets/env/env-loader';

if (!environment.isLocalDevMode) {
  enableProdMode(); // ANGULAR PROD MODE
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));  // eslint-disable-line
